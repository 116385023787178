import { StableBond, LPBond, NetworkID, CustomBond, BondType } from "../../src/lib/Bond";
import { addresses } from "../../src/constants";

// import { abi as BondOhmDaiContract } from "../../src/abi/bonds/OhmDaiContract.json";
// import { abi as DaiBondContract } from "../../src/abi/bonds/DaiContract.json";
// import { abi as ReserveOhmLusdContract } from "../../src/abi/reserves/OhmLusd.json";
import { abi as ReserveOhmDaiContract } from "../../src/abi/reserves/OhmDai.json";
import EthBondContract from "../../src/abi/bonds/EthContract.json";
import ReserveContract from "../../src/abi/bonds/ReserveContract.json";
import FixedBondDepository from "../../src/abi/FixedBondDepository.json";
import TreasuryContract from "../../src/abi/TreasuryContract.json";
import GenericVault from "../../src/abi/GenericVault.json";

import { abi as ierc20Abi } from "../../src/abi/IERC20.json";
// import { getBondCalculator } from "../../src/helpers/BondCalculator";
import { BigNumberish, ethers } from "ethers";
// import { EthContract } from "../../src/typechain";
import { AtlantisTreasury } from "../../src/typechain/AtlantisTreasury";

import tokens from "../tokens/tokens";
import { VaultAtlasToAtlas } from "src/typechain";

// TODO(zx): Further modularize by splitting up reserveAssets into vendor token definitions
//   and include that in the definition of a bond
export const dai = new StableBond({
  name: "busd",
  displayName: "BUSD",
  payoutToken: tokens.atlas,
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
  bondToken: tokens.busd,
  bondContractABI: EthBondContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x7fa01C9D5855a4a630c2a7B706e19e079367B84D",
      reserveAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xDea5668E815dAF058e3ecB30F645b04ad26374Cf",
      reserveAddress: "0xB2180448f8945C8Cc8AE9809E67D6bd27d8B2f2C",
    },
  },
});

export const usdt = new StableBond({
  name: "usdt",
  displayName: "USDT",
  payoutToken: tokens.atlas,
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
  bondToken: tokens.usdt,
  bondContractABI: EthBondContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x1fd6f4f7f70Db31195b0aCA4DC1Af118b8d823a5",
      reserveAddress: "0x55d398326f99059fF775485246999027B3197955",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xDea5668E815dAF058e3ecB30F645b04ad26374Cf",
      reserveAddress: "0xB2180448f8945C8Cc8AE9809E67D6bd27d8B2f2C",
    },
  },
});

export const frax = new StableBond({
  name: "frax",
  displayName: "FRAX",
  bondToken: tokens.dai,
  payoutToken: tokens.atlas,
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
  bondContractABI: EthBondContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x8510c8c2B6891E04864fa196693D44E6B6ec2514",
      reserveAddress: "0x853d955acef822db058eb8505911ed77f175b99e",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xF651283543fB9D61A91f318b78385d187D300738",
      reserveAddress: "0x2F7249cb599139e560f0c81c269Ab9b04799E453",
    },
  },
});

export const lusd = new StableBond({
  name: "lusd",
  displayName: "LUSD",
  bondToken: tokens.dai,
  payoutToken: tokens.atlas,
  isAvailable: { [NetworkID.Mainnet]: false, [NetworkID.Testnet]: true },
  bondContractABI: EthBondContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x10C0f93f64e3C8D0a1b0f4B87d6155fd9e89D08D",
      reserveAddress: "0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0x3aD02C4E4D1234590E87A1f9a73B8E0fd8CF8CCa",
      reserveAddress: "0x45754dF05AA6305114004358eCf8D04FF3B84e26",
    },
  },
});

export const cake = new CustomBond({
  name: "cake",
  displayName: "CAKE",
  lpUrl: `https://autoshark.finance/swap?outputCurrency=${tokens.cake.address["56"]}`,
  bondType: BondType.StableAsset,
  bondToken: tokens.cake,
  payoutToken: tokens.atlas,
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
  bondContractABI: ReserveContract,
  reserveContract: ierc20Abi, // The Standard ierc20Abi since they're normal tokens
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0xC3a8628aEb767928e43a78f22f22DEaeED525347",
      reserveAddress: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xca7b90f8158A4FAA606952c023596EE6d322bcf0",
      reserveAddress: "0xc778417e063141139fce010982780140aa0cd5ab",
    },
  },
  customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
    const ethBondContract = this.getContractForBond(networkID, provider);
    let ethPrice: BigNumberish = await ethBondContract.assetPrice();
    ethPrice = Number(ethPrice.toString()) / Math.pow(10, 8);
    const treasuryContract = new ethers.Contract(
      addresses[networkID].TREASURY_ADDRESS,
      TreasuryContract,
      provider,
    ) as AtlantisTreasury;
    let ethAmount: BigNumberish = await treasuryContract.balanceOf(this.networkAddrs[NetworkID.Mainnet].reserveAddress);
    ethAmount = Number(ethAmount.toString()) / Math.pow(10, 18);
    return ethAmount * ethPrice;
  },
});

export const fins = new CustomBond({
  name: "fins",
  displayName: "FINS",
  lpUrl: `https://autoshark.finance/swap?outputCurrency=${tokens.fins.address["56"]}`,
  bondType: BondType.StableAsset,
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
  bondToken: tokens.fins,
  payoutToken: tokens.atlas,
  bondContractABI: ReserveContract,
  reserveContract: ierc20Abi, // The Standard ierc20Abi since they're normal tokens
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0xdbFa940d0aE008866F6825AC5c1cFE8170Bbe28b",
      reserveAddress: "0x1b219Aca875f8C74c33CFF9fF98f3a9b62fCbff5",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xca7b90f8158A4FAA606952c023596EE6d322bcf0",
      reserveAddress: "0xc778417e063141139fce010982780140aa0cd5ab",
    },
  },
  customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
    const ethBondContract = this.getContractForBond(networkID, provider);
    let ethPrice: BigNumberish = await ethBondContract.assetPrice();
    ethPrice = Number(ethPrice.toString()) / Math.pow(10, 8);
    const treasuryContract = new ethers.Contract(
      addresses[networkID].TREASURY_ADDRESS,
      TreasuryContract,
      provider,
    ) as AtlantisTreasury;
    let ethAmount: BigNumberish = await treasuryContract.balanceOf(this.networkAddrs[NetworkID.Mainnet].reserveAddress);
    ethAmount = Number(ethAmount.toString()) / Math.pow(10, 18);
    return ethAmount * ethPrice;
  },
});

export const wbnb = new CustomBond({
  name: "wbnb",
  displayName: "WBNB",
  lpUrl: `https://autoshark.finance/swap?outputCurrency=${tokens.wbnb.address["56"]}`,
  bondType: BondType.StableAsset,
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
  bondToken: tokens.wbnb,
  payoutToken: tokens.atlas,
  bondContractABI: ReserveContract,
  reserveContract: ierc20Abi, // The Standard ierc20Abi since they're normal tokens
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0xB251482ce8896112803F8aFAc85343aA529Ad5DA",
      reserveAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xca7b90f8158A4FAA606952c023596EE6d322bcf0",
      reserveAddress: "0xc778417e063141139fce010982780140aa0cd5ab",
    },
  },
  customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
    const ethBondContract = this.getContractForBond(networkID, provider);
    let ethPrice: BigNumberish = await ethBondContract.assetPrice();
    ethPrice = Number(ethPrice.toString()) / Math.pow(10, 8);
    const treasuryContract = new ethers.Contract(
      addresses[networkID].TREASURY_ADDRESS,
      TreasuryContract,
      provider,
    ) as AtlantisTreasury;
    let ethAmount: BigNumberish = await treasuryContract.balanceOf(this.networkAddrs[NetworkID.Mainnet].reserveAddress);
    ethAmount = Number(ethAmount.toString()) / Math.pow(10, 18);
    return ethAmount * ethPrice;
  },
});

export const jaws = new CustomBond({
  name: "jaws",
  displayName: "JAWS",
  lpUrl: `https://autoshark.finance/swap?outputCurrency=${tokens.jaws.address["56"]}`,
  bondType: BondType.StableAsset,
  bondToken: tokens.jaws,
  payoutToken: tokens.atlas,
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
  bondContractABI: ReserveContract,
  reserveContract: ierc20Abi, // The Standard ierc20Abi since they're normal tokens
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x0875da867F14C76E890fbe35031508d3e3FDD5EA",
      reserveAddress: "0xdD97AB35e3C0820215bc85a395e13671d84CCBa2",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xca7b90f8158A4FAA606952c023596EE6d322bcf0",
      reserveAddress: "0xc778417e063141139fce010982780140aa0cd5ab",
    },
  },
  customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
    const ethBondContract = this.getContractForBond(networkID, provider);
    let ethPrice: BigNumberish = await ethBondContract.assetPrice();
    ethPrice = Number(ethPrice.toString()) / Math.pow(10, 8);
    const treasuryContract = new ethers.Contract(
      addresses[networkID].TREASURY_ADDRESS,
      TreasuryContract,
      provider,
    ) as AtlantisTreasury;
    let ethAmount: BigNumberish = await treasuryContract.balanceOf(this.networkAddrs[NetworkID.Mainnet].reserveAddress);
    ethAmount = Number(ethAmount.toString()) / Math.pow(10, 18);
    return ethAmount * ethPrice;
  },
});

export const atlas_busd = new LPBond({
  name: "atlas_busd_lp",
  displayName: "ATLAS-BUSD",
  bondToken: tokens.atlas,
  quoteToken: tokens.busd,
  payoutToken: tokens.atlas,
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
  isPool: true,
  bondContractABI: EthBondContract,
  reserveContract: ReserveOhmDaiContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0xe9DB5Fe270f481F43C08DEb30996422c9d94749D",
      reserveAddress: "0x8eC2dCc0B88ef879C885B0b31e87aBa14543a8cd",
      poolAddress: "0x802Cb1539b269B5ADa75956323A056f9C6458854",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xcF449dA417cC36009a1C6FbA78918c31594B9377",
      reserveAddress: "0x8D5a22Fb6A1840da602E56D1a260E56770e0bCE2",
    },
  },
  lpUrl: `https://autoshark.finance/add/${tokens.atlas.address["56"]}/${tokens.busd.address["56"]}`,
});

export const fins_bnb_for_fins = new CustomBond({
  name: "fins_bnb_for_fins",
  displayName: "FINS-WBNB",
  bondToken: tokens.fins,
  quoteToken: tokens.wbnb,
  isAvailable: { [NetworkID.Mainnet]: false, [NetworkID.Testnet]: true },
  isPool: true,
  bondType: BondType.Fixed,
  bondContractABI: FixedBondDepository,
  reserveContract: ReserveOhmDaiContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x6801685473A82335F82f939310bEb5543585BFcf",
      reserveAddress: "0x14B5a6d26577970953F9E6608d6604e4676Ac5b7",
      poolAddress: "0xee8c659c08adA7AE879404eB31b69aCbb1f19Ff8",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0x6801685473A82335F82f939310bEb5543585BFcf",
      reserveAddress: "0x14B5a6d26577970953F9E6608d6604e4676Ac5b7",
    },
  },
  lpUrl: `https://autoshark.finance/add/${tokens.fins.address["56"]}/${tokens.wbnb.address["56"]}`,
  payoutToken: tokens.fins,
  customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
    const ethBondContract = this.getContractForBond(networkID, provider);
    let ethPrice: BigNumberish = await ethBondContract.assetPrice();
    ethPrice = Number(ethPrice.toString()) / Math.pow(10, 8);
    const treasuryContract = new ethers.Contract(
      this.networkAddrs[NetworkID.Mainnet].reserveAddress,
      GenericVault,
      provider,
    ) as VaultAtlasToAtlas;
    let ethAmount: BigNumberish = await treasuryContract.balanceOf(
      this.networkAddrs[NetworkID.Mainnet].poolAddress ?? "",
    );
    ethAmount = Number(ethAmount.toString()) / Math.pow(10, 18);
    return ethAmount * ethPrice;
  },
});

export const fins_bnb = new CustomBond({
  name: "fins_bnb_lp",
  displayName: "FINS-WBNB",
  bondToken: tokens.fins,
  quoteToken: tokens.wbnb,
  isAvailable: { [NetworkID.Mainnet]: false, [NetworkID.Testnet]: true },
  isPool: true,
  bondType: BondType.Fixed,
  bondContractABI: FixedBondDepository,
  reserveContract: ReserveOhmDaiContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0xb5F7C0F0C1bB18e2C9c3E0F0117cd0bB72a3B780",
      reserveAddress: "0x14B5a6d26577970953F9E6608d6604e4676Ac5b7",
      poolAddress: "0xee8c659c08adA7AE879404eB31b69aCbb1f19Ff8",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xcF449dA417cC36009a1C6FbA78918c31594B9377",
      reserveAddress: "0x8D5a22Fb6A1840da602E56D1a260E56770e0bCE2",
    },
  },
  lpUrl: `https://autoshark.finance/add/${tokens.fins.address["56"]}/${tokens.wbnb.address["56"]}`,
  customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
    const ethBondContract = this.getContractForBond(networkID, provider);
    let ethPrice: BigNumberish = await ethBondContract.assetPrice();
    ethPrice = Number(ethPrice.toString()) / Math.pow(10, 8);
    const treasuryContract = new ethers.Contract(
      this.networkAddrs[NetworkID.Mainnet].reserveAddress,
      GenericVault,
      provider,
    ) as VaultAtlasToAtlas;
    let ethAmount: BigNumberish = await treasuryContract.balanceOf(
      this.networkAddrs[NetworkID.Mainnet].poolAddress ?? "",
    );
    ethAmount = Number(ethAmount.toString()) / Math.pow(10, 18);
    return ethAmount * ethPrice;
  },
});

export const jaws_bnb = new CustomBond({
  name: "jaws_bnb_lp",
  displayName: "JAWS-WBNB",
  bondToken: tokens.jaws,
  quoteToken: tokens.wbnb,
  isAvailable: { [NetworkID.Mainnet]: false, [NetworkID.Testnet]: true },
  isPool: true,
  bondType: BondType.Fixed,
  bondContractABI: FixedBondDepository,
  reserveContract: ReserveOhmDaiContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x6eCe5eb61f56Bd77E0df93Aa9c66590E3003E371",
      reserveAddress: "0x1BbA5C6Cc38EF04066042237b4298dC23405304D",
      poolAddress: "0x5D2112Ba0969EC66012380C1fb88F2A3D182Eb90",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xcF449dA417cC36009a1C6FbA78918c31594B9377",
      reserveAddress: "0x8D5a22Fb6A1840da602E56D1a260E56770e0bCE2",
    },
  },
  lpUrl: `https://autoshark.finance/add/${tokens.jaws.address["56"]}/${tokens.wbnb.address["56"]}`,
  customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
    const ethBondContract = this.getContractForBond(networkID, provider);
    let ethPrice: BigNumberish = await ethBondContract.assetPrice();
    ethPrice = Number(ethPrice.toString()) / Math.pow(10, 8);
    const treasuryContract = new ethers.Contract(
      this.networkAddrs[NetworkID.Mainnet].reserveAddress,
      GenericVault,
      provider,
    ) as VaultAtlasToAtlas;
    let ethAmount: BigNumberish = await treasuryContract.balanceOf(
      this.networkAddrs[NetworkID.Mainnet].poolAddress ?? "",
    );
    ethAmount = Number(ethAmount.toString()) / Math.pow(10, 18);
    return ethAmount * ethPrice;
  },
});

// export const ohm_frax = new LPBond({
//   name: "ohm_frax_lp",
//   displayName: "OHM-FRAX LP",
//   bondToken: "FRAX",
//   isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
//   bondIconSvg: OhmFraxImg,
//   bondContractABI: FraxOhmBondContract,
//   reserveContract: ReserveOhmFraxContract,
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0xc20CffF07076858a7e642E396180EC390E5A02f7",
//       reserveAddress: "0x2dce0dda1c2f98e0f171de8333c3c6fe1bbf4877",
//     },
//     [NetworkID.Testnet]: {
//       bondAddress: "0x7BB53Ef5088AEF2Bb073D9C01DCa3a1D484FD1d2",
//       reserveAddress: "0x11BE404d7853BDE29A3e73237c952EcDCbBA031E",
//     },
//   },
//   lpUrl:
//     "https://app.uniswap.org/#/add/v2/0x853d955acef822db058eb8505911ed77f175b99e/0x383518188c0c6d7730d91b2c03a03c837814a899",
// });

// export const ohm_lusd = new LPBond({
//   name: "ohm_lusd_lp",
//   displayName: "OHM-LUSD LP",
//   bondToken: "LUSD",
//   isAvailable: { [NetworkID.Mainnet]: false, [NetworkID.Testnet]: true },
//   bondIconSvg: OhmLusdImg,
//   bondContractABI: BondOhmLusdContract,
//   reserveContract: ReserveOhmLusdContract,
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0xFB1776299E7804DD8016303Df9c07a65c80F67b6",
//       reserveAddress: "0xfDf12D1F85b5082877A6E070524f50F6c84FAa6b",
//     },
//     [NetworkID.Testnet]: {
//       // NOTE (appleseed-lusd): using ohm-dai rinkeby contracts
//       bondAddress: "0xcF449dA417cC36009a1C6FbA78918c31594B9377",
//       reserveAddress: "0x8D5a22Fb6A1840da602E56D1a260E56770e0bCE2",
//     },
//   },
//   lpUrl:
//     "https://app.sushi.com/add/0x383518188C0C6d7730D91b2c03a03C837814a899/0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
// });

// export const ohm_weth = new CustomBond({
//   name: "ohm_weth_lp",
//   displayName: "OHM-WETH LP",
//   bondToken: "WETH",
//   isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
//   bondIconSvg: OhmEthImg,
//   bondContractABI: BondOhmEthContract,
//   reserveContract: ReserveOhmEthContract,
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0xB6C9dc843dEc44Aa305217c2BbC58B44438B6E16",
//       reserveAddress: "0xfffae4a0f4ac251f4705717cd24cadccc9f33e06",
//     },
//     [NetworkID.Testnet]: {
//       // NOTE (unbanksy): using ohm-dai rinkeby contracts
//       bondAddress: "0xcF449dA417cC36009a1C6FbA78918c31594B9377",
//       reserveAddress: "0x8D5a22Fb6A1840da602E56D1a260E56770e0bCE2",
//     },
//   },
//   bondType: BondType.LP,
//   lpUrl:
//     "https://app.sushi.com/add/0x383518188c0c6d7730d91b2c03a03c837814a899/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
//   customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
//     if (networkID === NetworkID.Mainnet) {
//       const ethBondContract = this.getContractForBond(networkID, provider);
//       let ethPrice: BigNumberish = await ethBondContract.assetPrice();
//       ethPrice = Number(ethPrice.toString()) / Math.pow(10, 8);
//       const token = this.getContractForReserve(networkID, provider);
//       const tokenAddress = this.getAddressForReserve(networkID);
//       const bondCalculator = getBondCalculator(networkID, provider);
//       const tokenAmount = await token.balanceOf(addresses[networkID].TREASURY_ADDRESS);
//       const valuation = await bondCalculator.valuation(tokenAddress, tokenAmount);
//       const markdown = await bondCalculator.markdown(tokenAddress);
//       let tokenUSD =
//         (Number(valuation.toString()) / Math.pow(10, 9)) * (Number(markdown.toString()) / Math.pow(10, 18));
//       return tokenUSD * Number(ethPrice.toString());
//     } else {
//       // NOTE (appleseed): using OHM-DAI on rinkeby
//       const token = this.getContractForReserve(networkID, provider);
//       const tokenAddress = this.getAddressForReserve(networkID);
//       const bondCalculator = getBondCalculator(networkID, provider);
//       const tokenAmount = await token.balanceOf(addresses[networkID].TREASURY_ADDRESS);
//       const valuation = await bondCalculator.valuation(tokenAddress, tokenAmount);
//       const markdown = await bondCalculator.markdown(tokenAddress);
//       let tokenUSD =
//         (Number(valuation.toString()) / Math.pow(10, 9)) * (Number(markdown.toString()) / Math.pow(10, 18));
//       return tokenUSD;
//     }
//   },
// });

// HOW TO ADD A NEW BOND:
// Is it a stableCoin bond? use `new StableBond`
// Is it an LP Bond? use `new LPBond`
// Add new bonds to this array!!
// export const allBonds = [atlas_busd, fins_bnb, jaws_bnb, fins, jaws, wbnb, dai, usdt, cake];
export const allBonds = [atlas_busd, fins, jaws, wbnb, dai, usdt, cake, fins_bnb_for_fins, jaws_bnb];
export const allBondsMap = allBonds.reduce((prevVal, bond) => {
  return { ...prevVal, [bond.name]: bond };
}, {});

// Debug Log
// console.log(allBondsMap);
export default allBonds;
