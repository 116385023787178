import classNames from "../utils/classnames";

interface Props {
  onClick?: () => void;
  className?: string;
  scale?: string;
  variant?: string;
  disabled?: boolean;
}

const VARIANTS: { [key: string]: string } = {
  disabled: "border-2 border-gray-400 text-gray-400",
  primary: "bg-primary text-black",
  outline: "border border-primary text-primary",
  secondary: "bg-secondary text-black",
  gradient: "bg-gradient-to-br from-green-300 via-blue-500 to-purple-600 text-white hover:opacity-75",
  "gradient-burning": "bg-gradient-to-br from-pink-500 via-red-500 to-yellow-500 text-white hover:opacity-75",
  "gradient-pink": `bg-gradient-to-br from-fuchsia-600 to-pink-600 text-white hover:opacity-75`,
  "gradient-metal": `bg-conic-to-br from-indigo-200 via-blue-gray-600 to-indigo-200 text-white hover:opacity-75`,
};

const SCALE: { [key: string]: string } = {
  xxs: "text-xxs",
  xs: "text-xs",
  sm: "text-sm",
  md: "text-md",
  lg: "text-lg",
};

const Button: React.FC<Props> = ({ children, className: _className, scale, variant, disabled, onClick }) => {
  const _scale = scale && scale in SCALE ? SCALE[scale] : SCALE["sm"];
  const _bg = variant && variant in VARIANTS ? VARIANTS[variant] : VARIANTS["gradient"];
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className={classNames(
        "w-100 flex-grow px-4 py-2 font-medium rounded-md select-none focus:outline-none",
        disabled ? "cursor-not-allowed" : "",
        _scale,
        disabled ? VARIANTS["disabled"] : _bg,
        disabled ? "opacity-75" : "",
        _className ?? "",
      )}
    >
      {children}
    </button>
  );
};

export default Button;
