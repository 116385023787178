import Button from "src/components/Button";
import { useAddress } from "src/hooks";
import Balance from "src/components/Balance";
import Skeleton from "src/components/Skeleton";
import { useHarvestPool } from "../../hooks/useStakePool";
import { Pool } from "src/constants/types";
import BigNumber from "bignumber.js";

interface Props {
  pool: Pool;
  loading: boolean;
  currentBlock: number;
  earned: BigNumber;
}

function Harvest(props: Props) {
  const { pool, loading, currentBlock, earned } = props;
  // console.log(pool);
  const account = useAddress();

  const { onReward } = useHarvestPool(pool.contractAddress["56"]);

  const harvestTitle = (
    <div className="text-white font-medium uppercase text-xs">
      <span className="text-primary">{pool.earningToken.symbol}</span> EARNED
    </div>
  );

  if (!account) {
    return (
      <>
        <div>
          {harvestTitle}
          <div className="text-white text-xl font-semibold">0.00</div>
          <div className="text-white text-xs">$0.00</div>
        </div>
        <div>
          <Button variant="gradient-metal" disabled className="w-32">
            Harvest
          </Button>
        </div>
      </>
    );
  }

  if (loading) {
    return (
      <div className="w-full">
        <div className="text-gray-500 font-medium uppercase text-xs">
          <span className="text-primary">{pool.earningToken.symbol}</span> EARNED
        </div>
        <div>
          <Skeleton className="w-full" height="h-10" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        {harvestTitle}
        <div className="text-white text-xl font-semibold">
          <Balance fontSize="text-xl" value={earned.toNumber()} />
        </div>
        <div className="text-white text-xs">
          <Balance
            fontSize="text-xs"
            value={earned.toNumber() * parseFloat(pool.earningTokenPrice)}
            prefix="$"
            decimals={2}
          />
        </div>
      </div>
      <div>
        <Button disabled={earned.eq(0)} variant="gradient" onClick={() => onReward(currentBlock)} className="w-32">
          Harvest
        </Button>
      </div>
    </>
  );
}

export default Harvest;
