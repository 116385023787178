import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BondDataCard, BondTableData } from "./BondRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { formatCurrency } from "../../helpers";
import useBonds from "../../hooks/Bonds";
import { useWeb3Context } from "src/hooks/web3Context";
import "./choosebond.scss";
import ClaimBonds from "./ClaimBonds";
import isEmpty from "lodash/isEmpty";
import { allBondsMap } from "src/helpers/AllBonds";
import Title from "./Title";
import FaqMultipleBonds from "./FaqMultipleBonds";
import Skeleton from "../../components/Skeleton";

function ChooseBond() {
  const { chainID } = useWeb3Context();
  const { bonds } = useBonds(chainID);
  const isSmallScreen = useMediaQuery("(max-width: 640px)"); // change to breakpoint query
  // const isVerySmallScreen = useMediaQuery("(max-width: 420px)");

  const isAppLoading = useSelector(state => state.app.loading);
  const isAccountLoading = useSelector(state => state.account.loading);

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });

  const isBondLoading = useSelector(state => {
    return state.bonding.status === "idle";
  });

  // TODO: need to show all bonds that bondMaturationBlock > 0
  // Need to grab data from vault
  const accountBonds = useSelector(state => {
    const withInterestDue = [];
    for (const bond in state.account.bonds) {
      if (
        state.account.bonds[bond].bondMaturationBlock > 0 &&
        state.account.bonds[bond].bondMaturationBlock > currentBlock
      ) {
        withInterestDue.push(state.account.bonds[bond]);
      }
    }
    return withInterestDue;
  });

  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });

  const treasuryBalance = useSelector(state => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0;
      for (const bond in allBondsMap) {
        if (state.bonding[bond]) {
          tokenBalances += state.bonding[bond].purchased;
        }
      }
      return tokenBalances;
    }
  });

  const [numberOfBonds, setNumberOfBonds] = useState(0);
  useEffect(() => {
    let bondCount = Object.keys(accountBonds).length;
    setNumberOfBonds(bondCount);
  }, [accountBonds]);

  return (
    <div className="flex bg-gradient-to-br from-gray-900 via-gray-700 to-gray-900">
      <div className="w-full max-w-screen-2xl mx-auto px-4 sm:px-6 md:px-8 my-12">
        <Title />

        <div className="text-white xs:flex justify-between max-w-sm mx-auto mb-7 sm:mb-0">
          <div className="mb-2 sm:mb-7">
            <div className="text-center">
              <div className="text-lg sm:text-xl font-semibold">Treasury Balance</div>
              <div className="text-lg sm:text-xl">
                {isAppLoading && !treasuryBalance ? (
                  <Skeleton width="w-32" bg="bg-gray-500" className="mx-auto mt-2" />
                ) : (
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(treasuryBalance)
                )}
              </div>
            </div>
          </div>

          <div>
            <div className="text-center">
              <div className="text-lg sm:text-xl font-semibold">ATLAS Price</div>
              <div className="text-lg sm:text-xl">
                {isAppLoading ? (
                  <Skeleton width="w-32" bg="bg-gray-500" className="mx-auto mt-2" />
                ) : (
                  formatCurrency(marketPrice, 4)
                )}
              </div>
            </div>
          </div>
        </div>

        {!isAccountLoading && !isEmpty(accountBonds) && (
          <ClaimBonds activeBonds={accountBonds} numberOfBonds={numberOfBonds} />
        )}

        {!isAccountLoading && numberOfBonds > 0 && <FaqMultipleBonds />}

        {!isSmallScreen && (
          <div>
            {bonds.map(bond => (
              <BondTableData key={bond.name} bond={bond} currentBlock={currentBlock} sBondLoading={isBondLoading} />
            ))}
          </div>
        )}

        {isSmallScreen && (
          <div>
            {bonds.map(bond => (
              <BondDataCard key={bond.name} bond={bond} currentBlock={currentBlock} sBondLoading={isBondLoading} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ChooseBond;
