const Title = () => {
  return (
    <>
      <div className="text-white text-3xl font-bold text-center">Fixed Deposits</div>
      <div className="text-gray-400 text-md mb-5 text-center">Locked staking plans with guaranteed ROIs</div>
    </>
  );
};

export default Title;
