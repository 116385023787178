import BigNumberJS from "bignumber.js";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
// import { getBondCalculator } from "src/helpers/BondCalculator";
import { RootState } from "src/store";
// import { AtlantisPool, PriceCalculatorBSC, VaultAtlasToAtlas } from "src/typechain";
// import atlantisPoolAbi from "src/abi/AtlantisPool.json";
import { abi as erc20ABI } from "src/abi/ExpandedIERC20.json";
import syrupPoolAbi from "src/abi/SyrupPool.json";
import multicall from "src/utils/multicall";
// import dashboardAbi from "src/abi/DashboardBSC.json";
// import calculatorAbi from "src/abi/PriceCalculator.json";
// import {
//   IApproveBondAsyncThunk,
//   IBondAssetAsyncThunk,
//   ICalcBondDetailsAsyncThunk,
//   IJsonRPCError,
//   IRedeemAllBondsAsyncThunk,
//   IRedeemBondAsyncThunk,
// } from "./interfaces";
import { NetworkID } from "src/lib/Bond";
// import { aprToApy, apyToApr } from "src/helpers/apr";
// import { DashboardBSC } from "src/typechain/DashboardBSC";
import { pools } from "src/constants/pools";
// import tokens from "src/tokens/tokens";
import { getPoolApr } from "src/utils/apr";
import { getBalanceNumber } from "src/utils/formatBalance";
import { getAddress, getWbnbAddress } from "src/utils/addressHelpers";
import cakeABI from "src/abi/cake.json";
import wbnbABI from "src/abi/weth.json";

export const fetchPoolsTotalStaking = async () => {
  const nonBnbPools = pools.filter(p => p.stakingToken.symbol !== "BNB");
  const bnbPool = pools.filter(p => p.stakingToken.symbol === "BNB");

  const callsNonBnbPools = nonBnbPools.map(pool => {
    return {
      address: getAddress(pool.stakingToken.address),
      name: "balanceOf",
      params: [getAddress(pool.contractAddress)],
    };
  });

  const callsBnbPools = bnbPool.map(poolConfig => {
    return {
      address: getWbnbAddress(),
      name: "balanceOf",
      params: [getAddress(poolConfig.contractAddress)],
    };
  });

  const nonBnbPoolsTotalStaked = await multicall(cakeABI, callsNonBnbPools);
  const bnbPoolsTotalStaked = await multicall(wbnbABI, callsBnbPools);

  return [
    ...nonBnbPools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumberJS(nonBnbPoolsTotalStaked[index]).toJSON(),
    })),
    ...bnbPool.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumberJS(bnbPoolsTotalStaked[index]).toJSON(),
    })),
  ];
};

export interface IStakeDetails {}
export const getSyrupPool = createAsyncThunk(
  "staking/getSyrupPool",
  async (
    { account, currentBlock }: { account: string; currentBlock: number },
    { dispatch },
  ): Promise<IStakeDetails> => {
    const totalStakings = await fetchPoolsTotalStaking();

    const poolsWithEnd = pools.filter(p => !!p.hasEnd);
    const callsStartBlock = poolsWithEnd.map(poolConfig => {
      return {
        address: poolConfig.contractAddress[NetworkID.Mainnet],
        name: "startBlock",
      };
    });
    const callsEndBlock = poolsWithEnd.map(poolConfig => {
      return {
        address: poolConfig.contractAddress[NetworkID.Mainnet],
        name: "bonusEndBlock",
      };
    });
    const callsAllowance = poolsWithEnd.map(p => ({
      address: p?.stakingToken?.address?.[NetworkID.Mainnet] ?? "",
      name: "allowance",
      params: [account, p.contractAddress[NetworkID.Mainnet]],
    }));
    const callsUserBalance = poolsWithEnd.map(p => ({
      address: p?.stakingToken?.address?.[NetworkID.Mainnet] ?? "",
      name: "balanceOf",
      params: [account],
    }));
    const callsUserInfo = poolsWithEnd.map(p => ({
      address: p.contractAddress[NetworkID.Mainnet],
      name: "userInfo",
      params: [account],
    }));
    const callsPendingReward = poolsWithEnd.map(p => ({
      address: p.contractAddress[NetworkID.Mainnet],
      name: "pendingReward",
      params: [account],
    }));

    const starts = await multicall(syrupPoolAbi, callsStartBlock);
    const ends = await multicall(syrupPoolAbi, callsEndBlock);
    const allowances = await multicall(erc20ABI, callsAllowance);
    const tokenBalancesRaw = await multicall(erc20ABI, callsUserBalance);
    const userInfo = await multicall(syrupPoolAbi, callsUserInfo);
    const pendingRewardRaw = await multicall(syrupPoolAbi, callsPendingReward);

    const data = poolsWithEnd.map((poolConfig, index) => {
      const startBlock = new BigNumberJS(starts[index]).toJSON();
      const endBlock = new BigNumberJS(ends[index]).toJSON();
      const allowance = new BigNumberJS(allowances[index]).toJSON();
      const walletBalance = new BigNumberJS(tokenBalancesRaw[index]).toJSON();
      const stakedBalance = new BigNumberJS(userInfo[index].amount._hex).toJSON();
      const pendingRewards = new BigNumberJS(pendingRewardRaw[index]).toJSON();
      return { ...poolConfig, startBlock, endBlock, allowance, walletBalance, stakedBalance, pendingRewards };
    });

    const earningTokenAltPools = poolsWithEnd.filter(item => item.earningTokenPriceApi);
    const stakingTokenAltPools = poolsWithEnd.filter(item => item.stakingTokenPriceApi);
    let coinGeckoTokens: string[] = [];
    earningTokenAltPools.map(pool => coinGeckoTokens.push(pool.earningToken.symbol));
    stakingTokenAltPools.map(pool => coinGeckoTokens.push(pool.stakingToken.symbol));

    let prices: { [key: string]: string } = {};
    for (let i = 0; i < poolsWithEnd.length; i++) {
      const poolConfig = poolsWithEnd[i];
      const earningTokenAddress = getAddress(poolConfig?.earningToken.address);
      const stakingTokenAddress = getAddress(poolConfig?.stakingToken.address);

      if (!(earningTokenAddress in prices)) {
        let earningTokenResp = null;
        if (poolConfig.earningTokenPriceApi) {
          // eslint-disable-next-line no-await-in-loop
          const response = await fetch(`${poolConfig.earningTokenPriceApi}`);
          // eslint-disable-next-line no-await-in-loop
          earningTokenResp = await response.json();
        } else {
          // eslint-disable-next-line no-await-in-loop
          const response = await fetch(`https://api.autoshark.info/api/tokens/${earningTokenAddress}`);
          // eslint-disable-next-line no-await-in-loop
          earningTokenResp = await response.json();
        }
        prices = {
          ...prices,
          [`${getAddress(poolConfig?.earningToken.address)}`.toLowerCase()]: coinGeckoTokens.includes(
            poolConfig.earningToken.symbol,
          )
            ? earningTokenResp[Object.keys(earningTokenResp)[0]].usd
            : earningTokenResp.data.price,
        };
      }

      if (!(stakingTokenAddress in prices)) {
        let stakingTokenResp = null;
        if (poolConfig.stakingTokenPriceApi) {
          // eslint-disable-next-line no-await-in-loop
          const response = await fetch(`${poolConfig.stakingTokenPriceApi}`);
          // eslint-disable-next-line no-await-in-loop
          stakingTokenResp = await response.json();
        } else {
          // eslint-disable-next-line no-await-in-loop
          const response = await fetch(`https://api.autoshark.info/api/tokens/${stakingTokenAddress}`);
          // eslint-disable-next-line no-await-in-loop
          stakingTokenResp = await response.json();
        }
        prices = {
          ...prices,
          [`${getAddress(poolConfig?.stakingToken.address)}`.toLowerCase()]: coinGeckoTokens.includes(
            poolConfig.stakingToken.symbol,
          )
            ? stakingTokenResp[Object.keys(stakingTokenResp)[0]].usd
            : stakingTokenResp.data.price,
        };
      }
    }

    const liveData = data.map(pool => {
      const totalStaking = totalStakings.find(entry => entry.sousId === pool.sousId);
      const earningTokenAddress = getAddress(pool?.earningToken.address).toLowerCase();
      const stakingTokenAddress = getAddress(pool?.stakingToken.address).toLowerCase();
      const isPoolEndBlockExceeded = currentBlock > 0 && pool.endBlock ? currentBlock > Number(pool.endBlock) : false;
      const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded;
      const balance = getBalanceNumber(new BigNumberJS(totalStaking?.totalStaked ?? 0), pool.stakingToken.decimals); // this uses a different variable than the existing UI
      const apr = !isPoolFinished
        ? getPoolApr(
            parseFloat(prices[stakingTokenAddress]),
            parseFloat(prices[earningTokenAddress]),
            balance < 1000 ? 1000 : balance,
            parseFloat(pool.tokenPerBlock),
            false,
            new BigNumberJS(0),
            new BigNumberJS(0),
            new BigNumberJS(0),
            1,
          )
        : 0;
      return {
        ...pool,
        ...totalStaking,
        stakingTokenPrice: prices[stakingTokenAddress],
        earningTokenPrice: prices[earningTokenAddress],
        apr,
      };
    });

    // console.log(prices);
    // console.log(liveData);
    return liveData;
  },
);

// Note(zx): this is a barebones interface for the state. Update to be more accurate
interface IStakingSlice {
  status: string;
  [key: string]: any;
}

const setStakeState = (state: IStakingSlice, payload: any) => {
  const newState = { ...state.data, ...payload };
  state.data = newState;
  state.loading = false;
};

const initialState: IStakingSlice = {
  status: "idle",
  data: { ...pools },
};

const syrupSlice = createSlice({
  name: "syrup",
  initialState,
  reducers: {
    fetchStakeSuccess(state, action) {
      state.data = action.payload;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getSyrupPool.pending, state => {
        state.loading = true;
      })
      .addCase(getSyrupPool.fulfilled, (state, action) => {
        setStakeState(state, action.payload);
        state.status = "active";
        state.loading = false;
      })
      .addCase(getSyrupPool.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error.message);
      });
  },
});

export default syrupSlice.reducer;

export const { fetchStakeSuccess } = syrupSlice.actions;

const baseInfo = (state: RootState) => state.syrup;

export const getSyrupState = createSelector(baseInfo, syrup => syrup);
