import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { GiftIcon, XIcon, TicketIcon } from "@heroicons/react/outline";
import Pair from "../../../components/TokenImages/Pair";
import Single from "../../../components/TokenImages/Single";
import classNames from "../../../utils/classnames";
import BuyBonds from "./BuyBonds";
import RedeemBonds from "./RedeemBonds";
import { useWeb3Context } from "src/hooks/web3Context";
import { formatCurrency } from "../../../helpers";
import Skeleton from "../../../components/Skeleton";
import tokens from "src/tokens/tokens";

interface Props {
  bond: any;
  visible: boolean;
  vestingPeriod: string;
  onClose: () => void;
}

const tabs = [
  { name: "Buy", href: "#", icon: TicketIcon, current: true },
  { name: "Redeem", href: "#", icon: GiftIcon, current: false },
];

export default function BondsModal(props: Props) {
  // const dispatch = useDispatch();
  const { provider, address } = useWeb3Context();

  const [slippage, setSlippage] = useState(0.5);
  const [recipientAddress, setRecipientAddress] = useState(address);

  // const onRecipientAddressChange = (e: any) => {
  //   return setRecipientAddress(e.target.value);
  // };

  // const onSlippageChange = (e: any) => {
  //   return setSlippage(e.target.value);
  // };

  // const [quantity, setQuantity] = useState();

  useEffect(() => {
    if (address) setRecipientAddress(address);
  }, [provider, address]);

  const isBondLoading = useSelector((state: any) => state.bonding.loading ?? true);

  const { bond, visible, onClose, vestingPeriod } = props;
  const [navToggle, setNavToggle] = useState(bond.isAvailable?.[56] ? "buy" : "redeem");

  return (
    <div>
      <Transition show={visible} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          // initialFocus={cancelButtonRef}
          static
          open={visible}
          onClose={() => onClose()}
        >
          <div className="min-h-screen px-4 text-center backdrop-filter backdrop-blur-md backdrop-grayscale">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element tricks the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-2xl my-8 text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-xl overflow-y-auto">
                <Dialog.Title as="h3" className="flex justify-between items-center pt-5 px-6 pb-3 mb-3">
                  <div></div>
                  <div className="flex items-center">
                    {bond.isLP || bond.quoteToken !== undefined ? (
                      <Pair topToken={bond.quoteToken} bottomToken={bond.bondToken} />
                    ) : (
                      <Single token={bond.bondToken} />
                    )}
                    <div className="ml-3 text-xl text-left text-white select-none font-semibold uppercase">
                      {bond.isLP || bond.quoteToken
                        ? `${bond.bondToken.symbol} - ${bond.quoteToken.symbol}`
                        : `${bond.bondToken.symbol}`}{" "}
                      Bond
                    </div>
                  </div>
                  <button
                    type="button"
                    className="select-none focus:outline-none focus:border-transparent"
                    onClick={() => onClose()}
                  >
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </Dialog.Title>
                <div className="mt-2 h-full relative px-6 pb-6">
                  {bond.payoutToken === tokens.atlas && (
                    <div className="flex mb-2">
                      <div className="w-1/2 flex justify-center">
                        <div>
                          <div className="text-center text-gray-400 text-lg">Bond Price</div>
                          <div className="text-center text-white text-lg font-semibold">
                            {isBondLoading ? <Skeleton className="mx-auto" /> : formatCurrency(bond.bondPrice, 4)}
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2 flex justify-center">
                        <div>
                          <div className="text-center text-gray-400 text-lg">Market Price</div>
                          <div className="text-center text-white text-lg font-semibold">
                            {isBondLoading ? <Skeleton className="mx-auto" /> : formatCurrency(bond.marketPrice, 4)}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div>
                    <div className="mb-5">
                      <nav className="-mb-px flex justify-center space-x-8" aria-label="Tabs">
                        {bond.isAvailable?.[56] && (
                          <div
                            onClick={() => {
                              setNavToggle("buy");
                            }}
                            className={classNames(
                              navToggle === "buy"
                                ? "border-primary text-primary"
                                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                              "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-sm cursor-pointer",
                            )}
                            aria-current={navToggle === "buy" ? "page" : undefined}
                          >
                            <TicketIcon
                              className={classNames(
                                navToggle === "buy" ? "text-primary" : "text-gray-400 group-hover:text-gray-500",
                                "-ml-0.5 mr-2 h-5 w-5",
                              )}
                              aria-hidden="true"
                            />
                            <span>Buy</span>
                          </div>
                        )}
                        <div
                          onClick={() => {
                            setNavToggle("redeem");
                          }}
                          className={classNames(
                            navToggle === "redeem"
                              ? "border-primary text-primary"
                              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                            "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-sm cursor-pointer",
                          )}
                          aria-current={navToggle === "redeem" ? "page" : undefined}
                        >
                          <GiftIcon
                            className={classNames(
                              navToggle === "redeem" ? "text-primary" : "text-gray-400 group-hover:text-gray-500",
                              "-ml-0.5 mr-2 h-5 w-5",
                            )}
                            aria-hidden="true"
                          />
                          <span>Redeem</span>
                        </div>
                      </nav>
                    </div>

                    {navToggle === "buy" ? (
                      <BuyBonds
                        bond={bond}
                        slippage={slippage}
                        recipientAddress={recipientAddress}
                        vestingPeriod={vestingPeriod}
                      />
                    ) : (
                      <RedeemBonds bond={bond} vestingPeriod={vestingPeriod} />
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
