import React, { useState, Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { Button, Typography, Box, Slide } from "@material-ui/core";
import { redeemBond } from "../../../../slices/BondSlice";
import { useWeb3Context } from "src/hooks/web3Context";
import { trim, secondsUntilBlock, prettifySeconds, prettyVestingPeriod } from "../../../../helpers";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
// import { Skeleton } from "@material-ui/lab";
import { DisplayBondDiscount } from "../../../Bond/Bond";
import Button from "src/components/Button";
import Skeleton from "src/components/Skeleton";
import { fixedDeposits } from "src/constants/fixedDeposits";
import { usePopperTooltip } from "react-popper-tooltip";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { InformationCircleIcon } from "@heroicons/react/outline";
import classNames from "src/utils/classnames";
import tokens from "src/tokens/tokens";

interface Props {
  bond: any;
  vestingPeriod: string;
}

export default function RedeemBonds(props: Props) {
  const { bond, vestingPeriod } = props;

  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();

  const [selected, setSelected] = useState(fixedDeposits[0]);

  const isBondLoading = useSelector((state: any) => state.bonding.loading ?? true);

  const currentBlock = useSelector((state: any) => {
    return state.app.currentBlock;
  });
  const pendingTransactions = useSelector((state: any) => {
    return state.pendingTransactions;
  });
  const bondingState = useSelector((state: any) => {
    return state.bonding && state.bonding[bond.name];
  });
  const bondDetails = useSelector((state: any) => {
    return state.account.bonds && state.account.bonds[bond.name];
  });

  async function onRedeem({ autostake }: { autostake: any }) {
    await dispatch(
      redeemBond({ address, bond, networkID: chainID, provider, autostake, stakingPlan: selected.stakingPlan }),
    );
  }

  const vestingTime = () => {
    return prettyVestingPeriod(currentBlock, bond.bondMaturationBlock);
  };

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement: "top",
  });

  // const warning =
  //   "By staking sATLAS into a Fixed Deposit Plan, you understand that you will not be able to unstake your ATLAS tokens from the Single Stake ATLAS Vault. sATLAS tokens are required to unstake ATLAS.";

  // useEffect(() => {
  //   console.log(bond);
  //   console.log(bondingState);
  //   console.log(bondDetails);
  // }, []);

  return (
    <div>
      <div className="mb-5">
        <div className="flex justify-center">
          <Button
            className="max-w-sm"
            variant="gradient-pink"
            disabled={isPendingTxn(pendingTransactions, "redeem_bond_" + bond.name) || bond.pendingPayout == 0.0}
            onClick={() => {
              onRedeem({ autostake: false });
            }}
          >
            {txnButtonText(pendingTransactions, "redeem_bond_" + bond.name, "Claim")}
          </Button>
        </div>
        {bond.payoutToken === tokens.atlas && (
          <>
            <div className="flex justify-center text-white my-1">or</div>
            <div className="flex justify-center">
              <Button
                className="max-w-sm"
                variant="gradient-primary"
                disabled={
                  isPendingTxn(pendingTransactions, "redeem_bond_" + bond.name + "_autostake") ||
                  bond.pendingPayout == 0.0
                }
                onClick={() => {
                  onRedeem({ autostake: true });
                }}
              >
                {txnButtonText(pendingTransactions, "redeem_bond_" + bond.name + "_autostake", "Claim and Autostake")}
              </Button>
            </div>
          </>
        )}
        <div className="max-w-sm mx-auto mt-2">
          {bond.payoutToken === tokens.atlas && (
            <Listbox value={selected} onChange={setSelected}>
              {({ open }) => (
                <>
                  <Listbox.Label className="block text-sm font-medium text-gray-300 mb-1 flex items-center">
                    Select Fixed Deposit
                    <div ref={setTriggerRef} className="text-sm font-semibold text-white hover:opacity-80 ml-1">
                      <InformationCircleIcon className="w-5 h-5" />
                    </div>
                  </Listbox.Label>
                  <div className="relative">
                    <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm">
                      <span className="block truncate">{selected.stakePeriod / 86400} Day Fixed Deposit</span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="bg-black absolute z-10 mt-1 w-full shadow-lg max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
                        {fixedDeposits.map(option => (
                          <Listbox.Option
                            key={option.stakePeriod}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-gradient-to-br from-green-300 via-blue-500 to-purple-600"
                                  : "text-white",
                                "cursor-default select-none relative p-4 text-sm",
                              )
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <div className="flex flex-col">
                                <div className="flex justify-between">
                                  <p className={active ? "font-semibold text-black" : "font-normal"}>
                                    {option.stakePeriod / 86400} Day Fixed Deposit
                                  </p>
                                  {selected ? (
                                    <span className={active ? "text-black" : "text-white"}>
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </div>
                                <p className={classNames(active ? "text-black" : "text-gray-200", "mt-2")}>
                                  APY: {option.apy}%, Daily ROI: {option.dailyApr}%, Lock Period:{" "}
                                  {option.stakePeriod / 86400} days
                                </p>
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          )}
          {/* {selected !== fixedDeposits[4] && (
            <div className="p-3 bg-gray-700 rounded-lg my-2 flex items-center">
              <ExclamationCircleIcon className="h-6 w-6 text-gray-300 mr-2 flex-shrink-0" />
              <div className="text-xs text-gray-300">{warning}</div>
            </div>
          )} */}
        </div>
      </div>

      <div className="text-white max-w-sm mx-auto">
        <div className="flex justify-between mb-1">
          <div>Pending Rewards</div>
          <div className="text-right">
            {isBondLoading ? (
              <Skeleton />
            ) : (
              `${trim(bond.interestDue, 4)} ${bond.payoutToken ? bond.payoutToken.symbol : "ATLAS"}`
            )}
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div>Claimable Rewards</div>
          <div className="text-right">
            {isBondLoading ? (
              <Skeleton />
            ) : (
              `${trim(bond.pendingPayout, 4)} ${bond.payoutToken ? bond.payoutToken.symbol : "ATLAS"}`
            )}
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div>Time until fully vested</div>
          <div className="text-right">{isBondLoading ? <Skeleton /> : vestingTime()}</div>
        </div>
        <div className="flex justify-between mb-1">
          <div>ROI ({vestingPeriod}D)</div>
          <div className="text-right">
            {isBondLoading ? <Skeleton /> : <DisplayBondDiscount key={bond.name} bond={bond} />}
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div>Debt Ratio</div>
          <div className="text-right">{isBondLoading ? <Skeleton /> : `${trim(bond.debtRatio / 10000000, 2)}%`}</div>
        </div>
        <div className="flex justify-between">
          <div>Vesting Term</div>
          <div className="text-right">{isBondLoading ? <Skeleton /> : vestingPeriod} days</div>
        </div>
      </div>
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {`ATLAS will be automatically staked into the fixed deposit plan of your choosing.`}
        </div>
      )}
    </div>
  );
}
