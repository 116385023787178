import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

interface Props {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string | React.ReactNode;
}

export default function Modal(props: Props) {
  const { visible, onClose } = props;

  return (
    <Transition show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        // initialFocus={cancelButtonRef}
        static
        open={visible}
        onClose={() => onClose()}
      >
        <div className="min-h-screen px-4 text-center backdrop-filter backdrop-blur-md backdrop-grayscale">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element tricks the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-sm my-8 text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-xl overflow-y-auto">
              <Dialog.Title
                as="h3"
                className="flex justify-between items-start p-6 mb-3 bg-gradient-to-r from-green-400 to-blue-500"
              >
                <div className="text-xl text-left text-white select-none font-bold">{props.title}</div>
                <button
                  type="button"
                  className="select-none focus:outline-none focus:border-transparent"
                  onClick={() => onClose()}
                >
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </Dialog.Title>
              <div className="mt-2 h-full relative">{props.children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
