const Title = () => {
  return (
    <div className="mb-10">
      <div className="text-white text-lg text-center font-medium mb-1 font-atlantis">Welcome to</div>
      <div className="flex justify-center">
        <div className="w-52 h-8 relative">
          <img className="object-cover" src="/logo/white.svg" alt="Atlantis Logo" />
        </div>
      </div>
      {/* <div className="text-white text-3xl font-bold text-center">Atlantis</div> */}
    </div>
  );
};

export default Title;
