export const mainNav = [
  { name: "Home", href: "/" },
  { name: "Bonds", href: "/bonds" },
  { name: "Stake", href: "/stake" },
  { name: "Fixed Deposits", href: "/fixed-deposits" },
  { name: "Voting", href: "/voting" },
];

export const moreNav = [
  { name: "Docs", href: "https://docs-atlantis.autoshark.finance/" },
  { name: "Swap", href: "https://autoshark.finance/swap" },
  { name: "Farms", href: "https://autoshark.finance/farms" },
  { name: "Vaults", href: "https://autoshark.finance/vaults" },
];
