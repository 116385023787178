import Title from "./Title";
// import SingleStakeAtlas from "./SingleStakeAtlas";
import PoolRows from "./PoolRows/index";

function Stake() {
  return (
    <div className="flex bg-gradient-to-br from-gray-900 via-gray-700 to-gray-900 text-white">
      <div className="w-full max-w-screen-2xl mx-auto px-4 sm:px-6 md:px-8 my-12">
        <Title />
        {/* <SingleStakeAtlas /> */}
        <PoolRows />
      </div>
    </div>
  );
}

export default Stake;
