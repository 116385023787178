import { PoolConfig, PoolCategory } from "./types";
import tokens from "../tokens/tokens";

export const pools: PoolConfig[] = [
  {
    sousId: 5,
    stakingToken: tokens.atlas,
    earningToken: tokens.jaws,
    contractAddress: {
      97: "",
      56: "0x57ba4F8ef2909fc5e4e44f4981D3b264F05119DC",
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: "1.157407407",
    sortOrder: 1,
    manualAPR: true,
    hideAPR: false,
    isFinished: false,
    hasEnd: true,
    isSmartChef: true,
  },
  {
    sousId: 4,
    stakingToken: tokens.atlas,
    earningToken: tokens.jaws,
    contractAddress: {
      97: "",
      56: "0x6CF257Bf3DBa0E33Ea3bfFC53826Cbb2F4EF2641",
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: "0.4",
    sortOrder: 1,
    manualAPR: true,
    hideAPR: false,
    isFinished: true,
    hasEnd: true,
    isSmartChef: true,
  },
  {
    sousId: 3,
    stakingToken: tokens.atlas,
    earningToken: tokens.fins,
    contractAddress: {
      97: "",
      56: "0x271566369339C3f0A21630122E788024EA221AC5",
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: "0.2",
    sortOrder: 1,
    manualAPR: true,
    hideAPR: false,
    isFinished: true,
    hasEnd: true,
    isSmartChef: true,
  },
  {
    sousId: 2,
    stakingToken: tokens.atlas,
    earningToken: tokens.jaws,
    contractAddress: {
      97: "",
      56: "0xb574B6923FD6D4342EFF61f44B395192BeE0c7ce",
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: "0.3884797500300445",
    sortOrder: 1,
    manualAPR: true,
    hideAPR: false,
    isFinished: true,
    hasEnd: true,
    isSmartChef: true,
  },
  {
    sousId: 1,
    stakingToken: tokens.atlas,
    earningToken: tokens.fins,
    contractAddress: {
      97: "",
      56: "0x94977E8AF9A858D1E73c8C17A041204190Eb33b0",
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: "0.1698938271604938",
    sortOrder: 1,
    manualAPR: true,
    hideAPR: false,
    isFinished: true,
    hasEnd: true,
    isSmartChef: true,
  },
];
