import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3Context } from "src/hooks/web3Context";
// import { getDividendPool } from "src/slices/StakingSlice";
import PoolRow from "./PoolRow";
// import { getSyrupPool } from "src/slices/SyrupSlice";
import { getFixedDepositPool } from "src/slices/FixedDepositSlice";
import useRefresh from "src/hooks/useRefresh";

function PoolRows() {
  const dispatch = useDispatch();
  const { provider, address, connected, connect, chainID } = useWeb3Context();

  const fixedDepositPools = useSelector(state => {
    return state.fixedDeposit;
  });

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });

  const { fastRefresh } = useRefresh();
  useEffect(() => {
    if (currentBlock) {
      dispatch(getFixedDepositPool({ account: address, currentBlock }));
    }
  }, [dispatch, currentBlock, fastRefresh]);

  const poolsToDisplay = useMemo(() => {
    const _poolsToDisplay = [];
    if (fixedDepositPools.status == "active") {
      Object.keys(fixedDepositPools.data).map(k => {
        const pool = fixedDepositPools.data[k];
        if (!pool.isFinished) {
          _poolsToDisplay.push(pool);
        } else if (pool?.userInfo?.filter(x => parseInt(x.amount) > 0).length > 0) {
          _poolsToDisplay.push(pool);
        }
      });
    } else {
      Object.keys(fixedDepositPools.data).map(k => {
        const pool = fixedDepositPools.data[k];
        _poolsToDisplay.push(pool);
      });
    }
    return _poolsToDisplay;
  }, [fixedDepositPools.status]);

  return (
    <>
      {poolsToDisplay.map(pool => {
        return (
          <PoolRow
            key={pool.sousId}
            pool={pool}
            loading={fixedDepositPools.status !== "active"}
            currentBlock={currentBlock}
          />
        );
      })}
    </>
  );
}

export default PoolRows;
