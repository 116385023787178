import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// 3rd party libraries
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

// own app
import classNames from "../../utils/classnames";
import { mainNav, moreNav } from "../../constants/navigation";
// import ConnectWalletPrompt from "../ConnectWalletPrompt";
import ConnectMenu from "./ConnectMenu.jsx";
import More from "./More";
import { formatCurrency } from "../../helpers";
import tokens from "../../tokens/tokens";
import Skeleton from "../Skeleton";
import { getAddress } from "src/utils/addressHelpers";

const Menu: React.FC = () => {
  const router = useLocation();

  const isAppLoading = useSelector((state: any) => state.app.loading);
  const marketPrice = useSelector((state: any) => {
    return state.app.marketPrice;
  });

  return (
    <Disclosure as="nav" className="bg-background sticky top-0 z-50">
      {({ open }: { open: boolean }) => (
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-background inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-gray-900">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center text-white">
                  {/* AUTOSHARK */}
                  <Link to="/">
                    <div className="h-6 w-28 sm:w-40 sm:h-8 relative cursor-pointer">
                      <img className="object-cover" src="/logo/white.svg" alt="Atlantis Logo" />
                    </div>
                  </Link>
                </div>
                <div className="hidden sm:-my-px sm:ml-6 lg:flex sm:space-x-8">
                  {mainNav.map(item => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.href === router.pathname ? "text-primary" : "text-white hover:text-primary",
                        "inline-flex items-center px-1 pt-1 text-sm font-medium",
                      )}
                      aria-current={item.href === router.pathname ? "page" : undefined}
                    >
                      {item.name}
                    </a>
                  ))}
                  <div className="flex items-center">
                    <More />
                  </div>
                </div>
              </div>
              <div className="sm:ml-6 flex sm:items-center text-white">
                <a
                  className="mr-2 sm:mr-8 flex items-center"
                  href="https://poocoin.app/tokens/0xcf87Ccf958d728f50d8ae5E4f15Bc4cA5733cDf5"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="w-6 h-6 sm:w-8 sm:h-8 relative">
                    <img
                      className="object-cover"
                      src={`/token/${getAddress(tokens.atlas.address)}.svg`}
                      alt="Atlantis Logo"
                    />
                  </div>
                  <div className="ml-1 text-xs sm:ml-2 sm:text-sm">
                    {isAppLoading ? <Skeleton /> : formatCurrency(marketPrice, 2)}
                  </div>
                </a>

                <ConnectMenu isMenu={true} />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {[...mainNav, ...moreNav].map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    "border-l-4",
                    item.href === router.pathname ? "text-primary border-primary" : "text-white hover:text-primary",
                    "block pl-3 pr-4 py-2 text-base font-medium border-transparent",
                  )}
                  aria-current={item.href === router.pathname ? "page" : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Menu;
