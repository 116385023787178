import { StaticJsonRpcProvider, JsonRpcSigner } from "@ethersproject/providers";
import { BigNumberish, ethers } from "ethers";

import TreasuryContract from "src/abi/TreasuryContract.json";
import { abi as ierc20Abi } from "src/abi/IERC20.json";
import { getBondCalculator } from "../../src/helpers/BondCalculator";
import { AtlantisTreasury, ReserveBondDepository, PairContract } from "src/typechain";
import { addresses } from "src/constants";
// import React from "react";
import { Token } from "../tokens/types";

export enum NetworkID {
  Mainnet = 56,
  Testnet = 4,
}

export enum BondType {
  StableAsset,
  LP,
  Partner,
  Fixed,
}

export interface BondAddresses {
  reserveAddress: string;
  bondAddress: string;
  poolAddress?: string;
}

export interface NetworkAddresses {
  [NetworkID.Mainnet]: BondAddresses;
  [NetworkID.Testnet]: BondAddresses;
}

export interface Available {
  [NetworkID.Mainnet]?: boolean;
  [NetworkID.Testnet]?: boolean;
}

interface BondOpts {
  name: string; // Internal name used for references
  displayName: string; // Displayname on UI
  isAvailable: Available; // set false to hide
  // bondIconSvg: React.ReactNode; //  SVG path for icons
  bondContractABI: ethers.ContractInterface; // ABI for contract
  networkAddrs: NetworkAddresses; // Mapping of network --> Addresses
  bondToken: Token; // Unused, but native token to buy the bond. // Unused, but native token to buy the bond.
  quoteToken?: Token | undefined; // Unused, but native token to buy the bond. // Unused, but native token to buy the bond.
  payoutToken?: Token | undefined; // Unused, but native token to buy the bond. // Unused, but native token to buy the bond.
  isPool?: boolean;
}

// Technically only exporting for the interface
export abstract class Bond {
  // Standard Bond fields regardless of LP bonds or stable bonds.
  readonly name: string;
  readonly displayName: string;
  readonly type: BondType;
  readonly isAvailable: Available;
  // readonly bondIconSvg: React.ReactNode;
  readonly bondContractABI: ethers.ContractInterface; // Bond ABI
  readonly networkAddrs: NetworkAddresses;
  readonly bondToken: Token | undefined;
  readonly quoteToken: Token | undefined;
  readonly payoutToken: Token | undefined;

  // The following two fields will differ on how they are set depending on bond type
  abstract isLP: boolean;
  abstract reserveContract: ethers.ContractInterface; // Token ABI
  abstract displayUnits: string;

  // Async method that returns a Promise
  abstract getTreasuryBalance(networkID: NetworkID, provider: StaticJsonRpcProvider): Promise<number>;

  constructor(type: BondType, bondOpts: BondOpts) {
    this.name = bondOpts.name;
    this.displayName = bondOpts.displayName;
    this.type = type;
    this.isAvailable = bondOpts.isAvailable;
    // this.bondIconSvg = bondOpts.bondIconSvg;
    this.bondContractABI = bondOpts.bondContractABI;
    this.networkAddrs = bondOpts.networkAddrs;
    this.bondToken = bondOpts.bondToken;
    this.quoteToken = bondOpts.quoteToken;
    this.payoutToken = bondOpts.payoutToken;
  }

  /**
   * makes isAvailable accessible within Bonds.ts
   * @param networkID
   * @returns boolean
   */
  getAvailability(networkID: NetworkID) {
    return this.isAvailable[networkID];
  }

  getAddressForBond(networkID: NetworkID) {
    return this.networkAddrs[networkID].bondAddress;
  }
  getContractForBond(networkID: NetworkID, provider: StaticJsonRpcProvider | JsonRpcSigner) {
    const bondAddress = this.getAddressForBond(networkID);
    return new ethers.Contract(bondAddress, this.bondContractABI, provider) as ReserveBondDepository;
  }

  getAddressForReserve(networkID: NetworkID) {
    return this.networkAddrs[networkID].reserveAddress;
  }
  getContractForReserve(networkID: NetworkID, provider: StaticJsonRpcProvider | JsonRpcSigner) {
    const bondAddress = this.getAddressForReserve(networkID);
    return new ethers.Contract(bondAddress, this.reserveContract, provider) as PairContract;
  }

  async getBondReservePrice(networkID: NetworkID, provider: StaticJsonRpcProvider | JsonRpcSigner) {
    const pairContract = this.getContractForReserve(networkID, provider);
    const reserves = await pairContract.getReserves();
    const marketPrice = Number(reserves[1].toString()) / Number(reserves[0].toString()) / 10 ** 9;
    return marketPrice;
  }
}

// Keep all LP specific fields/logic within the LPBond class
export interface LPBondOpts extends BondOpts {
  reserveContract: ethers.ContractInterface;
  lpUrl: string;
  isPool?: boolean;
  quoteToken: Token;
}

export class LPBond extends Bond {
  readonly isLP = true;
  readonly lpUrl: string;
  readonly isPool?: boolean;
  readonly reserveContract: ethers.ContractInterface;
  readonly displayUnits: string;
  readonly quoteToken: Token;

  constructor(lpBondOpts: LPBondOpts) {
    super(BondType.LP, lpBondOpts);

    this.lpUrl = lpBondOpts.lpUrl;
    this.reserveContract = lpBondOpts.reserveContract;
    this.isPool = lpBondOpts.isPool;
    this.displayUnits = "LP";
    this.quoteToken = lpBondOpts.quoteToken;
  }
  async getTreasuryBalance(networkID: NetworkID, provider: StaticJsonRpcProvider) {
    const treasuryAddress = this.isPool
      ? this.networkAddrs[NetworkID.Mainnet].poolAddress ?? ""
      : addresses[networkID].TREASURY_ADDRESS;
    const token = this.getContractForReserve(networkID, provider);
    const tokenAddress = this.getAddressForReserve(networkID);
    const bondCalculator = getBondCalculator(networkID, provider);
    console.log(bondCalculator);
    const tokenAmount = await token.balanceOf(treasuryAddress);
    const valuation = await bondCalculator.valuation(tokenAddress, tokenAmount);
    const markdown = await bondCalculator.markdown(tokenAddress);
    let tokenUSD = (Number(valuation.toString()) / Math.pow(10, 9)) * (Number(markdown.toString()) / Math.pow(10, 18));
    return Number(tokenUSD.toString());
  }
}

// Generic BondClass we should be using everywhere
// Assumes the token being deposited follows the standard ERC20 spec
export interface StableBondOpts extends BondOpts {}
export class StableBond extends Bond {
  readonly isLP = false;
  readonly reserveContract: ethers.ContractInterface;
  readonly displayUnits: string;

  constructor(stableBondOpts: StableBondOpts) {
    super(BondType.StableAsset, stableBondOpts);
    // For stable bonds the display units are the same as the actual token
    this.displayUnits = stableBondOpts.displayName;
    this.reserveContract = ierc20Abi; // The Standard ierc20Abi since they're normal tokens
  }
  // customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
  //   const ethBondContract = this.getContractForBond(networkID, provider);
  //   let ethPrice: BigNumberish = await ethBondContract.assetPrice();
  //   ethPrice = Number(ethPrice.toString()) / Math.pow(10, 8);
  //   const treasuryContract = new ethers.Contract(
  //     addresses[networkID].TREASURY_ADDRESS,
  //     TreasuryContract,
  //     provider,
  //   ) as AtlantisTreasury;
  //   let ethAmount: BigNumberish = await treasuryContract.balanceOf(this.networkAddrs[NetworkID.Mainnet].reserveAddress);
  //   ethAmount = Number(ethAmount.toString()) / Math.pow(10, 18);
  //   return ethAmount * ethPrice;
  // },
  async getTreasuryBalance(networkID: NetworkID, provider: StaticJsonRpcProvider) {
    const treasuryContract = new ethers.Contract(
      addresses[networkID].TREASURY_ADDRESS,
      TreasuryContract,
      provider,
    ) as AtlantisTreasury;
    let tokenAmount: BigNumberish = await treasuryContract.balanceOf(
      this.networkAddrs[NetworkID.Mainnet].reserveAddress,
    );
    tokenAmount = Number(tokenAmount.toString()) / Math.pow(10, 18);
    // return ethAmount * ethPrice;

    // let token = this.getContractForReserve(networkID, provider);
    // let tokenAmount = await token.balanceOf(addresses[networkID].TREASURY_ADDRESS);
    return tokenAmount;
  }
}

// These are special bonds that have different valuation methods
export interface CustomBondOpts extends BondOpts {
  reserveContract: ethers.ContractInterface;
  bondType: number;
  lpUrl: string;
  customTreasuryBalanceFunc: (
    this: CustomBond,
    networkID: NetworkID,
    provider: StaticJsonRpcProvider,
  ) => Promise<number>;
}
export class CustomBond extends Bond {
  readonly isLP: boolean;
  readonly isPool?: boolean;

  getTreasuryBalance(networkID: NetworkID, provider: StaticJsonRpcProvider): Promise<number> {
    throw new Error("Method not implemented.");
  }
  readonly reserveContract: ethers.ContractInterface;
  readonly displayUnits: string;
  readonly lpUrl: string;

  constructor(customBondOpts: CustomBondOpts) {
    super(customBondOpts.bondType, customBondOpts);
    this.isPool = customBondOpts.isPool;

    if (customBondOpts.bondType === BondType.LP) {
      this.isLP = true;
    } else {
      this.isLP = false;
    }
    this.lpUrl = customBondOpts.lpUrl;
    // For stable bonds the display units are the same as the actual token
    this.displayUnits = customBondOpts.displayName;
    this.reserveContract = customBondOpts.reserveContract;
    this.getTreasuryBalance = customBondOpts.customTreasuryBalanceFunc.bind(this);
  }
}
