// import OlympusLogo from "../../assets/Olympus Logo.svg";
// import "./notfound.scss";
import Button from "src/components/Button";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="flex bg-gradient-to-br from-gray-900 via-gray-700 to-gray-900">
      <div className="w-full max-w-screen-2xl mx-auto px-4 sm:px-6 md:px-8 my-12">
        <div className="flex justify-center pt-14 pb-20">
          <div className="text-center">
            <img className="w-10 h-10 mx-auto" src="/atlantis icon white.svg" alt="Atlantis Logo" />
            <h4 className="text-xl font-medium text-white mb-5">Page not found</h4>
            <Link to="/">
              <Button className="flex justify-center items-center">
                <div className="mx-2">Back to Atlantis</div>
                <ArrowRightIcon className="w-4 h-4 text-white" />
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
