import { useState, useEffect } from "react";
// import BondLogo from "../../components/BondLogo";
import { DisplayBondPrice, DisplayBondDiscount } from "../Bond/Bond";
// import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
// import { NavLink } from "react-router-dom";
// import "./choosebond.scss";
// import { Skeleton } from "@material-ui/lab";
// import useBonds from "src/hooks/Bonds";
import { useWeb3Context } from "../../hooks/web3Context";
import Pair from "src/components/TokenImages/Pair";
import Single from "src/components/TokenImages/Single";
import Button from "src/components/Button";
import BondsModal from "./BondsModal";
import Skeleton from "src/components/Skeleton";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import tokens from "src/tokens/tokens";
import ConnectMenu from "src/components/Menu/ConnectMenu.jsx";
import { prettifySeconds, secondsUntilBlock } from "../../helpers";

export function BondDataCard({ bond, currentBlock, isBondLoading }) {
  const { address } = useWeb3Context();
  // const { loading } = useBonds(chainID);
  // const isBondLoading = !bond.bondPrice ?? true;
  const [bondVisible, setBondVisible] = useState(false);
  const [vestingPeriod, setVestingPeriod] = useState();

  useEffect(() => {
    const vestingBlock = parseInt(currentBlock) + parseInt(bond.vestingTerm);
    const seconds = secondsUntilBlock(currentBlock, vestingBlock);
    setVestingPeriod(`${seconds ? seconds / 86400 : "-"}`);
  });

  return (
    <div className="rounded-lg border border-gray-600 bg-gray-900 max-w-3xl mx-auto p-5 mb-3">
      <div className="flex mb-3">
        <div className="mr-3">
          {bond.isLP || bond.quoteToken !== undefined ? (
            <Pair topToken={bond.quoteToken} bottomToken={bond.bondToken} scale="xs" />
          ) : (
            <Single token={bond.bondToken} scale="xs" />
          )}
        </div>
        <div className="flex items-center flex-grow justify-between">
          <div className="text-white mr-1">
            <div>{bond.displayName}</div>
            {bond.payoutToken && bond.payoutToken !== tokens.atlas && (
              <div className="text-primary text-xxs uppercase flex">Earn {bond.payoutToken.symbol}</div>
            )}
          </div>
          <div className="text-gray-400">
            <a href={bond.lpUrl} target="_blank" rel="noreferrer">
              <ExternalLinkIcon className="w-4 h-4 text-gray-500" />
            </a>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between mb-3">
        {bond.payoutToken !== tokens.atlas ? (
          <>
            <div className="text-gray-400">Vesting Period</div>
            <div className="text-white">{isBondLoading ? <Skeleton /> : `${vestingPeriod} days`}</div>
          </>
        ) : (
          <>
            <div className="text-gray-400">Price</div>
            <div className="text-white">
              <>{isBondLoading ? <Skeleton /> : <DisplayBondPrice key={bond.name} bond={bond} />}</>
            </div>
          </>
        )}
      </div>
      <div className="flex items-center justify-between mb-3">
        <div className="text-gray-400">ROI ({vestingPeriod}D)</div>
        <div className="text-white">
          {isBondLoading ? <Skeleton /> : <DisplayBondDiscount key={bond.name} bond={bond} />}
        </div>
      </div>

      <div className="flex items-center justify-between mb-3">
        <div className="text-gray-400">Purchased</div>
        <div className="text-white">
          {isBondLoading ? (
            <Skeleton />
          ) : (
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(bond.purchased)
          )}
        </div>
      </div>
      <div className="ml-auto">
        {!address ? (
          <ConnectMenu width="w-full" />
        ) : bond.isAvailable?.[56] ? (
          <Button onClick={() => setBondVisible(true)} className="w-full" scale="xs">
            Buy Bonds
          </Button>
        ) : bond.pendingPayout > 0 ? (
          <Button onClick={() => setBondVisible(true)} className="w-full" scale="xs">
            Claim
          </Button>
        ) : (
          <Button disabled className="w-full" scale="xs">
            Sold Out
          </Button>
        )}
      </div>
      <BondsModal
        bond={bond}
        visible={bondVisible}
        onClose={() => setBondVisible(false)}
        vestingPeriod={vestingPeriod}
      />
    </div>
  );
}

export function BondTableData({ bond, currentBlock, isBondLoading }) {
  const { address } = useWeb3Context();
  // Use BondPrice as indicator of loading.
  // const isBondLoading = !bond.bondPrice ?? true;
  const [bondVisible, setBondVisible] = useState(false);
  const [vestingPeriod, setVestingPeriod] = useState();

  useEffect(() => {
    const vestingBlock = parseInt(currentBlock) + parseInt(bond.vestingTerm);
    const seconds = secondsUntilBlock(currentBlock, vestingBlock);
    setVestingPeriod(`${seconds ? seconds / 86400 : "-"}`);
  });

  return (
    <div className="rounded-lg border border-gray-600 bg-gray-900 max-w-3xl lg:max-w-4xl mx-auto p-5 mb-3 flex items-center">
      <div className="w-20 flex-grow-0">
        {bond.isLP || bond.quoteToken !== undefined ? (
          <Pair topToken={bond.quoteToken} bottomToken={bond.bondToken} />
        ) : (
          <Single token={bond.bondToken} />
        )}
      </div>
      <div className="w-36 md:w-40">
        <div className="text-gray-500 font-medium uppercase text-xxs">Bond</div>
        <div className="text-white text-xs uppercase flex items-center">
          {bond.isLP || bond.quoteToken !== undefined
            ? `${bond.bondToken.symbol} - ${bond.quoteToken.symbol}`
            : bond.bondToken.symbol}
          {bond.isLP ||
            (bond.quoteToken !== undefined && (
              <a className="ml-1" href={bond.lpUrl} target="_blank" rel="noreferrer">
                <ExternalLinkIcon className="w-4 h-4 text-gray-500" />
              </a>
            ))}
        </div>
        {bond.payoutToken && bond.payoutToken !== tokens.atlas && (
          <div className="text-primary text-xxs uppercase flex">Earn {bond.payoutToken.symbol}</div>
        )}
      </div>
      <div className="w-32 md:w-40">
        {bond.payoutToken !== tokens.atlas ? (
          <>
            <div className="text-gray-500 font-medium uppercase text-xxs">Vesting Period</div>
            <div className="text-white text-xs">{isBondLoading ? <Skeleton /> : `${vestingPeriod} days`}</div>
          </>
        ) : (
          <>
            <div className="text-gray-500 font-medium uppercase text-xxs">Price</div>
            <div className="text-white text-xs">
              {isBondLoading ? <Skeleton /> : <DisplayBondPrice key={bond.name} bond={bond} />}
            </div>
          </>
        )}
      </div>
      <div className="w-32 md:w-40">
        <div className="text-gray-500 font-medium uppercase text-xxs">ROI ({vestingPeriod}D)</div>
        <div className="text-white text-xs">
          {isBondLoading ? <Skeleton /> : <DisplayBondDiscount key={bond.name} bond={bond} />}
        </div>
      </div>
      <div className="w-32 md:w-40">
        <div className="text-gray-500 font-medium uppercase text-xxs">Purchased</div>
        <div className="text-white text-xs">
          {isBondLoading ? (
            <Skeleton />
          ) : (
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(bond.purchased)
          )}
        </div>
      </div>

      <div className="lg:w-36 ml-auto">
        {!address ? (
          <div className="flex justify-center mx-auto">
            <ConnectMenu />
          </div>
        ) : bond.isAvailable?.[56] ? (
          <Button onClick={() => setBondVisible(true)} className="px-8 lg:px-4 lg:w-full">
            Buy <span className="hidden lg:inline">Bonds</span>
          </Button>
        ) : bond.pendingPayout > 0 ? (
          <Button onClick={() => setBondVisible(true)} className="px-8 lg:px-4 lg:w-full">
            Claim
          </Button>
        ) : (
          <Button disabled className="px-8 lg:px-4 lg:w-full">
            Sold Out
          </Button>
        )}
      </div>
      <BondsModal
        bond={bond}
        vestingPeriod={vestingPeriod}
        visible={bondVisible}
        onClose={() => setBondVisible(false)}
      />
    </div>
  );
}
