import { MainLink, SocialLink, footer } from "../constants/footer";

const Footer: React.FC = () => {
  return (
    <footer className="bg-background">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {footer.main.map((item: MainLink) => (
            <div key={item.name} className="px-5 py-2">
              <a
                href={item.href}
                target={item.external ? "_blank" : "_self"}
                rel="noreferrer"
                className="text-base text-gray-500 hover:text-gray-200"
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          {footer.social.map((item: SocialLink) => (
            <a
              key={item.name}
              href={item.href}
              target={item.external ? "_blank" : "_self"}
              rel="noreferrer"
              className="text-gray-400 hover:text-gray-200"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
