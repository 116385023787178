import BigNumberJS from "bignumber.js";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
// import { getBondCalculator } from "src/helpers/BondCalculator";
import { RootState } from "src/store";
// import { AtlantisPool, PriceCalculatorBSC, VaultAtlasToAtlas } from "src/typechain";
// import atlantisPoolAbi from "src/abi/AtlantisPool.json";
import { abi as erc20ABI } from "src/abi/ExpandedIERC20.json";
import fixedDepositAbi from "src/abi/FixedDepositStaking.json";
import multicall from "src/utils/multicall";
// import dashboardAbi from "src/abi/DashboardBSC.json";
// import calculatorAbi from "src/abi/PriceCalculator.json";
// import {
//   IApproveBondAsyncThunk,
//   IBondAssetAsyncThunk,
//   ICalcBondDetailsAsyncThunk,
//   IJsonRPCError,
//   IRedeemAllBondsAsyncThunk,
//   IRedeemBondAsyncThunk,
// } from "./interfaces";
import { NetworkID } from "src/lib/Bond";
// import { aprToApy, apyToApr } from "src/helpers/apr";
// import { DashboardBSC } from "src/typechain/DashboardBSC";
import { fixedDeposits } from "src/constants/fixedDeposits";
// import tokens from "src/tokens/tokens";
// import { getPoolApr } from "src/utils/apr";
// import { getBalanceNumber } from "src/utils/formatBalance";
// import { getAddress, getWbnbAddress } from "src/utils/addressHelpers";
import { getAddress } from "src/utils/addressHelpers";
import cakeABI from "src/abi/cake.json";

export const fetchPoolsTotalStaking = async () => {
  const nonBnbPools = fixedDeposits.filter(p => p.stakingToken.symbol !== "BNB");

  const callsNonBnbPools = nonBnbPools.map(pool => {
    return {
      address: getAddress(pool.stakingToken.address),
      name: "balanceOf",
      params: [getAddress(pool.contractAddress)],
    };
  });

  const nonBnbPoolsTotalStaked = await multicall(cakeABI, callsNonBnbPools);

  return [
    ...nonBnbPools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumberJS(nonBnbPoolsTotalStaked[index]).toJSON(),
    })),
  ];
};

export interface IStakeDetails {}
export const getFixedDepositPool = createAsyncThunk(
  "staking/getFixedDepositPool",
  async (
    { account, currentBlock }: { account: string; currentBlock: number },
    { dispatch },
  ): Promise<IStakeDetails> => {
    const totalStakings = await fetchPoolsTotalStaking();

    const callsDepositIds = fixedDeposits.map(p => ({
      address: p.contractAddress[NetworkID.Mainnet],
      name: "getDepositId",
      params: [account],
    }));

    const depositIds = await multicall(fixedDepositAbi, callsDepositIds);

    const callsAllowance = fixedDeposits.map(p => ({
      address: p?.stakingToken?.address?.[NetworkID.Mainnet] ?? "",
      name: "allowance",
      params: [account, p.contractAddress[NetworkID.Mainnet]],
    }));

    const newFixedVaults = fixedDeposits.map(async (p, index) => {
      const depositId = new BigNumberJS(depositIds[index])?.toNumber();
      const callsUserInfo: any[] = [];
      for (let i = depositId; i > 0; i--) {
        callsUserInfo.push({
          address: p.contractAddress[NetworkID.Mainnet],
          name: "getUserInfo",
          params: [account, i],
        });
      }
      const _userInfo = await multicall(fixedDepositAbi, callsUserInfo);
      const userInfo = _userInfo.map(([amount, depositedAt]: any, index: number) => ({
        id: callsUserInfo[index].params[1],
        amount: amount?.toString(),
        depositedAt: depositedAt?.toString(),
      }));
      return { ...p, userInfo };
    });

    const results = await Promise.allSettled(newFixedVaults);

    const finalVaults = results.map(item => (item as any).value);

    const callsUserBalance = fixedDeposits.map(p => ({
      address: p?.stakingToken?.address?.[NetworkID.Mainnet] ?? "",
      name: "balanceOf",
      params: [account],
    }));

    const allowances = await multicall(erc20ABI, callsAllowance);
    const tokenBalancesRaw = await multicall(erc20ABI, callsUserBalance);

    const data = finalVaults.map((poolConfig, index) => {
      const allowance = new BigNumberJS(allowances[index]).toJSON();
      const walletBalance = new BigNumberJS(tokenBalancesRaw[index]).toJSON();
      // const stakedBalance = new BigNumberJS(userInfo[index].amount._hex).toJSON();
      // const pendingRewards = new BigNumberJS(pendingRewardRaw[index]).toJSON();
      return { ...poolConfig, allowance, walletBalance };
    });

    const liveData = data.map(pool => {
      const totalStaking = totalStakings.find(entry => entry.sousId === pool.sousId);

      const apr = 0.2;
      return {
        ...pool,
        ...totalStaking,
        apr,
      };
    });

    // console.log({ liveData });
    return liveData;
  },
);

// Note(zx): this is a barebones interface for the state. Update to be more accurate
interface IStakingSlice {
  status: string;
  [key: string]: any;
}

const setStakeState = (state: IStakingSlice, payload: any) => {
  const newState = { ...state.data, ...payload };
  state.data = newState;
  state.loading = false;
};

const initialState: IStakingSlice = {
  status: "idle",
  data: { ...fixedDeposits },
};

const fixedDepositSlice = createSlice({
  name: "fixedDeposit",
  initialState,
  reducers: {
    fetchStakeSuccess(state, action) {
      state.data = action.payload;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getFixedDepositPool.pending, state => {
        state.loading = true;
      })
      .addCase(getFixedDepositPool.fulfilled, (state, action) => {
        setStakeState(state, action.payload);
        state.status = "active";
        state.loading = false;
      })
      .addCase(getFixedDepositPool.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error.message);
      });
  },
});

export default fixedDepositSlice.reducer;

export const { fetchStakeSuccess } = fixedDepositSlice.actions;

const baseInfo = (state: RootState) => state.fixedDeposit;

export const getFixedDepositState = createSelector(baseInfo, fixedDeposit => fixedDeposit);
