import { useEffect, useState } from "react";
import Button from "src/components/Button";
import { useAddress } from "src/hooks";
import Balance from "src/components/Balance";
import Skeleton from "src/components/Skeleton";
import { useHarvestPool } from "../../hooks/useStakeFixedDeposit";
import { FixedDeposit, ActiveDeposit } from "src/constants/types";
// import { prettyVestingPeriod } from "src/helpers";
import classNames from "src/utils/classnames";
import formatSeconds from "src/utils/formatTime";
import useRefresh from "src/hooks/useRefresh";
import { usePopperTooltip } from "react-popper-tooltip";
import { InformationCircleIcon } from "@heroicons/react/outline";

interface Props {
  pool: FixedDeposit;
  loading: boolean;
  currentBlock: number;
}

interface EnhancedActiveDeposit {
  id: number;
  depositedAt: string;
  isUnlocked: boolean;
  timeLeft: string;
  date: string;
  time: string;
  earned: number;
  amount: number;
}

function getSecLeft(deposit: ActiveDeposit, pool: FixedDeposit) {
  const endTime = (parseInt(deposit.depositedAt) + pool.stakePeriod) * 1000;
  const now = new Date();
  const currentTime = now.getTime();
  const isUnlocked = currentTime > endTime;
  const secsLeft = Math.round((endTime - currentTime) / 1000);
  const timeLeft = secsLeft > 0 ? formatSeconds(secsLeft) : "-";
  return { isUnlocked, secsLeft, currentTime, endTime, timeLeft };
}

function getEarned(currentTime: number, endTime: number, depositedAt: number, dailyApr: number, staked: number) {
  const secsElapsed = Math.round(Math.min(currentTime, endTime) / 1000 - depositedAt);
  const daysElapsed = secsElapsed / 86400;
  return (daysElapsed * (dailyApr / 100) * staked) / 1e9;
}

function Harvest(props: Props) {
  const { pool, loading, currentBlock } = props;
  const account = useAddress();

  const { onReward, onEmergencyWithdraw } = useHarvestPool(pool.contractAddress["56"]);

  const [activeFixedDeposits, setActiveFixedDeposits] = useState<EnhancedActiveDeposit[]>([]);
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalEarned, setTotalEarned] = useState(0);

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement: "top",
  });

  const harvestTitle = (
    <div className="text-white font-medium uppercase text-xs mb-1">
      <span className="text-primary">Active</span> Fixed Deposit{activeFixedDeposits.length > 1 ? "s" : ""}
    </div>
  );

  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const _activeFixedDeposits: EnhancedActiveDeposit[] = [];
    let _totalStaked = 0;
    let _totalEarned = 0;
    for (const _deposit in pool.userInfo) {
      const deposit = pool.userInfo[_deposit];

      // calculate starting datetime
      const depositedAt = new Date(parseInt(deposit.depositedAt) * 1000);
      const { isUnlocked, secsLeft, currentTime, endTime, timeLeft } = getSecLeft(deposit, pool);
      const earned = getEarned(
        currentTime,
        endTime,
        parseInt(deposit.depositedAt),
        pool.dailyApr,
        parseInt(deposit.amount),
      );
      if (parseInt(deposit.amount) > 0) {
        _totalStaked += parseInt(deposit.amount) / 1e9;
        _totalEarned += earned;
        _activeFixedDeposits.push({
          ...deposit,
          amount: parseInt(deposit.amount) / 1e9,
          isUnlocked,
          timeLeft,
          date: depositedAt.toLocaleDateString(),
          time: depositedAt.toLocaleTimeString(),
          earned,
        });
      }
    }
    setTotalStaked(_totalStaked);
    setTotalEarned(_totalEarned);
    setActiveFixedDeposits(_activeFixedDeposits);
  }, [pool.userInfo, fastRefresh]);

  if (!account || !pool.userInfo || activeFixedDeposits.length === 0) {
    return (
      <div className={classNames("w-full rounded-lg p-4", pool.isFinished ? "bg-gray-800" : "bg-gray-900")}>
        {harvestTitle}
        <div className="h-10 flex items-center">
          <div className="text-gray-500">You do not have any fixed deposits yet</div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={classNames("w-full rounded-lg p-4", pool.isFinished ? "bg-gray-800" : "bg-gray-900")}>
        {harvestTitle}
        <div>
          <Skeleton className="w-full" height="h-10" />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        activeFixedDeposits.length > 1 ? "h-64 sm:h-56" : "h-52 sm:h-40 lg:h-32",
        "w-full rounded-lg pl-4 py-4 pr-3",
        pool.isFinished ? "bg-gray-800" : "bg-gray-900",
      )}
    >
      <div className="flex items-center">
        <div className="text-primary font-medium uppercase text-xs mb-1 mr-1">{activeFixedDeposits.length}</div>{" "}
        {harvestTitle}
      </div>

      {activeFixedDeposits.length > 1 && (
        <div className="sm:flex justify-between items-center mb-3 lg:mb-1">
          <div className="flex items-center justify-between sm:justify-start sm:mr-3 text-xs">
            <div>Total Staked:</div>
            <div className="flex items-center">
              {<Balance value={totalStaked} decimals={4} />}
              <div className="ml-1">sATLAS</div>
            </div>
          </div>
          <div className="flex items-center justify-between sm:justify-start text-xs">
            <div>Total Earned:</div>
            <div className="flex items-center">
              {<Balance value={totalEarned} decimals={4} />}
              <div className="ml-1">ATLAS</div>
            </div>
          </div>
        </div>
      )}
      <div className="hidden lg:flex justify-between items-center mt-1">
        <div className="flex items-center flex-grow">
          <div className="w-28">
            <div className="text-gray-500 font-medium uppercase text-xs">Deposited</div>
          </div>
          <div className="w-36">
            <div className="text-gray-500 font-medium uppercase text-xs">Staked</div>
          </div>
          <div className="w-36">
            <div className="text-gray-500 font-medium uppercase text-xs">Earned</div>
          </div>
          <div className="w-44">
            <div className="text-gray-500 font-medium uppercase text-xs">Time Left</div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          activeFixedDeposits.length > 1 ? "max-h-36 sm:max-h-32 lg:max-h-28" : "max-h-40 sm:max-h-36 lg:max-h-28",
          "overflow-auto",
        )}
      >
        {activeFixedDeposits.map((deposit: EnhancedActiveDeposit, index: number) => {
          return (
            <div
              key={`${deposit.depositedAt}-${index}`}
              className={classNames(
                "flex justify-between items-start",
                index === activeFixedDeposits.length - 1 ? "" : "border-b border-gray-600 mb-2 pb-2",
              )}
            >
              <div className="sm:flex items-start justify-between w-full">
                <div className="flex items-start justify-between sm:justify-start w-full">
                  <div className="lg:flex">
                    <div className="sm:w-28">
                      <div className="text-gray-500 font-medium uppercase text-xxs lg:hidden">Deposited At</div>
                      <div className="text-white">
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <div>
                            <div className="text-xs">{deposit.date}</div>
                            <div className="text-xxs">{deposit.time}</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="sm:w-28 md:w-36">
                      <div className="text-gray-500 font-medium uppercase text-xxs lg:hidden">Staked</div>
                      <div className="text-white text-xxs">
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <div className="flex items-center">
                            <Balance value={deposit.amount} decimals={4} />
                            <span className="ml-1">{pool.stakingToken.symbol}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col-reverse lg:flex-row pr-3 sm:pr-0">
                    <div className="sm:w-28 md:w-36">
                      <div className="text-gray-500 font-medium uppercase text-xxs lg:hidden text-right sm:text-left">
                        Earned
                      </div>
                      <div className="text-white text-xxs">
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <div className="flex items-center justify-end sm:justify-start">
                            <Balance value={deposit.earned} decimals={4} />
                            <span className="ml-1">{pool.earningToken.symbol}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="sm:w-40 lg:mb-0">
                      <div className="text-gray-500 font-medium uppercase text-xxs text-right sm:text-left lg:hidden">
                        Time Left
                      </div>
                      <div className="text-white text-xxs sm:text-xs lg:text-xxs text-right sm:text-left">
                        {loading ? <Skeleton /> : deposit.timeLeft}
                        <div className="text-xs">&nbsp;</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pr-3 ml-auto sm:mb-0 mt-2 sm:mt-0 flex items-center">
                  {!deposit.isUnlocked && (
                    <div ref={setTriggerRef} className="text-sm font-semibold text-white hover:opacity-80 mr-2">
                      <InformationCircleIcon className="w-5 h-5" />
                    </div>
                  )}
                  <Button
                    // disabled={!deposit.isUnlocked}
                    variant={deposit.isUnlocked ? "gradient" : "outline"}
                    scale="xs"
                    onClick={() => (deposit.isUnlocked ? onReward(deposit.id) : onEmergencyWithdraw(deposit.id))}
                    className="w-full"
                  >
                    {deposit.isUnlocked ? "Harvest" : "Forfeit"}
                  </Button>
                  {!deposit.isUnlocked && visible && (
                    <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
                      <div {...getArrowProps({ className: "tooltip-arrow" })} />
                      {`Your earnings will be forfeit and the initial staked amount will be returned to you`}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Harvest;
