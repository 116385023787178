export interface MainLink {
  name: string;
  href: string;
  external?: boolean;
}

export interface SocialLink {
  name: string;
  href: string;
  external?: boolean;
  icon: any;
}

interface Footer {
  main: MainLink[];
  social: SocialLink[];
}

export const footer: Footer = {
  main: [
    { name: "Bonds", href: "/bonds" },
    { name: "Swap", href: "https://autoshark.finance/swap", external: true },
    { name: "Farm", href: "https://autoshark.finance/farms", external: true },
    {
      name: "Vaults",
      href: "https://autoshark.finance/vaults",
      external: true,
    },
    { name: "Ocean", href: "https://autoshark.finance/ocean", external: true },
    {
      name: "Docs",
      href: "https://docs-atlantis.autoshark.finance/",
      external: true,
    },
    { name: "Blog", href: "https://medium.com/autosharkfin", external: true },
  ],
  social: [
    {
      name: "Telegram",
      href: "https://t.me/AutoSharkFinance",
      external: true,
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="-6 -6 66 66" {...props}>
          <g stroke="none" strokeWidth="1" fillRule="evenodd">
            <g transform="translate(-518.000000, -666.000000)">
              <g transform="translate(518.000000, 666.000000)">
                <path d="M30,0 C46.5685425,0 60,13.4314575 60,30 C60,46.5685425 46.5685425,60 30,60 C13.4314575,60 0,46.5685425 0,30 C0,13.4314575 13.4314575,0 30,0 Z M42.6504,18.226075 L13.7529,29.368825 C11.780375,30.16 11.791875,31.260475 13.39335,31.750825 L20.8091,34.0654 L37.97735,23.23415 C38.787825,22.742675 39.531675,23.0069025 38.92115,23.54875 L25.011,36.101 L25.0105,36.10175 L24.5,43.75 C25.25,43.75 25.581375,43.407 26,43 L29.603,39.496 L37.1,45.03475 C38.479625,45.795975 39.47535,45.40185 39.819,43.753875 L44.74025,20.563125 C45.2441,18.543075 43.970225,17.626875 42.6504,18.226075 Z"></path>
              </g>
            </g>
          </g>
        </svg>
      ),
    },
    {
      name: "Twitter",
      href: "https://twitter.com/AutoSharkFin",
      external: true,
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: "GitHub",
      href: "https://github.com/autoshark-finance",
      external: true,
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};
