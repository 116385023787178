export interface Address {
  97?: string;
  56: string;
}
export interface Token {
  symbol: string;
  projectLink?: string;
  address: Address;
  decimals: number;
}

export enum StakingPlan {
  FIVE = "0",
  FIFTEEN = "1",
  THIRTY = "2",
  SIXTY = "3",
  NINETY = "4",
}

export enum PoolCategory {
  "COMMUNITY" = "Community",
  "CORE" = "Core",
  "BINANCE" = "Binance", // Pools using native BNB behave differently than pools using a token
  "AUTO" = "Auto",
}

export interface PoolConfig {
  sousId: number;
  earningToken: Token;
  stakingToken: Token;
  contractAddress: Address;
  poolCategory: PoolCategory;
  tokenPerBlock: string;
  sortOrder?: number;
  harvest?: boolean;
  isFinished?: boolean;
  hasEnd?: boolean;
  hideAPR?: boolean;
  manualAPR?: boolean;
  depositFees?: boolean;
  enableEmergencyWithdraw?: boolean;
  isSmartChef?: boolean;
  disableRewards?: boolean;
  earningTokenPriceApi?: string;
  stakingTokenPriceApi?: string;
  forcePrice?: number;
}

export interface Pool extends PoolConfig {
  allowance: string;
  stakedBalance: string;
  walletBalance: string;
  pendingRewards: string;
  totalStaked: string;
  apr: number;
  stakingTokenPrice: string;
  earningTokenPrice: string;
  startBlock: number;
  endBlock: number;
}

export interface FixedDepositConfig {
  sousId: number;
  earningToken: Token;
  stakingToken: Token;
  contractAddress: Address;
  poolCategory: PoolCategory;
  sortOrder?: number;
  dailyApr: number;
  apy?: number;
  stakePeriod: number;
  harvest?: boolean;
  isFinished?: boolean;
  hasEnd?: boolean;
  hideAPR?: boolean;
  manualAPR?: boolean;
  depositFees?: boolean;
  enableEmergencyWithdraw?: boolean;
  isSmartChef?: boolean;
  disableRewards?: boolean;
  earningTokenPriceApi?: string;
  stakingTokenPriceApi?: string;
  forcePrice?: number;
  stakingPlan: StakingPlan;
  withdrawOnly?: boolean;
}

export interface ActiveDeposit {
  id: number;
  amount: string;
  depositedAt: string;
}

export interface FixedDeposit extends FixedDepositConfig {
  allowance: string;
  stakedBalance: string;
  walletBalance: string;
  pendingRewards: string;
  totalStaked: string;
  apr: number;
  stakingTokenPrice: string;
  earningTokenPrice: string;
  startBlock: number;
  endBlock: number;
  userInfo: ActiveDeposit[];
}

// export declare enum ChainId {
//   MAINNET = 56,
//   TESTNET = 97
// }

// /**
//  * A currency is any fungible financial instrument on Ethereum, including Ether and all ERC20 tokens.
//  *
//  * The only instance of the base class `Currency` is Ether.
//  */
//  export declare class Currency {
//   readonly decimals: number;
//   readonly symbol?: string;
//   readonly name?: string;
//   /**
//    * The only instance of the base class `Currency`.
//    */
//   static readonly ETHER: Currency;
//   /**
//    * Constructs an instance of the base class `Currency`. The only instance of the base class `Currency` is `Currency.ETHER`.
//    * @param decimals decimals of the currency
//    * @param symbol symbol of the currency
//    * @param name of the currency
//    */
//   protected constructor(decimals: number, symbol?: string, name?: string);
// }
// declare const ETHER: Currency;
// export { ETHER };

// /**
//  * Represents an ERC20 token with a unique address and some metadata.
//  */
//  export declare class Token extends Currency {
//   readonly chainId: ChainId;
//   readonly address: string;
//   readonly projectLink?: string;
//   constructor(chainId: ChainId, address: string, decimals: number, symbol?: string, name?: string, projectLink?: string);
//   /**
//    * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
//    * @param other other token to compare
//    */
//   equals(other: Token): boolean;
//   /**
//    * Returns true if the address of this token sorts before the address of the other token
//    * @param other other token to compare
//    * @throws if the tokens have the same address
//    * @throws if the tokens are on different chains
//    */
//   sortsBefore(other: Token): boolean;
// }
// /**
// * Compares two currencies for equality
// */
// export declare function currencyEquals(currencyA: Currency, currencyB: Currency): boolean;
// export declare const WETH: {
//   56: Token;
//   97: Token;
// };
