import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Grid, Typography, Box, Zoom } from "@material-ui/core";
import { trim } from "../../helpers";
// import "./voting.scss";
import { Skeleton } from "@material-ui/lab";
import { useWeb3Context } from "src/hooks/web3Context";
import { getDividendPool } from "src/slices/StakingSlice";
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import {
  CursorClickIcon,
  MailOpenIcon,
  UsersIcon,
  CurrencyDollarIcon,
  LockClosedIcon,
  ArrowCircleDownIcon,
  CashIcon,
  TruckIcon,
  GlobeIcon,
  TrendingUpIcon,
  ShoppingCartIcon,
  ArrowRightIcon,
  ClockIcon,
  LibraryIcon,
  ChatIcon,
} from "@heroicons/react/outline";
import Title from "./Title";
import { useBonds } from "src/hooks";
import { useMemo } from "react";

function Voting() {
  const rules = [
    {
      name: "Buyback Votes",
      description: `As a part of the Atlantis tokenomics, the intrinsic value of the token is $1. The community can call for a vote to buyback and burn tokens when market value of the token falls below intrinsic value. That said, the community can also propose buybacks before token price dips below intrinsic value, but all pros and cons are to be strictly outlined by the proposer.
      Note: Launchpad value of $10 is not the same as Intrinsic value ($1).
      `,
    },
    {
      name: "Vaulting Strategy",
      description: `Users can call to vote on the usage of a more profitable, and more secure vaulting strategy for the treasury to grow its returns.`,
    },
    {
      name: "Rules",
      description: `Discuss your ideas by starting a discussion on Telegram, or with core team members prior to posting a proposal. Also, engage the community by answering their replies and addressing their concerns`,
    },
  ];

  return (
    <div className="flex bg-gradient-to-br from-gray-900 via-gray-700 to-gray-900 text-white">
      <div className="w-full max-w-screen-md mx-auto px-4 sm:px-6 md:px-8 my-12">
        <Title />

        <div className="bg-gray-900 rounded-md p-5 mb-3">
          <dt>
            <div className="absolute bg-gradient-to-tr from-blue-700 to-purple-900 rounded-md p-3">
              <LibraryIcon className="h-6 w-6 sm:h-10 sm:w-10" aria-hidden="true" />
            </div>
            <p className="xs:pt-2 sm:pt-0 ml-16 sm:ml-20 text-lg sm:text-2xl font-semibold text-white mb-1">
              The Atlantis Governance System
            </p>
          </dt>
          <dd className="mt-5 sm:mt-0 ml-0 sm:ml-16 sm:ml-20 flex items-baseline">
            <p className="text-md sm:text-lg text-gray-300">
              Power in the hands of the people - Create a community proposal for important issues. The Atlantis Protocol
              is but a young one, and hence seeks to the community for improvement proposals on a few core items. Check
              out the rules of engagement below. At all times, do note that proposals that are out of scope are to be
              considered to serve as a form of guideline, and may or may not result in implementation by the team.
            </p>
          </dd>
          <div className="flex justify-end">
            <a
              href="https://snapshot.org/#/autosharkdao.eth/"
              target="_blank"
              rel="noreferrer"
              className="flex items-center mt-4 text-primary hover:opacity-80 cursor-pointer"
            >
              <div className="mr-1">Propose or Vote</div>
              <ArrowRightIcon className="h-4 w-4" aria-hidden="true" />
            </a>
          </div>
        </div>

        <div className="bg-gray-900 rounded-md p-5 mb-3">
          <div className="flex items-center mb-5">
            <div className="bg-gradient-to-tr from-blue-700 to-purple-900 rounded-md p-3 mr-4">
              <ChatIcon className="h-6 w-6 sm:h-10 sm:w-10" aria-hidden="true" />
            </div>
            <p className="text-lg sm:text-3xl font-semibold text-white mb-1">Rules of Engagement</p>
          </div>
          <p className="mb-2">
            By and large, the Atlantis protocol will focus on the following key items for proposals. All proposals are
            to be strongly substantiated by pros and cons, and should also already have been discussed with the
            community prior. When proposing, do use the following template.
            <ul>
              <li classname="pt-2 pb-2">
                <b>Summary: </b>A simple description of the proposal's end result and desired change; should be no more
                than a few succinct sentences.
              </li>
              <li classname="pt-2 pb-2">
                <b>Motivation: </b>
                Explain why this proposal is necessary or useful for the protocol. The author is encouraged to add
                visual elements such as charts to support their arguments.
              </li>
              <li classname="pt-2 pb-2">
                <b>Proposal: </b>
                Lay out your proposal - explain how it is going to tackle the issue at hand and present the action
                items.
              </li>
              <li classname="pt-2 pb-2">
                <b>Polling Period: </b>
                The polling process begins now and will end at HH:MM UTC on DD/MM/YYYY. After this, a Scattershot vote
                will be put up at HH:MM UTC on DD/MM/YYYY.
              </li>
              <li classname="pt-2 pb-2">
                <b>Poll: </b>
                For: Action taken if this proposal is accepted. Against: Action taken if this proposal is rejected.
              </li>
            </ul>
          </p>
          {rules.map((item, index) => {
            return (
              <div key={item.name + index} className={index + 1 === rules.length ? "" : "mb-4"}>
                <div className="font-medium sm:text-lg">
                  {index + 1}. {item.name}
                </div>
                <div className="text-gray-400">{item.description}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Voting;
