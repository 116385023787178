import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import classNames from "src/utils/classnames";

const FaqMultiplBonds = () => {
  return (
    <div className="rounded-lg border border-gray-600 bg-indigo-200 max-w-3xl lg:max-w-4xl mx-auto p-5 mb-3">
      <div className="flex items-center mb-3">
        <ExclamationCircleIcon className="h-12 w-12 text-indigo-800 mr-2" />
        <div>
          <div className="text-black text-lg text-indigo-800 font-bold">Buying Multiple Bonds</div>
          <div className="text-indigo-800 font-medium">
            Purchasing a new bond will reset the vested period of all existing bonds,{" "}
            <span className="font-semibold">regardless of the bonded asset</span>.
          </div>
        </div>
      </div>

      {/* <Disclosure as="div" className="">
        {({ open }) => (
          <>
            <dt className="text-base">
              <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-700">
                <span className="font-semibold text-gray-800">Detailed Example</span>
                <span className="ml-6 h-7 flex items-center">
                  <ChevronDownIcon
                    className={classNames(open ? "-rotate-180" : "rotate-0", "h-6 w-6 transform text-gray-800")}
                    aria-hidden="true"
                  />
                </span>
              </Disclosure.Button>
            </dt>
            <Disclosure.Panel as="dd" className="mt-2 pr-12">
              <div className="text-md text-gray-800 font-medium">
                <div className="mb-2">
                  1. You create your 1st bond (FINS bond), for 100 ATLAS, and you get 100 sATLAS (which is auto staked,
                  to at least the 5 day plan). The ATLAS is placed under a five day lock.
                </div>
                <div className="mb-2">
                  2. 2 days later, you create a second bond (JAWS bond) for 100 ATLAS, and you get 100 sATLAS (which is
                  auto staked). Your total amount of 200 ATLAS will be locked for 5 days.
                </div>
                <div>
                  3. Your 1st fixed deposit expires in 3 days. You are able to take out 100 sATLAS. But you wouldn't be
                  able to withdraw your ATLAS as that's still placed under 5 day lock.
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure> */}
    </div>
  );
};

export default FaqMultiplBonds;
