import { useState } from "react";
// import Button from "src/components/Button";
import BigNumber from "bignumber.js";
import DepositTokenModal from "src/components/DepositTokenModal";
import WithdrawTokenModal from "src/components/WithdrawTokenModal";
import GetTokenModal from "src/components/GetTokenModal";
import { BIG_ZERO } from "src/utils/bigNumber";
import Harvest from "./Harvest";
import Stake from "./Stake";
import { useStakePool, useUnstakePool, useApprovePool } from "../../hooks/useStakePool";
import { useERC20 } from "src/hooks/useContract";
import { Pool } from "src/constants/types";

interface Props {
  pool: Pool;
  loading: boolean;
  currentBlock: number;
  earned: BigNumber;
}

function ActionPanel(props: Props) {
  const { pool, loading, currentBlock, earned } = props;

  const [depositQuantity, setDepositQuantity] = useState("");
  const [withdrawQuantity, setWithdrawQuantity] = useState("");

  const [depositModalVisible, setDepositModalVisible] = useState(false);
  const [withdrawModalVisible, setWithdrawModalVisible] = useState(false);
  const [getTokenModalVisible, setGetTokenModalVisible] = useState(false);

  const stakingTokenContract = useERC20(pool.stakingToken.address?.[56] ?? "");

  const { onStake } = useStakePool(pool.contractAddress?.[56]);
  const { onUnstake } = useUnstakePool(pool.contractAddress?.[56]);
  const { handleApprove, requestedApproval } = useApprovePool(
    stakingTokenContract,
    pool.contractAddress?.[56],
    pool.earningToken.symbol,
  );
  const { onUnstake: onEmergencyUnstake } = useUnstakePool(pool.contractAddress?.[56], true);

  const walletBalance = parseFloat(pool.walletBalance) ? new BigNumber(pool.walletBalance).div(1e9) : BIG_ZERO;
  const stakedBalance = parseFloat(pool.stakedBalance) ? new BigNumber(pool.stakedBalance).div(1e9) : BIG_ZERO;

  return (
    <>
      <div className="sm:w-1/2 h-28 bg-gray-900 rounded-lg mb-2 sm:mb-0 sm:mr-2 p-4 flex items-center justify-between">
        <Harvest pool={pool} loading={loading} currentBlock={currentBlock} earned={earned} />
      </div>
      <div className="sm:w-1/2 h-28 bg-gray-900 rounded-lg sm:ml-2 p-4 flex items-center">
        <Stake
          pool={pool}
          loading={loading}
          handleApprove={() => handleApprove(currentBlock)}
          requestedApproval={requestedApproval}
          onEmergencyUnstake={(x: string) => onEmergencyUnstake(x, currentBlock)}
          setDepositModalVisible={(x: boolean) => setDepositModalVisible(x)}
          setWithdrawModalVisible={(x: boolean) => setWithdrawModalVisible(x)}
          setGetTokenModalVisible={(x: boolean) => setGetTokenModalVisible(x)}
          walletBalance={walletBalance}
          stakedBalance={stakedBalance}
        />
      </div>
      <DepositTokenModal
        visible={depositModalVisible}
        quantity={depositQuantity}
        setQuantity={x => setDepositQuantity(x)}
        maxQuantity={walletBalance.toString()}
        onClose={() => setDepositModalVisible(false)}
        token={pool.stakingToken}
        action={() => onStake(depositQuantity, currentBlock)}
      />
      <GetTokenModal
        visible={getTokenModalVisible}
        onClose={() => setGetTokenModalVisible(false)}
        token={pool.stakingToken}
      />
      <WithdrawTokenModal
        visible={withdrawModalVisible}
        quantity={withdrawQuantity}
        setQuantity={(x: string) => setWithdrawQuantity(x)}
        maxQuantity={stakedBalance.toString()}
        onClose={() => setWithdrawModalVisible(false)}
        token={pool.stakingToken}
        action={() => onUnstake(withdrawQuantity, currentBlock)}
      />
    </>
  );
}

export default ActionPanel;
