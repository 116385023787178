import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as OlympusStakingv2ABI } from "../abi/OlympusStakingv2.json";
import { abi as sOHMv2 } from "../abi/sOhmv2.json";
import { setAll, getTokenPrice, getMarketPrice } from "../helpers";
import apollo from "../lib/apolloClient.js";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";
import { IERC20, VaultAtlasToAtlas } from "src/typechain";
import { abi as ierc20ABI } from "../abi/IERC20.json";
import atlantisPoolAbi from "../abi/AtlantisPool.json";
import multicall from "src/utils/multicall";
import BigNumberJS from "bignumber.js";

const initialState = {
  loading: false,
  loadingMarketPrice: false,
};

export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
    const atlasContract = new ethers.Contract(
      addresses[networkID].ATLAS_ADDRESS as string,
      ierc20ABI,
      provider,
    ) as IERC20;
    const singleContract = new ethers.Contract(
      addresses[networkID].ATLANTIS_POOL,
      atlantisPoolAbi,
      provider,
    ) as VaultAtlasToAtlas;
    // const _totalSupply = await atlasContract.totalSupply();
    // const _burntSupply = await atlasContract.balanceOf("0x000000000000000000000000000000000000dEaD");
    // const _lpSupply = await atlasContract.balanceOf(addresses[networkID].ATLAS_BUSD_ADDRESS);
    // const _treasurySupply = await atlasContract.balanceOf(addresses[networkID].TREASURY_ADDRESS);
    const _poolSupply = await singleContract.totalSupply();

    // total LP in dividend pool + total LP in Treasury
    // /
    // total LP
    const calls = [
      {
        address: addresses[networkID].ATLAS_ADDRESS,
        name: "totalSupply",
        params: [],
      },
      {
        address: addresses[networkID].ATLAS_ADDRESS,
        name: "balanceOf",
        params: ["0x000000000000000000000000000000000000dEaD"],
      },
      {
        address: addresses[networkID].ATLAS_ADDRESS,
        name: "balanceOf",
        params: [addresses[networkID].ATLAS_BUSD_ADDRESS],
      },
      {
        address: addresses[networkID].ATLAS_ADDRESS,
        name: "balanceOf",
        params: [addresses[networkID].TREASURY_ADDRESS],
      },
      {
        address: addresses[networkID].ATLAS_ADDRESS,
        name: "balanceOf",
        params: [addresses[networkID].VAULT_5_DAYS],
      },
      {
        address: addresses[networkID].ATLAS_ADDRESS,
        name: "balanceOf",
        params: [addresses[networkID].VAULT_15_DAYS],
      },
      {
        address: addresses[networkID].ATLAS_ADDRESS,
        name: "balanceOf",
        params: [addresses[networkID].VAULT_30_DAYS],
      },
      {
        address: addresses[networkID].ATLAS_ADDRESS,
        name: "balanceOf",
        params: [addresses[networkID].VAULT_60_DAYS],
      },
      {
        address: addresses[networkID].ATLAS_ADDRESS,
        name: "balanceOf",
        params: [addresses[networkID].VAULT_90_DAYS],
      },
      {
        address: addresses[networkID].ATLAS_BUSD_ADDRESS,
        name: "balanceOf",
        params: [addresses[networkID].ATLANTIS_POOL],
      },
      {
        address: addresses[networkID].ATLAS_BUSD_ADDRESS,
        name: "balanceOf",
        params: [addresses[networkID].TREASURY_ADDRESS],
      },
      {
        address: addresses[networkID].ATLAS_BUSD_ADDRESS,
        name: "totalSupply",
        params: [],
      },
    ];

    const [
      _totalSupply,
      _burntSupply,
      _lpSupply,
      _treasurySupply,
      _vault_5,
      _vault_15,
      _vault_30,
      _vault_60,
      _vault_90,
      _atlasBusdLPInPool,
      _atlasBusdLPInTreasury,
      _totalAtlasBusdLP,
    ] = await multicall(ierc20ABI, calls);
    // console.log(
    //   new BigNumberJS(_vault_5)
    //     .plus(_vault_15.toString())
    //     .plus(_vault_30.toString())
    //     .plus(_vault_60.toString())
    //     .plus(_vault_90.toString())
    //     .toString(),
    // );

    const protocolLiquidity =
      ((parseInt(_atlasBusdLPInPool.toString()) + parseInt(_atlasBusdLPInTreasury.toString())) /
        parseInt(_totalAtlasBusdLP.toString())) *
      100;

    // NOTE (appleseed): marketPrice from Graph was delayed, so get CoinGecko price
    // const marketPrice = parseFloat(graphData.data.protocolMetrics[0].ohmPrice);
    let marketPrice;
    try {
      const originalPromiseResult = await dispatch(
        loadMarketPrice({ networkID: networkID, provider: provider }),
      ).unwrap();
      marketPrice = originalPromiseResult?.marketPrice;
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      console.error("Returned a null response from dispatch(loadMarketPrice)");
      return;
    }
    const fixedDepositsTVL = parseFloat(
      new BigNumberJS(_vault_5)
        .plus(_vault_15.toString())
        .plus(_vault_30.toString())
        .plus(_vault_60.toString())
        .plus(_vault_90.toString())
        .div(1e9)
        .toString(),
    );
    const poolTVL = parseFloat(_poolSupply.div(1e9).toString());
    const totalTVL = poolTVL + fixedDepositsTVL;
    const stakingTVL = totalTVL * marketPrice;
    const _circSupply = new BigNumberJS(_totalSupply)
      .minus(_burntSupply)
      .minus(_lpSupply)
      .minus(_treasurySupply)
      .div(1e9);
    const circSupply = parseFloat(_circSupply.toString());
    const totalSupply = parseFloat(new BigNumberJS(_totalSupply).div(1e9).toString());
    const marketCap = parseFloat(_circSupply.toString()) * marketPrice;
    const treasuryMarketValue = parseFloat(_poolSupply.div(1e9).toString()) * marketPrice; // TODO: change treasury market value to be sum of value of all locked
    // const currentBlock = parseFloat(graphData.data._meta.block.number);

    if (!provider) {
      console.error("failed to connect to provider, please connect your wallet");
      return {
        stakingTVL,
        marketPrice,
        marketCap,
        circSupply,
        totalSupply,
        treasuryMarketValue,
      };
    }
    const currentBlock = await provider.getBlockNumber();

    // Calculating staking
    return {
      currentIndex: "0",
      currentBlock,
      fiveDayRate: 0,
      stakingAPY: 0,
      stakingTVL,
      stakingRebase: 0,
      marketCap,
      marketPrice,
      circSupply,
      totalSupply,
      treasuryMarketValue,
      protocolLiquidity,
    } as IAppData;
  },
);

/**
 * checks if app.slice has marketPrice already
 * if yes then simply load that state
 * if no then fetches via `loadMarketPrice`
 *
 * `usage`:
 * ```
 * const originalPromiseResult = await dispatch(
 *    findOrLoadMarketPrice({ networkID: networkID, provider: provider }),
 *  ).unwrap();
 * originalPromiseResult?.whateverValue;
 * ```
 */
export const findOrLoadMarketPrice = createAsyncThunk(
  "app/findOrLoadMarketPrice",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch, getState }) => {
    const state: any = getState();
    let marketPrice;
    // check if we already have loaded market price
    if (state.app.loadingMarketPrice === false && state.app.marketPrice) {
      // go get marketPrice from app.state
      marketPrice = state.app.marketPrice;
    } else {
      // we don't have marketPrice in app.state, so go get it
      try {
        const originalPromiseResult = await dispatch(
          loadMarketPrice({ networkID: networkID, provider: provider }),
        ).unwrap();
        marketPrice = originalPromiseResult?.marketPrice;
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        console.error("Returned a null response from dispatch(loadMarketPrice)");
        return;
      }
    }
    return { marketPrice };
  },
);

/**
 * - fetches the ATLAS price from CoinGecko (via getTokenPrice)
 * - falls back to fetch marketPrice from ohm-dai contract
 * - updates the App.slice when it runs
 */
const loadMarketPrice = createAsyncThunk("app/loadMarketPrice", async ({ networkID, provider }: IBaseAsyncThunk) => {
  let marketPrice: number;
  try {
    marketPrice = await getMarketPrice({ networkID, provider });
    marketPrice = marketPrice / Math.pow(10, 9);
  } catch (e) {
    marketPrice = await getTokenPrice("olympus");
  }
  return { marketPrice };
});

interface IAppData {
  readonly circSupply: number;
  readonly currentIndex?: string;
  readonly currentBlock?: number;
  readonly fiveDayRate?: number;
  readonly marketCap: number;
  readonly marketPrice: number;
  readonly stakingAPY?: number;
  readonly stakingRebase?: number;
  readonly stakingTVL: number;
  readonly totalSupply: number;
  readonly treasuryBalance?: number;
  readonly treasuryMarketValue?: number;
  readonly protocolLiquidity?: number;
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error.name, error.message, error.stack);
      })
      .addCase(loadMarketPrice.pending, (state, action) => {
        state.loadingMarketPrice = true;
      })
      .addCase(loadMarketPrice.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loadingMarketPrice = false;
      })
      .addCase(loadMarketPrice.rejected, (state, { error }) => {
        state.loadingMarketPrice = false;
        console.error(error.name, error.message, error.stack);
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
