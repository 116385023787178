import { useMemo } from "react";
import { getBep20Contract, getFixedDeposit, getSousChef } from "src/utils/contractHelpers";
import { useWeb3Context } from ".";

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useSousChef = (address: string) => {
  const { provider } = useWeb3Context();
  return useMemo(() => getSousChef(address, provider.getSigner()), [address, provider]);
};

export const useERC20 = (address: string) => {
  const { provider } = useWeb3Context();
  return useMemo(() => getBep20Contract(address, provider?.getSigner()), [address, provider]);
};

export const useFixedDeposit = (address: string) => {
  const { provider } = useWeb3Context();
  return useMemo(() => getFixedDeposit(address, provider.getSigner()), [address, provider]);
};
