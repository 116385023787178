import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { NetworkID } from "../lib/Bond";
import { abi as BondCalcContractABI } from "../abi/BondCalcContract.json";
import PriceCalcContractABI from "../abi/PriceCalculator.json";
import { ethers } from "ethers";
import { addresses } from "../constants";
import { BondCalcContract, PriceCalculatorBSC } from "../typechain";

export function getBondCalculator(networkID: NetworkID, provider: StaticJsonRpcProvider) {
  return new ethers.Contract(
    addresses[networkID].BONDINGCALC_ADDRESS as string,
    BondCalcContractABI,
    provider,
  ) as BondCalcContract;
}

export function getPriceCalculator(networkID: NetworkID, provider: StaticJsonRpcProvider) {
  return new ethers.Contract(
    addresses[networkID].PRICE_CALCULATOR as string,
    PriceCalcContractABI,
    provider,
  ) as PriceCalculatorBSC;
}
