import { Fragment } from "react";

// 3rd party libraries

import { Menu, Transition } from "@headlessui/react";
import { DotsHorizontalIcon } from "@heroicons/react/solid";

// own app
import { moreNav } from "../../constants/navigation";

const More: React.FC = () => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex justify-center items-center w-full rounded-md pr-4 py-2 focus:outline-none">
        <DotsHorizontalIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-900 focus:outline-none">
          <div className="py-1">
            {moreNav.map(item => (
              <Menu.Item key={item.name}>
                <a
                  href={item.href}
                  target="_blank"
                  className="text-white hover:text-primary block px-4 py-2 text-sm"
                  rel="noreferrer"
                >
                  {item.name}
                </a>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default More;
