import { PoolCategory, FixedDepositConfig, StakingPlan } from "./types";
import tokens from "../tokens/tokens";

// NOTE: the 90 day plan MUST be on the top
export const fixedDeposits: FixedDepositConfig[] = [
  {
    sousId: 6,
    stakingToken: tokens.atlas,
    earningToken: tokens.atlas,
    contractAddress: {
      97: "",
      56: "0xCb9Fc63f7914364fA5621dF962270C9dFCcd22d5",
    },
    poolCategory: PoolCategory.COMMUNITY,
    dailyApr: 0.2,
    apy: 106.75,
    stakePeriod: 432000, // in seconds
    sortOrder: 1,
    isFinished: false,
    stakingPlan: StakingPlan.FIVE,
    withdrawOnly: true,
  },
  {
    sousId: 10,
    stakingToken: tokens.atlas,
    earningToken: tokens.atlas,
    contractAddress: {
      97: "",
      56: "0x6dFb0EE93B247C5eCe4918206024B22d2A75e716",
    },
    poolCategory: PoolCategory.COMMUNITY,
    dailyApr: 3,
    apy: 19413,
    stakePeriod: 7776000, // in seconds
    sortOrder: 1,
    isFinished: true,
    stakingPlan: StakingPlan.NINETY,
    withdrawOnly: true,
  },
  {
    sousId: 9,
    stakingToken: tokens.atlas,
    earningToken: tokens.atlas,
    contractAddress: {
      97: "",
      56: "0x61cc23b5bf56692F9fC996e5a056F61b98572b97",
    },
    poolCategory: PoolCategory.COMMUNITY,
    dailyApr: 1.5,
    apy: 4780,
    stakePeriod: 5184000, // in seconds
    sortOrder: 1,
    isFinished: true,
    stakingPlan: StakingPlan.SIXTY,
    withdrawOnly: true,
  },
  {
    sousId: 8,
    stakingToken: tokens.atlas,
    earningToken: tokens.atlas,
    contractAddress: {
      97: "",
      56: "0xF0cF1Aa429ab2078729B6B1652bAE2c0cCBD8fEf",
    },
    poolCategory: PoolCategory.COMMUNITY,
    dailyApr: 0.7,
    apy: 913.9,
    stakePeriod: 2592000, // in seconds
    sortOrder: 1,
    isFinished: true,
    stakingPlan: StakingPlan.THIRTY,
    withdrawOnly: true,
  },
  {
    sousId: 7,
    stakingToken: tokens.atlas,
    earningToken: tokens.atlas,
    contractAddress: {
      97: "",
      56: "0x21458ADa59D97d6782052C958FF76637e6786F3b",
    },
    poolCategory: PoolCategory.COMMUNITY,
    dailyApr: 0.5,
    apy: 480.6,
    stakePeriod: 1296000, // in seconds
    sortOrder: 1,
    isFinished: true,
    stakingPlan: StakingPlan.FIFTEEN,
    withdrawOnly: true,
  },
  {
    sousId: 5,
    stakingToken: tokens.satlas,
    earningToken: tokens.atlas,
    contractAddress: {
      97: "",
      56: "0x7C68C634aa4171C5f19e24ED0D9409E0B0e999Bc",
    },
    poolCategory: PoolCategory.COMMUNITY,
    dailyApr: 3,
    apy: 19413,
    stakePeriod: 7776000, // in seconds
    sortOrder: 1,
    isFinished: true,
    stakingPlan: StakingPlan.NINETY,
    withdrawOnly: true,
  },
  {
    sousId: 4,
    stakingToken: tokens.satlas,
    earningToken: tokens.atlas,
    contractAddress: {
      97: "",
      56: "0xc3dC0984d120927E4c69F254f12f85613FCA9df8",
    },
    poolCategory: PoolCategory.COMMUNITY,
    dailyApr: 1.5,
    apy: 4780,
    stakePeriod: 5184000, // in seconds
    sortOrder: 1,
    isFinished: true,
    stakingPlan: StakingPlan.SIXTY,
    withdrawOnly: true,
  },
  {
    sousId: 3,
    stakingToken: tokens.satlas,
    earningToken: tokens.atlas,
    contractAddress: {
      97: "",
      56: "0x5110078595070FEeb8B6388960a398d3134fF243",
    },
    poolCategory: PoolCategory.COMMUNITY,
    dailyApr: 0.7,
    apy: 913.9,
    stakePeriod: 2592000, // in seconds
    sortOrder: 1,
    isFinished: true,
    stakingPlan: StakingPlan.THIRTY,
    withdrawOnly: true,
  },
  {
    sousId: 2,
    stakingToken: tokens.satlas,
    earningToken: tokens.atlas,
    contractAddress: {
      97: "",
      56: "0x3375C09ce9d02D1B0F5A525819CF29b4C21e284F",
    },
    poolCategory: PoolCategory.COMMUNITY,
    dailyApr: 0.5,
    apy: 480.6,
    stakePeriod: 1296000, // in seconds
    sortOrder: 1,
    isFinished: true,
    stakingPlan: StakingPlan.FIFTEEN,
    withdrawOnly: true,
  },
  {
    sousId: 1,
    stakingToken: tokens.satlas,
    earningToken: tokens.atlas,
    contractAddress: {
      97: "",
      56: "0x857364d9D049Cd1B83090E36348F75726be1AC8A",
    },
    poolCategory: PoolCategory.COMMUNITY,
    dailyApr: 0.2,
    apy: 106.75,
    stakePeriod: 432000, // in seconds
    sortOrder: 1,
    isFinished: true,
    stakingPlan: StakingPlan.FIVE,
    withdrawOnly: true,
  },
];
