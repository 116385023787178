import { ClaimBondTableData, ClaimBondCardData } from "./ClaimRow";
// import { isPendingTxn } from "src/slices/PendingTxnsSlice";
// import { redeemAllBonds } from "src/slices/BondSlice";
// import { useWeb3Context } from "src/hooks/web3Context";
// import useBonds from "src/hooks/Bonds";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import "./choosebond.scss";
// import { useSelector, useDispatch } from "react-redux";

function ClaimBonds({ activeBonds, numberOfBonds }) {
  // const dispatch = useDispatch();
  // const { provider, address, chainID } = useWeb3Context();
  // const { bonds } = useBonds(chainID);

  const isSmallScreen = useMediaQuery("(max-width: 768px)"); // change to breakpoint query

  // const pendingTransactions = useSelector(state => {
  //   return state.pendingTransactions;
  // });

  // const pendingClaim = () => {
  //   if (
  //     isPendingTxn(pendingTransactions, "redeem_all_bonds") ||
  //     isPendingTxn(pendingTransactions, "redeem_all_bonds_autostake")
  //   ) {
  //     return true;
  //   }

  //   return false;
  // };

  // const onRedeemAll = async ({ autostake }) => {
  //   console.log("redeeming all bonds");

  //   await dispatch(redeemAllBonds({ address, bonds, networkID: chainID, provider, autostake }));

  //   console.log("redeem all complete");
  // };

  return (
    <div className="rounded-lg border border-gray-600 bg-black max-w-3xl lg:max-w-4xl mx-auto p-5 mb-3">
      {numberOfBonds > 0 && (
        <div>
          <div className="flex items-center justify-between">
            <div className="text-primary font-semibold text-xl mb-3">Your Bonds</div>
            <div className="text-gray-500 font-semibold text-sm mb-3">{`${activeBonds.length} Active Bond${
              activeBonds.length > 1 ? "s" : ""
            }`}</div>
          </div>
          <div>
            {!isSmallScreen && (
              <div>
                {Object.entries(activeBonds).map((bond, i) => {
                  const hasSeparator = activeBonds.length - i > 1;
                  return <ClaimBondTableData key={i} userBond={bond} hasSeparator={hasSeparator} />;
                })}
              </div>
            )}

            {isSmallScreen &&
              Object.entries(activeBonds).map((bond, i) => {
                const hasSeparator = activeBonds.length - i > 1;
                return <ClaimBondCardData key={i} userBond={bond} hasSeparator={hasSeparator} />;
              })}

            {/* <div
              display="flex"
              justifyContent="center"
              className={`global-claim-buttons ${isSmallScreen ? "small" : ""}`}
            >
              {numberOfBonds > 1 && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    className="transaction-button"
                    fullWidth
                    disabled={pendingClaim()}
                    onClick={() => {
                      onRedeemAll({ autostake: false });
                    }}
                  >
                    {txnButtonTextGeneralPending(pendingTransactions, "redeem_all_bonds", "Claim all")}
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    id="claim-all-and-stake-btn"
                    className="transaction-button"
                    fullWidth
                    disabled={pendingClaim()}
                    onClick={() => {
                      onRedeemAll({ autostake: true });
                    }}
                  >
                    {txnButtonTextGeneralPending(
                      pendingTransactions,
                      "redeem_all_bonds_autostake",
                      "Claim all and Stake",
                    )}
                  </Button>
                </>
              )}
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default ClaimBonds;
