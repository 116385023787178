const Title = () => {
  return (
    <>
      <div className="text-white text-3xl font-bold text-center">Voting</div>
      <div className="text-gray-400 text-md mb-10 text-center">Play a part in Governance</div>
    </>
  );
};

export default Title;
