import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20ABI } from "../abi/IERC20.json";
import { abi as OlympusStakingABI } from "../abi/OlympusStakingv2.json";
import GenericVaultABI from "../abi/GenericVault.json";
import { abi as StakingHelperABI } from "../abi/StakingHelper.json";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText, getClaimingTypeText } from "./PendingTxnsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountSuccess, getBalances } from "./AccountSlice";
import { error, info } from "../slices/MessagesSlice";
import { IActionValueAsyncThunk, IChangeApprovalAsyncThunk, IJsonRPCError } from "./interfaces";
import { segmentUA } from "../helpers/userAnalyticHelpers";
import { IERC20, OlympusStakingv2, StakingHelper, VaultAtlasToAtlas } from "src/typechain";
import { BLACKHOLE } from "src/constants/solidity";

interface IUAData {
  address: string;
  value: string;
  approved: boolean;
  txHash: string | null;
  type: string | null;
}

function alreadyApprovedToken(token: string, stakeAllowance: BigNumber, unstakeAllowance: BigNumber) {
  // set defaults
  let bigZero = BigNumber.from("0");
  let applicableAllowance = bigZero;

  // determine which allowance to check
  if (token === "ohm") {
    applicableAllowance = stakeAllowance;
  } else if (token === "sohm") {
    applicableAllowance = unstakeAllowance;
  }

  // check if allowance exists
  if (applicableAllowance.gt(bigZero)) return true;

  return false;
}

export const changeApproval = createAsyncThunk(
  "stake/changeApproval",
  async ({ token, provider, address, networkID }: IChangeApprovalAsyncThunk, { dispatch }) => {
    console.log("approving");
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const atlasContract = new ethers.Contract(
      addresses[networkID].ATLAS_ADDRESS as string,
      ierc20ABI,
      signer,
    ) as IERC20;
    const satlasContract = new ethers.Contract(
      addresses[networkID].S_ATLAS_ADDRESS as string,
      ierc20ABI,
      signer,
    ) as IERC20;
    let approveTx;
    let stakeAllowance = await atlasContract.allowance(address, addresses[networkID].SINGLE_ATLAS_POOL);
    let unstakeAllowance = await satlasContract.allowance(address, addresses[networkID].SINGLE_ATLAS_POOL);

    // return early if approval has already happened
    if (alreadyApprovedToken(token, stakeAllowance, unstakeAllowance)) {
      dispatch(info("Approval completed."));
      return dispatch(
        fetchAccountSuccess({
          staking: {
            ohmStake: +stakeAllowance,
            ohmUnstake: +unstakeAllowance,
          },
        }),
      );
    }
    // console.log(token);
    try {
      if (token === "ohm") {
        // won't run if stakeAllowance > 0
        approveTx = await atlasContract.approve(addresses[networkID].SINGLE_ATLAS_POOL, ethers.constants.MaxUint256);
      } else if (token === "sohm") {
        console.log(addresses[networkID].S_ATLAS_ADDRESS);
        console.log(addresses[networkID].SINGLE_ATLAS_POOL);
        approveTx = await satlasContract.approve(addresses[networkID].SINGLE_ATLAS_POOL, ethers.constants.MaxUint256);
      }

      const text = "Approve " + (token === "ohm" ? "Staking" : "Unstaking");
      const pendingTxnType = token === "ohm" ? "approve_staking" : "approve_unstaking";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));

        await approveTx.wait();
      }
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }

    // go get fresh allowances
    stakeAllowance = await atlasContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);
    unstakeAllowance = await satlasContract.allowance(address, addresses[networkID].STAKING_ADDRESS);

    return dispatch(
      fetchAccountSuccess({
        staking: {
          ohmStake: +stakeAllowance,
          ohmUnstake: +unstakeAllowance,
        },
      }),
    );
  },
);

export const changeStake = createAsyncThunk(
  "stake/changeStake",
  async ({ action, value, provider, address, networkID }: IActionValueAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const staking = new ethers.Contract(
      addresses[networkID].SINGLE_ATLAS_POOL as string,
      GenericVaultABI,
      signer,
    ) as VaultAtlasToAtlas;

    let stakeTx;
    let uaData: IUAData = {
      address: address,
      value: value,
      approved: true,
      txHash: null,
      type: null,
    };
    try {
      if (action === "stake") {
        uaData.type = "stake";
        stakeTx = await staking.deposit(ethers.utils.parseUnits(value, "gwei"), BLACKHOLE);
      } else {
        uaData.type = "unstake";
        stakeTx = await staking.withdrawUnderlying(ethers.utils.parseUnits(value, "gwei"));
      }
      const pendingTxnType = action === "stake" ? "staking" : "unstaking";
      uaData.txHash = stakeTx.hash;
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: getStakingTypeText(action), type: pendingTxnType }));
      await stakeTx.wait();
    } catch (e: unknown) {
      uaData.approved = false;
      const rpcError = e as IJsonRPCError;
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else {
        dispatch(error(rpcError.message));
      }
      return;
    } finally {
      if (stakeTx) {
        segmentUA(uaData);

        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
    dispatch(getBalances({ address, networkID, provider }));
  },
);

export const claim = createAsyncThunk(
  "stake/claim",
  async ({ action, provider, address, networkID }: IActionValueAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const staking = new ethers.Contract(
      addresses[networkID].SINGLE_ATLAS_POOL as string,
      GenericVaultABI,
      signer,
    ) as VaultAtlasToAtlas;

    let claimTx;
    let uaData: IUAData = {
      address: address,
      value: "",
      approved: true,
      txHash: null,
      type: null,
    };
    try {
      if (action === "claim") {
        console.log("claim only");
        uaData.type = "claim";
        claimTx = await staking.getReward();
      } else {
        console.log("claim_and_unstake");
        uaData.type = "claim and unstake";
        claimTx = await staking.withdrawAll();
      }
      const pendingTxnType = action === "claim" ? "claim" : "claim_and_unstake";
      uaData.txHash = claimTx.hash;
      dispatch(fetchPendingTxns({ txnHash: claimTx.hash, text: getClaimingTypeText(action), type: pendingTxnType }));
      await claimTx.wait();
    } catch (e: unknown) {
      const rpcError = e as IJsonRPCError;
      if (rpcError.code === -32603 && rpcError.data?.message === "execution reverted: not unlocked") {
        dispatch(error("A portion of your Staked ATLAS Balance is not yet unlocked from bonding"));
      } else {
        dispatch(error(rpcError.message));
      }
      return;
    } finally {
      if (claimTx) {
        segmentUA(uaData);
        dispatch(clearPendingTxn(claimTx.hash));
      }
    }
    dispatch(getBalances({ address, networkID, provider }));
  },
);
