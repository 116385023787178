import { ethers, BigNumber } from "ethers";
import BigNumberJS from "bignumber.js";
import { contractForRedeemHelper, getMarketPrice, getTokenPrice } from "../helpers";
import { getBalances, calculateUserBondDetails } from "./AccountSlice";
// import { findOrLoadMarketPrice } from "./AppSlice";
import { error, info } from "./MessagesSlice";
import { clearPendingTxn, fetchPendingTxns } from "./PendingTxnsSlice";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
// import { getBondCalculator } from "src/helpers/BondCalculator";
import { RootState } from "src/store";
import { AtlantisPool, ExpandedIERC20, VaultAtlasToAtlas } from "src/typechain";
import atlantisPoolAbi from "src/abi/AtlantisPool.json";
// import routerAbi from "src/abi/Router.json";
import erc20Abi from "src/abi/erc20.json";
import jawsMinterAbi from "src/abi/JawsMinter.json";
import dashboardAbi from "src/abi/DashboardBSC.json";
// import calculatorAbi from "src/abi/PriceCalculator.json";
import vaultAbi from "src/abi/GenericVault.json";
import {
  IApproveBondAsyncThunk,
  // IBondAssetAsyncThunk,
  ICalcBondDetailsAsyncThunk,
  IJsonRPCError,
  IRedeemAllBondsAsyncThunk,
  IRedeemBondAsyncThunk,
} from "./interfaces";
import { segmentUA } from "../helpers/userAnalyticHelpers";
import { addresses } from "src/constants";
import { NetworkID } from "src/lib/Bond";
import { aprToApy } from "src/helpers/apr";
import { DashboardBSC } from "src/typechain/DashboardBSC";
// import { StakingPlan } from "src/constants/types";

export const changeApproval = createAsyncThunk(
  "staking/changeApproval",
  async ({ address, bond, provider, networkID }: IApproveBondAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const reserveContract = bond.getContractForReserve(networkID, signer);
    const bondAddr = bond.getAddressForBond(networkID);

    let approveTx;
    let bondAllowance = await reserveContract.allowance(address, bondAddr);

    // return early if approval already exists
    if (bondAllowance.gt(BigNumber.from("0"))) {
      dispatch(info("Approval completed."));
      dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));
      return;
    }

    try {
      approveTx = await reserveContract.approve(bondAddr, ethers.constants.MaxUint256);
      dispatch(
        fetchPendingTxns({
          txnHash: approveTx.hash,
          text: "Approving " + bond.displayName,
          type: "approve_" + bond.name,
        }),
      );
      await approveTx.wait();
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
        dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));
      }
    }
  },
);

export interface IStakeDetails {
  dividendsTotalSupply: string;
  dividendsRewardRate: string;
  dividendsDailyROI: number;
  singleTotalSupply: string;
  singleDailyROI: number;
  dividendsTVL: string;
  singleTVL: string;
  dividendsAPY: number;
  singleAPY: number;
  dividendsStaked: string;
  singleStaked: string;
  currentBlock: number;
  singleLockedTill: string;
  profits: [string, string];
}
export const getDividendPool = createAsyncThunk(
  "staking/getDividendPool",
  async ({ provider, account }: ICalcBondDetailsAsyncThunk, { dispatch }): Promise<IStakeDetails> => {
    const contract = new ethers.Contract(
      addresses[NetworkID.Mainnet].ATLANTIS_POOL,
      atlantisPoolAbi,
      provider,
    ) as AtlantisPool;
    const singleContract = new ethers.Contract(
      addresses[NetworkID.Mainnet].SINGLE_ATLAS_POOL,
      vaultAbi,
      provider,
    ) as VaultAtlasToAtlas;
    const dashboardContract = new ethers.Contract(
      addresses[NetworkID.Mainnet].DASHBOARD_BSC,
      dashboardAbi,
      provider,
    ) as DashboardBSC;
    const minterContract = new ethers.Contract("0x342afF01fe4781FC15eE6977C20cC55Ad8da3121", jawsMinterAbi, provider);

    const rewardRate = await contract.rewardRate();
    const totalSupply = await contract.totalSupply();
    let dividendsStaked;
    let accountInfo;

    if (account) {
      dividendsStaked = await contract.principalOf(account);
      accountInfo = await dashboardContract.infoOfPool(addresses[NetworkID.Mainnet].SINGLE_ATLAS_POOL, account);
    }
    const jawsPerProfitBNB = await minterContract.jawsPerProfitBNB();
    const bnbPrice = await getTokenPrice("binancecoin");
    const jawsPrice = await getTokenPrice("autoshark");
    const marketPrice = await getMarketPrice({ networkID: NetworkID.Mainnet, provider });
    const currentBlock = await provider.getBlockNumber();

    const farmTokenPrice = marketPrice / 1e9;
    let lpTokenPrice = 2e9; // assume price is 2 dollars
    try {
      const busdContract = new ethers.Contract(
        "0xe9e7cea3dedca5984780bafc599bd69add087d56",
        erc20Abi,
        provider,
      ) as ExpandedIERC20;
      const lpContract = new ethers.Contract(
        "0x8eC2dCc0B88ef879C885B0b31e87aBa14543a8cd",
        erc20Abi,
        provider,
      ) as ExpandedIERC20;
      const amountBUSD = await busdContract.balanceOf("0x8eC2dCc0B88ef879C885B0b31e87aBa14543a8cd");
      const lpValue = amountBUSD.mul(2);
      const lpSupply = await lpContract.totalSupply();
      lpTokenPrice = lpValue.div(lpSupply).toNumber();
      // console.log("lpTokenPrice", lpTokenPrice.toString());
    } catch (e) {
      console.log("error fetching lp price");
    }
    const singleTotalSupply = await singleContract.totalSupply();
    let singleLockedTill = "0";
    try {
      const _singleLockedTill = await singleContract._unlockedAt(account ?? "");
      singleLockedTill = _singleLockedTill?.toString();
    } catch (e) {
      // console.log("error fetching singleLockedTill");
    }
    const dividendsTVL = totalSupply.mul(Math.round(marketPrice)).div((1e18).toString());
    const singleTVL = singleTotalSupply.mul(Math.round(marketPrice)).div((1e18).toString());
    // console.log("lpTokenPrice", lpTokenPrice.toString());
    const dailyRewards = new BigNumberJS(rewardRate.toString()).times(86400).div(1e9).times(lpTokenPrice.toString());
    // default total supply staked is 1k tokens
    const totalPrice = new BigNumberJS(
      totalSupply.toString() === "0" ? "1000000000000000000000" : totalSupply.toString(),
    ).times(farmTokenPrice.toString());
    const actualDailyROI = dailyRewards.div(totalPrice).times(100);
    const dividendsAPY = aprToApy(actualDailyROI.times(365).toNumber());

    const actualJawsRewardsApr = new BigNumberJS(actualDailyROI)
      .times(30 * 2)
      .div(100)
      .div(bnbPrice)
      .times(new BigNumberJS(jawsPerProfitBNB.toString()).div(new BigNumberJS(10).pow(18))?.toString())
      .times(jawsPrice);
    const singleAPY = aprToApy(actualDailyROI.times(7).div(10).plus(actualJawsRewardsApr).times(365).toNumber());
    return {
      dividendsRewardRate: rewardRate.toString(),
      dividendsTotalSupply: totalSupply.toString(),
      dividendsDailyROI: actualDailyROI.toNumber(),
      singleTotalSupply: singleTotalSupply.toString(),
      dividendsTVL: dividendsTVL.toString(),
      singleTVL: singleTVL.toString(),
      dividendsAPY: dividendsAPY,
      singleAPY: singleAPY,
      currentBlock,
      singleLockedTill: singleLockedTill,
      singleDailyROI: actualDailyROI.times(105).div(100).toNumber(),
      dividendsStaked: dividendsStaked?.toString() ?? "0",
      singleStaked: accountInfo?.principal?.toString() ?? "0",
      profits: [accountInfo?.pBASE?.toString() ?? "0", accountInfo?.pJAWS?.toString() ?? "0"],
    };
  },
);

export const redeemStake = createAsyncThunk(
  "staking/redeemStake",
  async ({ address, bond, networkID, provider, autostake, stakingPlan }: IRedeemBondAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const bondContract = bond.getContractForBond(networkID, signer);

    let redeemTx;
    let uaData = {
      address: address,
      type: "Redeem",
      bondName: bond.displayName,
      autoStake: autostake,
      approved: true,
      txHash: "",
    };
    try {
      // TODO: change , StakingPlan.FIVE
      redeemTx = await bondContract.redeem(address, autostake === true, stakingPlan);
      const pendingTxnType = "redeem_bond_" + bond + (autostake === true ? "_autostake" : "");
      uaData.txHash = redeemTx.hash;
      dispatch(
        fetchPendingTxns({ txnHash: redeemTx.hash, text: "Redeeming " + bond.displayName, type: pendingTxnType }),
      );

      await redeemTx.wait();
      await dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));

      dispatch(getBalances({ address, networkID, provider }));
    } catch (e: unknown) {
      uaData.approved = false;
      dispatch(error((e as IJsonRPCError).message));
    } finally {
      if (redeemTx) {
        segmentUA(uaData);
        dispatch(clearPendingTxn(redeemTx.hash));
      }
    }
  },
);

export const redeemAllStakes = createAsyncThunk(
  "staking/redeemAllStakes",
  async ({ bonds, address, networkID, provider, autostake }: IRedeemAllBondsAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const redeemHelperContract = contractForRedeemHelper({ networkID, provider: signer });

    let redeemAllTx;

    try {
      redeemAllTx = await redeemHelperContract.redeemAll(address, autostake);
      const pendingTxnType = "redeem_all_bonds" + (autostake === true ? "_autostake" : "");

      await dispatch(
        fetchPendingTxns({ txnHash: redeemAllTx.hash, text: "Redeeming All Stakes", type: pendingTxnType }),
      );

      await redeemAllTx.wait();

      bonds &&
        bonds.forEach(async bond => {
          dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));
        });

      dispatch(getBalances({ address, networkID, provider }));
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
    } finally {
      if (redeemAllTx) {
        dispatch(clearPendingTxn(redeemAllTx.hash));
      }
    }
  },
);

// Note(zx): this is a barebones interface for the state. Update to be more accurate
interface IStakingSlice {
  status: string;
  [key: string]: any;
}

const setStakeState = (state: IStakingSlice, payload: any) => {
  const newState = { ...state.publicData, ...payload };
  state.publicData = newState;
  state.loading = false;
};

const initialState: IStakingSlice = {
  status: "idle",
  publicData: {},
};

const stakingSlice = createSlice({
  name: "staking",
  initialState,
  reducers: {
    fetchStakeSuccess(state, action) {
      state.publicData = action.payload;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getDividendPool.pending, state => {
        state.loading = true;
      })
      .addCase(getDividendPool.fulfilled, (state, action) => {
        setStakeState(state, action.payload);
        state.status = "active";
        state.loading = false;
      })
      .addCase(getDividendPool.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error.message);
      });
  },
});

export default stakingSlice.reducer;

export const { fetchStakeSuccess } = stakingSlice.actions;

const baseInfo = (state: RootState) => state.staking;

export const getStakingState = createSelector(baseInfo, staking => staking);
