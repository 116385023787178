import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import Pair from "src/components/TokenImages/Pair";
// import Button from "src/components/Button";
import classNames from "src/utils/classnames";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
// import useRefresh from "src/hooks/useRefresh";
import Balance from "src/components/Balance";
import Skeleton from "src/components/Skeleton";
import ActionPanel from "./ActionPanel";
import { FixedDeposit } from "src/constants/types";

export const getPoolBlockInfo = (pool: FixedDeposit, currentBlock: number) => {
  const { startBlock, endBlock, isFinished } = pool;
  const shouldShowBlockCountdown = Boolean(!isFinished && startBlock && endBlock);
  const blocksUntilStart = Math.max(startBlock - currentBlock, 0);
  const blocksRemaining = Math.max(endBlock - currentBlock, 0);
  const hasPoolStarted = blocksUntilStart === 0 && blocksRemaining > 0;
  const blocksToDisplay = hasPoolStarted ? blocksRemaining : blocksUntilStart;
  return { shouldShowBlockCountdown, blocksUntilStart, blocksRemaining, hasPoolStarted, blocksToDisplay };
};

const DAY_IN_SECONDS = 86400;

interface Props {
  pool: FixedDeposit;
  loading: boolean;
  currentBlock: number;
}

function PoolRow(props: Props) {
  const { pool, loading, currentBlock } = props;
  const [toggleOpen, setToggleOpen] = useState(true);

  useEffect(() => {
    if (parseFloat(pool.stakedBalance) > 0) {
      setToggleOpen(true);
    }
  }, []);

  return (
    <Disclosure
      key={pool.sousId}
      as="div"
      className={classNames(
        "rounded-lg border border-gray-600 max-w-3xl lg:max-w-4xl mx-auto mb-3 overflow-clip overflow-hidden",
        pool.isFinished ? "bg-gray-800" : "bg-gray-900",
      )}
    >
      {() => (
        <>
          <Disclosure.Button className="text-left w-full">
            <div
              className="flex items-center text-white pt-5 px-5 sm:pb-5 z-0 relative overflow-hidden"
              onClick={toggleOpen ? () => setToggleOpen(false) : () => setToggleOpen(true)}
            >
              {pool.isFinished && (
                <div className="transform -rotate-45 bg-black opacity-85 absolute z-20 w-28 md:w-36 top-4 md:top-8 -left-6 md:-left-10 text-center">
                  Finished
                </div>
              )}
              <div className="w-20 flex-grow-0">
                <Pair ocean topToken={pool.stakingToken} bottomToken={pool.earningToken} />
              </div>
              <div className="w-36 md:w-40">
                <div className="text-white">
                  <div>
                    <div className="text-white text-sm font-semibold mr-1">Earn {pool.earningToken.symbol}</div>
                    <div className="text-white text-xs flex items-center">Stake {pool.stakingToken.symbol} </div>
                  </div>
                </div>
              </div>
              <div className="flex items-start">
                <div className="hidden md:block w-32 md:w-32">
                  <div className="text-gray-500 font-medium uppercase text-xxs">APY</div>
                  <div className="text-white text-xs">
                    {loading ? <Skeleton /> : <Balance value={pool.apy ?? 0} decimals={2} unit="%" />}
                  </div>
                </div>
                <div className="hidden sm:block w-32 md:w-32">
                  <div className="text-gray-500 font-medium uppercase text-xxs">Daily ROI</div>
                  <div className="text-white text-xs">
                    {loading ? <Skeleton /> : <Balance value={pool.dailyApr} decimals={2} unit="%" />}
                  </div>
                </div>
                <div className="hidden sm:block w-32 md:w-36">
                  <div className="text-gray-500 font-medium uppercase text-xxs">Lock Period</div>
                  <div className="text-white text-xs">
                    {loading ? <Skeleton /> : `${pool.stakePeriod / DAY_IN_SECONDS} days`}
                  </div>
                </div>
              </div>
              <div className="ml-auto h-6 flex items-center">
                <ChevronDownIcon
                  className={classNames(toggleOpen ? "-rotate-180" : "rotate-0", "h-5 w-5 transform")}
                  aria-hidden="true"
                />
              </div>
            </div>
            <div className="sm:hidden">
              <div
                className="xs:flex text-white px-5 pb-5 pt-3 z-0"
                onClick={toggleOpen ? () => setToggleOpen(false) : () => setToggleOpen(true)}
              >
                <div className="flex items-start justify-between">
                  <div className="w-28">
                    <div className="text-gray-500 font-medium uppercase text-xs">APY</div>
                    <div className="text-white text-xs">
                      {loading ? <Skeleton /> : <Balance value={pool.apy ?? 0} decimals={2} unit="%" />}
                    </div>
                  </div>
                  <div className="w-28">
                    <div className="text-gray-500 font-medium uppercase text-xs">Daily ROI</div>
                    <div className="text-white text-xs">
                      {loading ? <Skeleton /> : <Balance value={pool.dailyApr} decimals={2} unit="%" />}
                    </div>
                  </div>
                  <div className="w-28">
                    <div className="text-gray-500 font-medium uppercase text-xs">Lock Period</div>
                    <div className="text-white text-xs">
                      {loading ? <Skeleton /> : `${pool.stakePeriod / DAY_IN_SECONDS} days`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Disclosure.Button>
          {toggleOpen && (
            <Disclosure.Panel static as="div" className="bg-black p-5 lg:flex items-center">
              <ActionPanel pool={pool} loading={loading} currentBlock={currentBlock} />
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  );
}

export default PoolRow;
