// import { useState } from "react";
import Button from "src/components/Button";
import { useAddress } from "src/hooks";
import { BIG_ZERO } from "src/utils/bigNumber";
import BigNumber from "bignumber.js";
import Skeleton from "src/components/Skeleton";
// import Balance from "src/components/Balance";
import ConnectMenu from "src/components/Menu/ConnectMenu";
import { FixedDeposit } from "src/constants/types";

interface Props {
  pool: FixedDeposit;
  loading: boolean;
  setDepositModalVisible: (x: boolean) => void;
  // setWithdrawModalVisible: (x: boolean) => void;
  setGetTokenModalVisible: (x: boolean) => void;
  handleApprove: () => void;
  requestedApproval: boolean;
  // onEmergencyUnstake: (x: string) => void;
}

function Staking(props: Props) {
  const {
    pool,
    loading,
    setDepositModalVisible,
    // setWithdrawModalVisible,
    setGetTokenModalVisible,
    handleApprove,
    requestedApproval,
    // onEmergencyUnstake,
  } = props;
  const account = useAddress();
  const isApproved = !loading && parseFloat(pool.allowance) > 0;

  const walletBalance = parseFloat(pool.walletBalance) ? new BigNumber(pool.walletBalance) : BIG_ZERO;
  // const stakedBalance = parseFloat(pool.stakedBalance) ? new BigNumber(pool.stakedBalance) : BIG_ZERO;

  if (!account) {
    return (
      <div className="w-full">
        <div className="text-gray-500 font-medium uppercase text-xs">Make Deposit</div>
        <div>
          <ConnectMenu width="w-full" />
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="w-full">
        <div className="text-gray-500 font-medium uppercase text-xs">Make Deposit</div>
        <div>
          <Skeleton className="w-full" height="h-10" />
        </div>
      </div>
    );
  }

  if (!isApproved) {
    return (
      <div className="w-full">
        <div className="text-white font-medium uppercase text-xs">
          <span className="text-primary">Enable</span> Deposit
        </div>
        <div>
          <Button onClick={handleApprove} disabled={requestedApproval} className="w-full">
            Enable
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-full">
        <div className="text-white font-medium uppercase text-xs">
          Deposit <span className="text-primary">{pool.stakingToken.symbol}</span>
        </div>
        <div>
          <Button
            onClick={walletBalance.gt(0) ? () => setDepositModalVisible(true) : () => setGetTokenModalVisible(true)}
            className="w-full"
          >
            Deposit
          </Button>
        </div>
      </div>
    </>
  );
}

export default Staking;
