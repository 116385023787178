import { ethers } from "ethers";
import { simpleRpcProvider } from "./providers";
import MultiCallAbi from "src/abi/Multicall.json";
import SyrupPoolAbi from "src/abi/SyrupPool.json";
import FixedDepositAbi from "src/abi/FixedDepositStaking.json";
import bep20Abi from "src/abi/erc20.json";
import { FixedDepositStaking } from "src/typechain";

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new ethers.Contract(address, abi, signerOrProvider);
};

export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B", signer);
};

export const getSousChef = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(SyrupPoolAbi, address, signer);
};

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer);
};

export const getFixedDeposit = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(FixedDepositAbi, address, signer) as FixedDepositStaking;
};
