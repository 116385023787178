import classNames from "../utils/classnames";

interface Props {
  className?: string;
  width?: string;
  height?: string;
  bg?: string;
}

const Button: React.FC<Props> = ({ children, className: _className, width, height, bg }) => {
  const _width = width ?? "w-20";
  const _height = height ?? "h-6";
  const _bg = bg ?? "bg-gray-700";
  return (
    <div
      className={classNames(
        "animate-pulse rounded-md select-none focus:outline-none",
        _bg,
        _width,
        _height,
        _className ?? "",
      )}
    >
      {children}
    </div>
  );
};

export default Button;
