import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3Context } from "src/hooks/web3Context";
import { getDividendPool } from "src/slices/StakingSlice";
import PoolRow from "./PoolRow";
import { getSyrupPool } from "src/slices/SyrupSlice";
import useRefresh from "src/hooks/useRefresh";

export const getPoolBlockInfo = (pool, currentBlock) => {
  const { startBlock, endBlock, isFinished } = pool;
  const shouldShowBlockCountdown = Boolean(!isFinished && startBlock && endBlock);
  const blocksUntilStart = Math.max(startBlock - currentBlock, 0);
  const blocksRemaining = Math.max(endBlock - currentBlock, 0);
  const hasPoolStarted = blocksUntilStart === 0 && blocksRemaining > 0;
  const blocksToDisplay = hasPoolStarted ? blocksRemaining : blocksUntilStart;
  return { shouldShowBlockCountdown, blocksUntilStart, blocksRemaining, hasPoolStarted, blocksToDisplay };
};

function PoolRows() {
  const dispatch = useDispatch();
  const { provider, address } = useWeb3Context();

  const syrupPools = useSelector(state => {
    return state.syrup;
  });

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });

  const { fastRefresh } = useRefresh();

  useEffect(() => {
    dispatch(getDividendPool({ provider }));
    if (currentBlock) {
      dispatch(getSyrupPool({ account: address, currentBlock }));
    }
  }, [dispatch, currentBlock, fastRefresh]);

  return (
    <>
      <div className="text-white text-3xl font-bold text-center">Pools</div>
      <div className="text-gray-400 text-md mb-10 text-center">Stake ATLAS to Earn Other Assets</div>
      {Object.keys(syrupPools.data).map(k => {
        return (
          <PoolRow
            key={syrupPools.data[k].sousId}
            pool={syrupPools.data[k]}
            loading={syrupPools.status === "idle"}
            currentBlock={currentBlock}
          />
        );
      })}
    </>
  );
}

export default PoolRows;
