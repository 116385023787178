import { useState } from "react";
// import Button from "src/components/Button";
import BigNumber from "bignumber.js";
import DepositTokenModal from "src/components/DepositTokenModal";
// import WithdrawTokenModal from "src/components/WithdrawTokenModal";
import GetTokenModal from "src/components/GetTokenModal";
import { BIG_ZERO } from "src/utils/bigNumber";
import Harvest from "./Harvest";
import Stake from "./Stake";
import { useStakePool, useApprovePool } from "../../hooks/useStakeFixedDeposit";
import { useERC20 } from "src/hooks/useContract";
import { FixedDeposit } from "src/constants/types";

interface Props {
  pool: FixedDeposit;
  loading: boolean;
  currentBlock: number;
}

function ActionPanel(props: Props) {
  const { pool, loading, currentBlock } = props;

  const [depositQuantity, setDepositQuantity] = useState("");
  // const [withdrawQuantity, setWithdrawQuantity] = useState("");

  const [depositModalVisible, setDepositModalVisible] = useState(false);
  // const [withdrawModalVisible, setWithdrawModalVisible] = useState(false);
  const [getTokenModalVisible, setGetTokenModalVisible] = useState(false);

  const stakingTokenContract = useERC20(pool.stakingToken.address?.[56] ?? "");

  const { onStake } = useStakePool(pool.contractAddress?.[56]);
  // const { onUnstake } = useUnstakePool(pool.contractAddress?.[56]);
  const { handleApprove, requestedApproval } = useApprovePool(
    stakingTokenContract,
    pool.contractAddress?.[56],
    pool.earningToken.symbol,
  );
  // const { onUnstake: onEmergencyUnstake } = useUnstakePool(pool.contractAddress?.[56], true);

  const walletBalance = parseFloat(pool.walletBalance) ? new BigNumber(pool.walletBalance).dividedBy(1e9) : BIG_ZERO;
  // const stakedBalance = parseFloat(pool.stakedBalance) ? new BigNumber(pool.stakedBalance).dividedBy(1e9) : BIG_ZERO;

  return (
    <>
      <div className="flex flex-col lg:flex-row-reverse w-full">
        {!pool.isFinished && (
          <div className="lg:w-1/4 mb-2 lg:mb-0 w-full h-24 bg-gray-900 rounded-lg lg:ml-2 lg:flex-grow p-4 flex items-center">
            <Stake
              pool={pool}
              loading={loading}
              handleApprove={() => handleApprove(currentBlock)}
              requestedApproval={requestedApproval}
              // onEmergencyUnstake={(x: string) => onEmergencyUnstake(x, currentBlock)}
              setDepositModalVisible={(x: boolean) => setDepositModalVisible(x)}
              // setWithdrawModalVisible={(x: boolean) => setWithdrawModalVisible(x)}
              setGetTokenModalVisible={(x: boolean) => setGetTokenModalVisible(x)}
            />
          </div>
        )}
        <Harvest pool={pool} loading={loading} currentBlock={currentBlock} />
      </div>

      <DepositTokenModal
        visible={depositModalVisible}
        quantity={depositQuantity}
        setQuantity={x => setDepositQuantity(x)}
        maxQuantity={walletBalance.toString()}
        onClose={() => setDepositModalVisible(false)}
        token={pool.stakingToken}
        action={() => {
          onStake(depositQuantity, currentBlock);
          setDepositModalVisible(false);
        }}
        // warning="By staking sATLAS you understand that you will not be able to unstake your ATLAS tokens, as sATLAS tokens are required to unstake ATLAS"
      />
      <GetTokenModal
        visible={getTokenModalVisible}
        onClose={() => setGetTokenModalVisible(false)}
        token={pool.stakingToken}
      />
    </>
  );
}

export default ActionPanel;
