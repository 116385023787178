import { BigNumber } from "ethers";
import { useSelector, useDispatch } from "react-redux";
import { trim, prettyVestingPeriod } from "../../helpers";
import { redeemBond } from "../../slices/BondSlice";
// import BondLogo from "../../components/BondLogo";
// import { SvgIcon } from "@material-ui/core";
// import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
// import "./choosebond.scss";
import { Skeleton } from "@material-ui/lab";
import { useWeb3Context, useBonds } from "src/hooks";
import { isPendingTxn, txnButtonTextGeneralPending } from "src/slices/PendingTxnsSlice";
import Button from "src/components/Button";
import Pair from "src/components/TokenImages/Pair";
import Single from "src/components/TokenImages/Single";
import classNames from "src/utils/classnames";
import { RefreshIcon } from "@heroicons/react/outline";
import tokens from "src/tokens/tokens";
// import { BondType } from "src/lib/Bond";

export function ClaimBondTableData({ userBond, hasSeparator }) {
  const dispatch = useDispatch();
  const { address, chainID, provider } = useWeb3Context();
  const { bonds } = useBonds(chainID);

  const bond = userBond[1];
  const bondName = bond.bond;

  const isAppLoading = useSelector(state => state.app.loading ?? true);

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const vestingPeriod = () => {
    return prettyVestingPeriod(currentBlock, bond.bondMaturationBlock);
  };

  async function onRedeem({ autostake }) {
    let currentBond = bonds.find(bond => bond.name === bondName);
    await dispatch(
      redeemBond({
        address,
        bond: currentBond,
        networkID: chainID,
        provider,
        autostake,
        stakingPlan: BigNumber.from(0),
      }),
    );
  }

  return (
    <div
      className={classNames(
        "flex items-start justify-between",
        hasSeparator ? "border-b border-gray-600 pb-4 mb-4" : "",
      )}
    >
      <div className="w-20 flex-grow-0">
        {bond.isLP || bond.quoteToken !== undefined ? (
          <Pair topToken={bond.quoteToken} bottomToken={bond.bondToken} />
        ) : (
          <Single token={bond.bondToken} />
        )}
      </div>
      <div className="w-40">
        <div className="text-gray-500 font-medium uppercase text-xxs">Bonds</div>
        <div className="text-white text-xs uppercase">
          {bond.isLP || bond.quoteToken !== undefined
            ? `${bond.bondToken.symbol} - ${bond.quoteToken.symbol}`
            : bond.bondToken.symbol}
          {/* {bond.isLP && (
            <a className="ml-1" href={bond.lpUrl} target="_blank" rel="noreferrer">
              <SvgIcon component={ArrowUp} htmlColor="#A3A3A3" />
            </a>
          )} */}
        </div>
      </div>
      {/* <div className="w-28 lg:w-40">
        <div className="text-gray-500 font-medium uppercase text-xxs">Claimable</div>
        <div className="text-white text-xs">
          {bond.pendingPayout ? trim(bond.pendingPayout, 4) : <Skeleton />} ATLAS
        </div>
      </div>
      <div className="w-28 lg:w-40">
        <div className="text-gray-500 font-medium uppercase text-xxs">Pending</div>
        <div className="text-white text-xs">{bond.interestDue ? trim(bond.interestDue, 4) : <Skeleton />} ATLAS</div>
      </div> */}
      <div className="w-40">
        <div className="text-gray-500 font-medium uppercase text-xxs">Vesting Period</div>
        <div className="text-white text-xs">
          {isAppLoading ? (
            <Skeleton />
          ) : (
            <div>
              <div>
                {new Intl.NumberFormat("en-US", {}).format(
                  bond.bondMaturationBlock > currentBlock ? bond.bondMaturationBlock - currentBlock : 0,
                )}{" "}
                blocks left
              </div>
              <div className="text-xxs">~ {vestingPeriod()}</div>
            </div>
          )}
        </div>
      </div>

      {bond.payoutToken === tokens.atlas ? (
        <div className="flex items-center justify-end flex-grow ml-auto">
          <RefreshIcon className="w-5 h-5 text-white animate-reverse-spin-slow flex-shrink-0 mr-2" />
          <div className="text-white text-xs hidden lg:block">Earnings are automatically staked to the ATLAS pool</div>
          <div className="text-white text-xs lg:hidden">Earnings auto-staked to ATLAS pool</div>
        </div>
      ) : (
        <div className="w-36 ml-auto">
          <div className="mb-1">
            <Button
              variant="gradient-pink"
              disabled={isPendingTxn(pendingTransactions, "redeem_bond_" + bondName) || bond.pendingPayout == 0.0}
              onClick={() => onRedeem({ autostake: false })}
              className="sm:py-1 w-full"
            >
              {txnButtonTextGeneralPending(pendingTransactions, "redeem_bond_" + bondName, "Claim")}
            </Button>
          </div>
          {/* <div>
          <Button onClick={() => onRedeem({ autostake: true })} scale="sm" className="sm:py-1 w-full">
            {txnButtonTextGeneralPending(
              pendingTransactions,
              "redeem_bond_" + bondName + "_autostake",
              "Claim & Stake",
            )}
          </Button>
        </div> */}
        </div>
      )}
    </div>
  );
}

export function ClaimBondCardData({ userBond, hasSeparator }) {
  const dispatch = useDispatch();
  const { address, chainID, provider } = useWeb3Context();
  const { bonds } = useBonds(chainID);

  const bond = userBond[1];
  const bondName = bond.bond;

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const vestingPeriod = () => {
    return prettyVestingPeriod(currentBlock, bond.bondMaturationBlock);
  };

  async function onRedeem({ autostake }) {
    let currentBond = bonds.find(bond => bond.name === bondName);
    await dispatch(
      redeemBond({
        address,
        bond: currentBond,
        networkID: chainID,
        provider,
        autostake,
        stakingPlan: BigNumber.from(0),
      }),
    );
  }

  return (
    <div className={classNames(hasSeparator ? "border-b border-gray-600 pb-4 mb-4" : "")}>
      <div className="flex justify-between">
        <div className="flex items-center mb-3">
          <div className="mr-3">
            {bond.isLP || bond.quoteToken !== undefined ? (
              <Pair topToken={bond.quoteToken} bottomToken={bond.bondToken} scale="xs" />
            ) : (
              <Single token={bond.bondToken} scale="xs" />
            )}
          </div>
          <div className="text-white mr-1">{bond.displayName}</div>
        </div>
      </div>

      <div className="flex items-center justify-between mb-3">
        <div className="text-gray-500 font-medium uppercase text-xs">Vesting Period</div>
        <div className="text-white text-right text-xs">{vestingPeriod()}</div>
      </div>

      {bond.payoutToken === tokens.atlas ? (
        <div className="flex items-center justify-center mx-auto">
          <RefreshIcon className="w-4 h-4 text-white animate-spin-slow flex-shrink-0 mr-1" />
          <div className="text-white text-xs">Earnings auto-staked to ATLAS pool</div>
        </div>
      ) : (
        <div className="flex justify-between">
          <Button
            disabled={isPendingTxn(pendingTransactions, "redeem_bond_" + bondName) || bond.pendingPayout == 0.0}
            onClick={() => onRedeem({ autostake: false })}
            scale="xs"
            className="w-1/2 mr-1"
            variant="gradient-pink"
          >
            {txnButtonTextGeneralPending(pendingTransactions, "redeem_bond_" + bondName, "Claim")}
          </Button>

          {/* <Button
            variant="outlined"
            color="primary"
            onClick={() => onRedeem({ autostake: true })}
            scale="xs"
            className="w-1/2 ml-1"
          >
            {txnButtonTextGeneralPending(
              pendingTransactions,
              "redeem_bond_" + bondName + "_autostake",
              "Claim & Stake",
            )}
          </Button> */}
        </div>
      )}

      {/* <div className="flex items-center justify-between mb-3">
        <div className="text-gray-400">Claimable</div>
        <div className="text-white">{bond.pendingPayout ? trim(bond.pendingPayout, 4) : <Skeleton />} ATLAS</div>
      </div>

      <div className="flex items-center justify-between mb-3">
        <div className="text-gray-400">Pending</div>
        <div className="text-white">{bond.interestDue ? trim(bond.interestDue, 4) : <Skeleton />} ATLAS</div>
      </div> */}
    </div>
  );
}
