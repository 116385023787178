import { useCallback, useState } from "react";
import BigNumberJS from "bignumber.js";
import { useSelector, useDispatch } from "react-redux";
import BigNumber from "bignumber.js";
import { getSyrupPool } from "src/slices/SyrupSlice";
import { useWeb3Context } from "src/hooks";
import { useFixedDeposit } from "src/hooks/useContract";
import { ethers } from "ethers";
import { FixedDepositStaking } from "src/typechain";
import { getFixedDepositPool } from "src/slices/FixedDepositSlice";

export const BIG_TEN = new BigNumberJS(10);

const stakePool = async (contract: FixedDepositStaking, amount: string) => {
  const estimate = await contract.estimateGas.deposit(new BigNumber(amount).times(BIG_TEN.pow(9)).toString());
  const tx = await contract.deposit(new BigNumber(amount).times(BIG_TEN.pow(9)).toString(), {
    gasLimit: estimate.mul(2000).div(1000),
  });
  const receipt = await tx.wait();
  return receipt.status;
};

const harvestPool = async (contract: FixedDepositStaking, depositId: number | string) => {
  const tx = await contract.getReward(depositId);
  const receipt = await tx.wait();
  return receipt.status;
};

const emergencyWithdrawPool = async (contract: FixedDepositStaking, depositId: number | string) => {
  const tx = await contract.forfeit(depositId);
  const receipt = await tx.wait();
  return receipt.status;
};

export const useStakePool = (poolAddress: string) => {
  const dispatch = useDispatch();
  const { provider, address, connect, chainID } = useWeb3Context();
  const contract = useFixedDeposit(poolAddress);

  const handleStake = useCallback(
    async (amount: string, currentBlock: number) => {
      await stakePool(contract, amount);
      dispatch(getFixedDepositPool({ account: address, currentBlock }));
    },
    [address, provider, dispatch, contract, poolAddress],
  );

  return { onStake: handleStake };
};

export const useHarvestPool = (poolAddress: string) => {
  const dispatch = useDispatch();
  const { provider, address, connect, chainID } = useWeb3Context();
  const contract = useFixedDeposit(poolAddress);
  const currentBlock = useSelector((state: any) => {
    return state.app.currentBlock;
  });

  const handleHarvest = useCallback(
    async (depositId: number | string) => {
      await harvestPool(contract, depositId);
      dispatch(getFixedDepositPool({ account: address, currentBlock }));
    },
    [address, dispatch, contract, poolAddress],
  );

  const handleEmergencyWithdrawal = useCallback(
    async (depositId: number | string) => {
      await emergencyWithdrawPool(contract, depositId);
      dispatch(getFixedDepositPool({ account: address, currentBlock }));
    },
    [address, dispatch, contract, poolAddress],
  );

  return { onReward: handleHarvest, onEmergencyWithdraw: handleEmergencyWithdrawal };
};

export const useApprovePool = (lpContract: any, poolAddress: string, earningTokenSymbol: string) => {
  const [requestedApproval, setRequestedApproval] = useState(false);
  const dispatch = useDispatch();
  const { provider, address, connect, chainID } = useWeb3Context();
  const contract = useFixedDeposit(poolAddress);

  const handleApprove = useCallback(
    async (currentBlock: number) => {
      try {
        await window.ethereum.enable();
        setRequestedApproval(true);
        const tx = await lpContract.approve(contract.address, ethers.constants.MaxUint256);
        const receipt = await tx.wait();
        if (receipt.status) {
          // toastSuccess(
          //   t("Contract Enabled"),
          //   t("You can now stake in the %symbol% pool!", { symbol: earningTokenSymbol }),
          // );
          setRequestedApproval(false);
        } else {
          // user rejected tx or didn't go thru
          // toastError(t("Error"), t("Please try again. Confirm the transaction and make sure you are paying enough gas!"));
          setRequestedApproval(false);
        }
      } catch (e) {
        console.error(e);
        setRequestedApproval(false);
        // toastError(t("Error"), t("Please try again. Confirm the transaction and make sure you are paying enough gas!"));
      }
      dispatch(getFixedDepositPool({ account: address, currentBlock }));
    },
    [address, dispatch, lpContract, contract, poolAddress, earningTokenSymbol],
  );

  return { handleApprove, requestedApproval };
};
