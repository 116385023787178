// import { TOKEN_IMAGE_URL_OVERRRIDES } from "../tokens/tokens";
import { Token } from "../tokens/types";

const getTokenLogoURL = (token: Token) => {
  // return TOKEN_IMAGE_URL_OVERRRIDES.map((x) => x.toLowerCase()).includes(
  //   address.toLowerCase()
  // )
  //   ? `/tokens/${address}.png`
  //   : `https://assets.trustwalletapp.com/blockchains/smartchain/assets/${address}/logo.png`;
  if (token.symbol === "BNB") {
    return `/token/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.svg`;
  }
  return `/token/${token.address?.[56]}.svg`;
};

export default getTokenLogoURL;
