import { useCallback, useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useDispatch, useSelector } from "react-redux";
// import NewReleases from "@material-ui/icons/NewReleases";
// import RebaseTimer from "../../components/RebaseTimer/RebaseTimer";
// import TabPanel from "../../components/TabPanel";
import { trim } from "../../helpers";
import { changeApproval, changeStake, claim } from "../../slices/StakeThunk";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { error } from "../../slices/MessagesSlice";
import { ethers } from "ethers";
import { getDividendPool } from "src/slices/StakingSlice";
import { loadAccountDetails } from "src/slices/AccountSlice";
import Single from "src/components/TokenImages/Single";
import tokens from "src/tokens/tokens";
import Button from "src/components/Button";
import Skeleton from "src/components/Skeleton";
import ConnectMenu from "src/components/Menu/ConnectMenu.jsx";
import classNames from "src/utils/classnames";
import {
  ArrowCircleDownIcon,
  ArrowCircleUpIcon,
  ExclamationIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import useRefresh from "src/hooks/useRefresh";
import Balance from "src/components/Balance";
import { Link } from "react-router-dom";
// import { useWeb3React } from "@web3-react/core";
import Title from "./Title";

function SingleStakeAtlas() {
  const dispatch = useDispatch();
  const { provider, address, connect, chainID } = useWeb3Context();
  const [navToggle, setNavToggle] = useState("unstake");

  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState("");

  const stakingData = useSelector(state => state.staking.publicData);

  const { fastRefresh } = useRefresh();
  useEffect(() => {
    dispatch(getDividendPool({ provider, account: address }));
    dispatch(loadAccountDetails({ networkID: chainID, address, provider }));
  }, [dispatch, address, fastRefresh]);

  const isAppLoading = useSelector(state => state.app.loading);
  // const currentIndex = useSelector(state => {
  //   return state.app.currentIndex;
  // });
  // const fiveDayRate = useSelector(state => {
  //   return state.app.fiveDayRate;
  // });
  const ohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.ohm;
  });
  const sohmBalance = useSelector(state => {
    return state.account.balances && state.account.balances.sohm;
  });
  // const fsohmBalance = useSelector(state => {
  //   return state.account.balances && state.account.balances.fsohm;
  // });
  // const wsohmAsSohm = useSelector(state => {
  //   return state.account.balances && state.account.balances.wsohmAsSohm;
  // });
  const stakeAllowance = useSelector(state => {
    return state.account.staking && state.account.staking.ohmStake;
  });
  const unstakeAllowance = useSelector(state => {
    return state.account.staking && state.account.staking.ohmUnstake;
  });
  const stakingRebase = useSelector(state => {
    return state.app.stakingRebase;
  });

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const setMax = () => {
    if (navToggle === "stake") {
      setQuantity(ohmBalance);
    } else {
      setQuantity(sohmBalance);
    }
  };

  const onSeekApproval = async token => {
    await dispatch(changeApproval({ address, token, provider, networkID: chainID }));
  };

  const onChangeStake = async action => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(quantity) || quantity === 0 || quantity === "") {
      // eslint-disable-next-line no-alert
      return dispatch(error("Please enter a value!"));
    }

    // 1st catch if quantity > balance
    let gweiValue = ethers.utils.parseUnits(quantity, "gwei");
    if (action === "stake" && gweiValue.gt(ethers.utils.parseUnits(ohmBalance, "gwei"))) {
      return dispatch(error("You cannot stake more than your ATLAS balance."));
    }

    if (action === "unstake" && gweiValue.gt(ethers.utils.parseUnits(sohmBalance, "gwei"))) {
      return dispatch(error("You cannot unstake more than your sATLAS balance."));
    }

    await dispatch(changeStake({ address, action, value: quantity.toString(), provider, networkID: chainID }));
  };

  const hasAllowance = useCallback(
    token => {
      if (token === "ohm") return stakeAllowance > 0;
      if (token === "sohm") return unstakeAllowance > 0;
      return 0;
    },
    [stakeAllowance, unstakeAllowance],
  );

  const isAllowanceDataLoading = (stakeAllowance == null && view === 0) || (unstakeAllowance == null && view === 1);

  let modalButton = [];

  modalButton.push(
    <Button variant="contained" color="primary" className="connect-button" onClick={connect} key={1}>
      Connect Wallet
    </Button>,
  );

  // const changeView = (event, newView) => {
  //   setView(newView);
  // };

  // const trimmedBalance = Number(
  //   [sohmBalance]
  //     .filter(Boolean)
  //     .map(balance => Number(balance))
  //     .reduce((a, b) => a + b, 0)
  //     .toFixed(4),
  // );
  // const trimmedStakingAPY = trim(stakingAPY * 100, 1);
  // const stakingRebasePercentage = trim(stakingRebase * 100, 4);
  // const nextRewardValue = trim((stakingRebasePercentage / 100) * trimmedBalance, 4);
  const [pBASE, pJAWS] = stakingData?.profits ?? ["0", "0"];
  // console.log(stakingData);
  const onClaim = async action => {
    await dispatch(claim({ address, action, provider, networkID: chainID }));
  };

  const lockedTill = new BigNumber(stakingData?.singleLockedTill ?? 0).minus(stakingData?.currentBlock ?? 0);

  const claimable = new BigNumber(pBASE).div(1e18).gt(0) || new BigNumber(pJAWS).div(1e18).gt(0);

  return (
    <>
      <Title />
      <div className="rounded-lg border border-gray-600 bg-indigo-200 max-w-xl mx-auto p-5 mb-3">
        <div className="flex items-center">
          <ExclamationCircleIcon className="h-12 w-8 text-indigo-800 mr-2 flex-shrink-0" />
          <div>
            <div className="text-black text-lg text-indigo-800 font-bold">Deprecation of Single Stake ATLAS</div>
            <div className="text-indigo-800 font-medium">
              We are removing sATLAS to simplify user experience. You may now purchase bonds and have the ATLAS earned
              directly staked into the fixed deposit plans. Please unstake your ATLAS and invest in bonds, pools or
              fixed deposit plans.
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900 border border-gray-600 max-w-xl mx-auto p-6 md:px-10 rounded-lg">
        <div className="sm:flex mb-5">
          <div className="flex justify-center sm:justify-start items-start sm:w-1/2">
            <Single token={tokens.atlas} scale="md" />
            <div className="ml-2 flex items-center">
              <div>
                <div className="text-xs uppercase text-center text-primary leading-none mb-1">Single Stake</div>
                <div className="text-2xl font-semibold leading-none">ATLAS</div>
              </div>
            </div>
          </div>
          <div className="flex-grow sm:w-1/2 mt-3 sm:mt-0">
            <div className="flex justify-between mb-3">
              <div className="text-md sm:text-lg font-semibold sm:ml-8 leading-4">TVL</div>
              <div className="text-md sm:text-lg leading-4">
                {stakingData.singleTotalSupply ? (
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(new BigNumber(stakingData.singleTVL).toString())
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>

            <div className="flex justify-between mb-1">
              <div className="sm:ml-8 flex items-center leading-4">
                <div className="text-md sm:text-lg font-semibold mr-1">Status</div>
                {/* <div className="text-xxs">(daily)</div> */}
              </div>
              <div>Ended</div>
            </div>

            {/* <div className="flex justify-end">
              {stakingData.singleAPY ? (
                <Link to="/fixed-deposits">
                  <div className="flex items-center hover:text-primary">
                    <div className="text-xxs mr-1">up to</div>
                    <div className="text-xs font-semibold">
                      {new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }).format(3 + stakingData.singleAPY / 365)}
                      %
                    </div>
                    <div className="text-xxs ml-1">with sATLAS</div>
                  </div>
                </Link>
              ) : (
                <Skeleton />
              )}
            </div> */}
          </div>
        </div>

        <div className="max-w-lg mx-auto">
          {!address ? (
            <div>
              <div className="flex justify-center">
                <ConnectMenu />
              </div>
              <div className="mt-2 text-center text-gray-500">Connect your wallet to start staking ATLAS</div>
            </div>
          ) : (
            <>
              <div>
                <nav className="-mb-px flex justify-center space-x-8" aria-label="Tabs">
                  {/* <div
                  onClick={() => {
                    setNavToggle("stake");
                  }}
                  className={classNames(
                    navToggle === "stake"
                      ? "border-primary text-primary"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-sm cursor-pointer",
                  )}
                  aria-current={navToggle === "stake" ? "page" : undefined}
                >
                  <ArrowCircleDownIcon
                    className={classNames(
                      navToggle === "stake" ? "text-primary" : "text-gray-400 group-hover:text-gray-500",
                      "-ml-0.5 mr-2 h-5 w-5",
                    )}
                    aria-hidden="true"
                  />
                  <span>Stake</span>
                </div> */}
                  <div
                    onClick={() => {
                      setNavToggle("unstake");
                    }}
                    className={classNames(
                      navToggle === "unstake"
                        ? "border-primary text-primary"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "group inline-flex items-center py-1 px-1 border-b-2 font-medium text-sm cursor-pointer",
                    )}
                    aria-current={navToggle === "unstake" ? "page" : undefined}
                  >
                    <ArrowCircleUpIcon
                      className={classNames(
                        navToggle === "unstake" ? "text-primary" : "text-gray-400 group-hover:text-gray-500",
                        "-ml-0.5 mr-2 h-5 w-5",
                      )}
                      aria-hidden="true"
                    />
                    <span>Unstake</span>
                  </div>
                </nav>

                <div className="mt-5">
                  <div className="mb-1">
                    {address && !isAllowanceDataLoading ? (
                      <div className="flex max-w-md mx-auto rounded-md shadow-sm">
                        <input
                          type="number"
                          name="amount-input"
                          id="amount-input"
                          placeholder="Enter an amount"
                          value={quantity}
                          onChange={e => setQuantity(e.target.value)}
                          className="text-black flex-1 text-center block w-full focus:outline-none min-w-0 rounded-none rounded-l-md sm:text-sm pl-3"
                        />
                        <button
                          onClick={setMax}
                          className="inline-flex items-center px-5 py-2 rounded-r-md bg-gradient-to-br from-gray-700 via-gray-900 to-black text-white sm:text-sm"
                        >
                          Max
                        </button>
                      </div>
                    ) : (
                      <div className="flex max-w-md mx-auto">
                        <Skeleton width="w-full" height="h-10" />
                      </div>
                    )}
                  </div>

                  <div className="max-w-md mx-auto mb-5">
                    {/* {navToggle === "stake" && (
                    <div>
                      {isAllowanceDataLoading ? (
                        <Skeleton width="w-full" height="h-10" />
                      ) : address && hasAllowance("ohm") ? (
                        <Button
                          className="w-full"
                          disabled={isPendingTxn(pendingTransactions, "staking")}
                          onClick={() => {
                            onChangeStake("stake");
                          }}
                        >
                          {txnButtonText(pendingTransactions, "staking", "Stake ATLAS")}
                        </Button>
                      ) : (
                        <Button
                          className="w-full"
                          disabled={isPendingTxn(pendingTransactions, "approve_staking")}
                          onClick={() => {
                            onSeekApproval("ohm");
                          }}
                        >
                          {txnButtonText(pendingTransactions, "approve_staking", "Approve Staking")}
                        </Button>
                      )}
                    </div>
                  )} */}
                    {navToggle === "unstake" && (
                      <div>
                        {isAllowanceDataLoading ? (
                          <Skeleton width="w-full" height="h-10" />
                        ) : address && hasAllowance("sohm") ? (
                          <Button
                            className="w-full"
                            disabled={isPendingTxn(pendingTransactions, "unstaking")}
                            onClick={() => {
                              onChangeStake("unstake");
                            }}
                          >
                            {txnButtonText(pendingTransactions, "unstaking", "Unstake ATLAS")}
                          </Button>
                        ) : (
                          <Button
                            className="w-full"
                            disabled={isPendingTxn(pendingTransactions, "approve_unstaking")}
                            onClick={() => {
                              onSeekApproval("sohm");
                            }}
                          >
                            {txnButtonText(pendingTransactions, "approve_unstaking", "Approve Unstaking")}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="max-w-sm mx-auto">
                <div className="flex justify-between mb-2">
                  <div className="text-gray-300">Unstaked Balance</div>
                  <div>{isAppLoading ? <Skeleton /> : <>{trim(ohmBalance, 4)} ATLAS</>}</div>
                </div>

                <div className="flex justify-between mb-2">
                  <div className="text-gray-300">sATLAS Balance</div>
                  <div>{isAppLoading ? <Skeleton /> : <>{trim(sohmBalance, 4)} sATLAS</>}</div>
                </div>

                <div className="bg-gray-800 h-px my-4" />

                <div className="flex justify-between mb-2">
                  <div className="text-gray-300">Staked Balance</div>
                  <div>
                    {isAppLoading ? (
                      <Skeleton />
                    ) : (
                      <>{new BigNumber(stakingData?.singleStaked ?? "0").div(1e9)?.toString()} ATLAS</>
                    )}
                  </div>
                </div>

                {/* {new BigNumber(pBASE).div(1e9).gt(-1) && new BigNumber(stakingData?.singleStaked ?? "0").isEqualTo(0) && (
                  <div className="bg-gray-800 text-gray-300 w-full p-4 rounded-md flex justify-start items-center mb-2">
                    <ExclamationIcon className="w-6 h-6 text-gray-300 mr-2 flex-shrink-0" />
                    <div className="text-xs">
                      Staked Balance might be inaccurate due to caching in the smart contract. Any staking or unstaking
                      action will refresh this value.
                    </div>
                  </div>
                )} */}

                <div className="flex justify-between mb-1 pl-0">
                  <div className="text-gray-300">Locked Till</div>
                  <div>
                    {isAppLoading ? (
                      <Skeleton />
                    ) : (
                      <div className="flex">
                        <Balance fontSize="16px" value={lockedTill.gt(0) ? lockedTill?.toNumber() : 0} decimals={0} />
                        <div className="ml-1">more blocks</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="bg-gray-800 h-px my-4" />

                <div className="flex justify-between mb-1 pl-0">
                  <div className="text-gray-300">ATLAS Earned</div>
                  <div>{isAppLoading ? <Skeleton /> : <>{new BigNumber(pBASE).div(1e9)?.toString()} ATLAS</>}</div>
                </div>

                <div className="max-w-md mt-3 mx-auto sm:flex">
                  <div className="mb-2 sm:mb-0 sm:w-1/2 sm:mr-1">
                    <Button
                      className="w-full"
                      disabled={
                        !claimable ||
                        isPendingTxn(pendingTransactions, "claim") ||
                        isPendingTxn(pendingTransactions, "claim_and_unstake")
                      }
                      variant="gradient-pink"
                      onClick={() => {
                        onClaim("claim");
                      }}
                    >
                      {txnButtonText(pendingTransactions, "claim", "Claim")}
                    </Button>
                  </div>
                  <div className="sm:w-1/2 sm:ml-1">
                    <Button
                      className="w-full"
                      disabled={
                        !claimable ||
                        isPendingTxn(pendingTransactions, "claim") ||
                        isPendingTxn(pendingTransactions, "claim_and_unstake")
                      }
                      onClick={() => {
                        onClaim("claim_and_unstake");
                      }}
                    >
                      {txnButtonText(pendingTransactions, "claim_and_unstake", "Claim & Unstake")}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SingleStakeAtlas;
