import React, { useEffect, useRef } from "react";
import CountUp from "react-countup";
import classNames from "src/utils/classnames";

interface BalanceProps {
  value: number;
  color?: string;
  fontSize?: string;
  decimals?: number;
  unit?: string;
  isDisabled?: boolean;
  prefix?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Balance: React.FC<BalanceProps> = ({
  value,
  fontSize = "text-xs",
  color = "text-white",
  decimals = 3,
  isDisabled = false,
  unit = "",
  prefix = "",
  onClick,
  ...props
}) => {
  const previousValue = useRef(0);

  useEffect(() => {
    previousValue.current = value;
  }, [value]);

  return (
    <div className={classNames(isDisabled ? "text-gray-500" : color, fontSize)} onClick={onClick} {...props}>
      <CountUp
        start={previousValue.current}
        end={value}
        prefix={prefix}
        suffix={unit}
        decimals={decimals}
        duration={1}
        separator=","
      />
    </div>
  );
};

export default Balance;
