import { Token } from "src/tokens/types";
import Modal from "./Modal";
import Button from "src/components/Button";
import { Link } from "react-router-dom";
import tokens from "src/tokens/tokens";

interface Props {
  title?: string;
  token: Token;
  visible: boolean;
  onClose: () => void;
}

export default function GetTokenModal(props: Props) {
  const { title, visible, onClose, token } = props;

  return (
    <Modal title={title ?? `Get ${token.symbol}`} visible={visible} onClose={() => onClose()}>
      <div className="px-6 pb-6 pt-3 text-white">
        <div className="text-center mb-2">
          {token.symbol} is required to stake in this pool.{" "}
          {token === tokens.satlas && "You may get sATLAS by purchasing bonds."}
        </div>
        {token === tokens.satlas ? (
          <Link to="/bonds">
            <div className="flex">
              <Button>Buy Bonds</Button>
            </div>
          </Link>
        ) : (
          <a
            href={`https://autoshark.finance/swap?outputCurrency=${token.address?.[56]}`}
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex">
              <Button>Buy {token.symbol}</Button>
            </div>
          </a>
        )}
      </div>
    </Modal>
  );
}
