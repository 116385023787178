// next imports
import { Token } from "../../constants/types";
import getTokenLogoURL from "../../utils/getTokenLogoURL";

// own app

interface Props {
  topToken: Token;
  bottomToken: Token;
  scale?: string;
  ocean?: boolean;
}

const SCALE: { [key: string]: { wrapper: string; child: string; oceanTop: string; oceanBottom: string } } = {
  xs: {
    wrapper: "w-10 h-10",
    child: "w-7 h-7",
    oceanTop: "w-9 h-9",
    oceanBottom: "w-6 h-6",
  },
  sm: {
    wrapper: "w-12 h-12",
    child: "w-8 h-8",
    oceanTop: "w-7 h-7",
    oceanBottom: "w-7 h-7",
  },
  md: {
    wrapper: "w-14 h-14",
    child: "w-10 h-10",
    oceanTop: "w-12 h-12",
    oceanBottom: "w-8 h-8",
  },
  lg: {
    wrapper: "w-20 h-20",
    child: "w-14 h-14",
    oceanTop: "w-18 h-18",
    oceanBottom: "w-10 h-10",
  },
};

const Pair: React.FC<Props> = ({ topToken, bottomToken, scale, ocean }) => {
  const topTokenLogoUrl = getTokenLogoURL(topToken);
  const bottomTokenLogoUrl = getTokenLogoURL(bottomToken);
  const _scale = scale ?? "md";
  return (
    <div className={`${SCALE[_scale].wrapper} relative`}>
      <div className={`${ocean ? SCALE[_scale].oceanTop : SCALE[_scale].child} object-cover absolute`}>
        <img src={topTokenLogoUrl} alt={`${topToken.symbol} Token Images`} />
      </div>
      <div
        className={`${ocean ? SCALE[_scale].oceanBottom : SCALE[_scale].child} object-cover absolute bottom-0 right-0`}
      >
        <img src={bottomTokenLogoUrl} alt={`${bottomToken.symbol} Token Images`} />
      </div>
    </div>
  );
};

export default Pair;
