import { Token } from "src/tokens/types";
import Modal from "./Modal";
import Button from "src/components/Button";

interface Props {
  title?: string;
  visible: boolean;
  quantity: string;
  maxQuantity: string;
  token: Token;
  setQuantity: (x: string) => void;
  onClose: () => void;
  action: () => void;
}

export default function DepositTokenModal(props: Props) {
  const { title, visible, onClose, quantity, maxQuantity, setQuantity, token, action } = props;
  return (
    <Modal title={title ?? `Unstake ${token.symbol}`} visible={visible} onClose={() => onClose()}>
      <div className="px-6 pb-6 pt-3 text-white">
        <div className="flex justify-between mb-2 text-xs">
          <div>Staked</div>
          <div>
            {maxQuantity} {token.symbol}
          </div>
        </div>
        <div className="flex mb-2">
          <input
            type="number"
            placeholder="Enter an amount"
            value={quantity}
            onChange={e => setQuantity(`${e.target.value}`)}
            className="flex-1 text-center text-black block w-full focus:outline-none min-w-0 rounded-none rounded-l-md sm:text-sm pl-3"
          />
          <button
            onClick={() => setQuantity(maxQuantity)}
            className="inline-flex items-center px-5 py-2 rounded-r-md bg-gradient-to-br from-gray-700 via-gray-900 to-black text-white sm:text-sm"
          >
            Max
          </button>
        </div>
        <div className="flex mb-2">
          <Button variant="gradient-metal" onClick={action}>
            Unstake
          </Button>
        </div>
        <div onClick={onClose} className="text-center text-gray-400 cursor-pointer">
          Cancel
        </div>
      </div>
    </Modal>
  );
}
