import React, { useState, useCallback, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3Context } from "src/hooks/web3Context";
import { prettifySeconds, secondsUntilBlock, shorten, trim } from "../../../../helpers";
import { bondAsset, calcBondDetails, changeApproval } from "src/slices/BondSlice";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import Skeleton from "src/components/Skeleton";
import useDebounce from "src/hooks/Debounce";
import { error } from "src/slices/MessagesSlice";
import { DisplayBondDiscount } from "src/views/Bond/Bond";
import Button from "src/components/Button";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { InformationCircleIcon } from "@heroicons/react/outline";
import classNames from "src/utils/classnames";
import { fixedDeposits } from "src/constants/fixedDeposits";
import { FixedDepositConfig } from "src/constants/types";
import { usePopperTooltip } from "react-popper-tooltip";
import tokens from "src/tokens/tokens";

interface Props {
  bond: any;
  slippage: any;
  recipientAddress: string;
  vestingPeriod: string;
}

export default function BuyBonds(props: Props) {
  const { bond, slippage, recipientAddress, vestingPeriod } = props;
  const SECONDS_TO_REFRESH = 60;
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();

  const activeFixedDeposits = fixedDeposits.filter((pool: FixedDepositConfig) => !pool.isFinished);
  const [selected, setSelected] = useState(activeFixedDeposits[0]);

  const [quantity, setQuantity] = useState("");
  const [secondsToRefresh, setSecondsToRefresh] = useState(SECONDS_TO_REFRESH);

  const currentBlock = useSelector((state: any) => {
    return state.app.currentBlock;
  });

  const isBondLoading = useSelector((state: any) => state.bonding.status === "idle");

  const pendingTransactions = useSelector((state: any) => {
    return state.pendingTransactions;
  });

  async function onBond() {
    async function dispatchBondAsset() {
      await dispatch(
        bondAsset({
          value: quantity,
          slippage,
          bond,
          networkID: chainID,
          provider,
          address: recipientAddress || address,
          stakingPlan: selected.stakingPlan,
        }),
      );
      clearInput();
    }

    if (quantity === "") {
      dispatch(error("Please enter a value!"));
    } else if (isNaN(parseFloat(quantity))) {
      dispatch(error("Please enter a valid value!"));
    } else if (bond.bondMaturationBlock > currentBlock) {
      const shouldProceed = window.confirm(
        "You have an existing bond. Bonding will reset your vesting period. We recommend using a fresh wallet. Do you still want to proceed?",
      );
      if (shouldProceed) {
        await dispatchBondAsset();
      }
    } else {
      await dispatchBondAsset();
    }
  }

  const clearInput = () => {
    setQuantity("");
  };

  const hasAllowance = useCallback(() => {
    return bond.allowance > 0;
  }, [bond.allowance]);

  const setMax = () => {
    let maxQ;
    if (bond.maxBondPrice * bond.bondPrice < Number(bond.balance)) {
      // there is precision loss here on Number(bond.balance)
      maxQ = bond.maxBondPrice * bond.bondPrice.toString();
    } else {
      maxQ = bond.balance;
    }
    setQuantity(maxQ);
  };

  const bondDetailsDebounce = useDebounce(quantity, 1000);

  useEffect(() => {
    dispatch(calcBondDetails({ bond, value: `${quantity}`, provider, networkID: chainID }));
  }, [bondDetailsDebounce]);

  useEffect(() => {
    let interval: any = null;
    if (secondsToRefresh > 0) {
      interval = setInterval(() => {
        setSecondsToRefresh(secondsToRefresh => secondsToRefresh - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      dispatch(calcBondDetails({ bond, value: `${quantity}`, provider, networkID: chainID }));
      setSecondsToRefresh(SECONDS_TO_REFRESH);
    }
    return () => clearInterval(interval);
  }, [secondsToRefresh, quantity]);

  const onSeekApproval = async () => {
    dispatch(changeApproval({ address, bond, provider, networkID: chainID }));
  };

  const displayUnits = bond.displayUnits;

  const isAllowanceDataLoading = bond.allowance == null;

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement: "top",
  });

  // const warning =
  //   "By choosing a fixed deposit plan longer than 5 days, you understand that you will not be able to unstake your ATLAS tokens from single asset ATLAS vault after the 5 day vesting period of bonds.";

  return (
    <div>
      <div className="mb-5">
        {isAllowanceDataLoading ? (
          <Skeleton height="h-8" className="max-w-xs w-full mx-auto" />
        ) : (
          <>
            {hasAllowance() && (
              <div className="max-w-sm mx-auto mb-2">
                <div className="mt-1 sm:mt-0 mb-2 sm:col-span-2">
                  <div className="flex rounded-md shadow-sm">
                    <input
                      type="number"
                      name="outlined-adornment-amount"
                      id="outlined-adornment-amount"
                      placeholder="Enter an amount"
                      value={quantity}
                      onChange={e => setQuantity(e.target.value)}
                      className="flex-1 text-center block w-full focus:outline-none min-w-0 rounded-none rounded-l-md sm:text-sm pl-3"
                    />
                    <button
                      onClick={setMax}
                      className="inline-flex items-center px-5 py-2 rounded-r-md bg-gradient-to-br from-gray-700 via-gray-900 to-black text-white sm:text-sm"
                    >
                      Max
                    </button>
                  </div>
                </div>
                {bond.payoutToken === tokens.atlas && (
                  <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium text-gray-300 mb-1 flex items-center">
                          Select Fixed Deposit
                          <div ref={setTriggerRef} className="text-sm font-semibold text-white hover:opacity-80 ml-1">
                            <InformationCircleIcon className="w-5 h-5" />
                          </div>
                        </Listbox.Label>
                        <div className="relative">
                          <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm">
                            <span className="block truncate">{selected.stakePeriod / 86400} Day Fixed Deposit</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="bg-black absolute z-10 mt-1 w-full shadow-lg max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm">
                              {activeFixedDeposits.map(option => (
                                <Listbox.Option
                                  key={option.stakePeriod}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "text-white bg-gradient-to-br from-green-300 via-blue-500 to-purple-600"
                                        : "text-white",
                                      "cursor-default select-none relative p-4 text-sm",
                                    )
                                  }
                                  value={option}
                                >
                                  {({ selected, active }) => (
                                    <div className="flex flex-col">
                                      <div className="flex justify-between">
                                        <p className={active ? "font-semibold text-black" : "font-normal"}>
                                          {option.stakePeriod / 86400} Day Fixed Deposit
                                        </p>
                                        {selected ? (
                                          <span className={active ? "text-black" : "text-white"}>
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </div>
                                      <p className={classNames(active ? "text-black" : "text-gray-200", "mt-2")}>
                                        APY: {option.apy}%, Daily ROI: {option.dailyApr}%, Lock Period:{" "}
                                        {option.stakePeriod / 86400} days
                                      </p>
                                    </div>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                )}
                {/* {selected !== fixedDeposits[4] && (
                  <div className="p-3 bg-gray-700 rounded-lg my-2 flex items-center">
                    <ExclamationCircleIcon className="h-6 w-6 text-gray-300 mr-2 flex-shrink-0" />
                    <div className="text-xs text-gray-300">{warning}</div>
                  </div>
                )} */}
              </div>
            )}

            <div className="flex justify-center">
              {!bond.isAvailable[chainID] ? (
                <Button disabled={true} className="max-w-sm" variant="gradient-metal">
                  Sold Out
                </Button>
              ) : hasAllowance() ? (
                <Button
                  disabled={isPendingTxn(pendingTransactions, "bond_" + bond.name)}
                  onClick={onBond}
                  className="max-w-sm"
                  variant="gradient-primary"
                >
                  {txnButtonText(pendingTransactions, "bond_" + bond.name, "Bond")}
                </Button>
              ) : (
                <Button
                  disabled={isPendingTxn(pendingTransactions, "approve_" + bond.name)}
                  onClick={onSeekApproval}
                  className="max-w-sm"
                  variant="gradient-burning"
                >
                  {txnButtonText(pendingTransactions, "approve_" + bond.name, "Approve")}
                </Button>
              )}
            </div>
          </>
        )}
      </div>

      <div className="text-white max-w-sm mx-auto">
        <div className="flex justify-between mb-1">
          <div>Your Balance</div>
          <div className="uppercase">
            {isBondLoading ? (
              <Skeleton />
            ) : (
              <>
                {trim(bond.balance, 4)} {displayUnits}
              </>
            )}
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div>You Will Get</div>
          <div className="text-right">
            {isBondLoading ? (
              <Skeleton />
            ) : (
              `${trim(bond.bondQuote, 6) || "0"} ${bond.payoutToken ? bond.payoutToken.symbol : "ATLAS"}`
            )}
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div>Max You Can Buy</div>
          <div className="text-right">
            {isBondLoading ? (
              <Skeleton />
            ) : (
              `${trim(bond.maxBondPrice, 4) || "0"} ${bond.payoutToken ? bond.payoutToken.symbol : "ATLAS"}`
            )}
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div>ROI ({vestingPeriod}D)</div>
          <div className="text-right">
            {isBondLoading ? <Skeleton /> : <DisplayBondDiscount key={bond.name} bond={bond} />}
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div>Debt Ratio</div>
          <div className="text-right">{isBondLoading ? <Skeleton /> : `${trim(bond.debtRatio / 10000000, 2)}%`}</div>
        </div>
        <div className="flex justify-between">
          <div>Vesting Term</div>
          <div className="text-right">{isBondLoading ? <Skeleton /> : vestingPeriod} days</div>
        </div>

        {recipientAddress !== address && (
          <div className="data-row">
            <div>Recipient</div>
            <div className="text-right">{isBondLoading ? <Skeleton /> : shorten(recipientAddress)}</div>
          </div>
        )}
      </div>
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {`Upon purchase of the bond, ATLAS will immediately be awarded to you, and automatically staked into the fixed deposit plan of your choosing.`}
        </div>
      )}
    </div>
  );
}
