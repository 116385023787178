// import { useState } from "react";
import Button from "src/components/Button";
import { useAddress } from "src/hooks";
import { BIG_ZERO } from "src/utils/bigNumber";
import BigNumber from "bignumber.js";
import Skeleton from "src/components/Skeleton";
import Balance from "src/components/Balance";
import ConnectMenu from "src/components/Menu/ConnectMenu";
import { Pool } from "src/constants/types";

interface Props {
  pool: Pool;
  loading: boolean;
  setDepositModalVisible: (x: boolean) => void;
  setWithdrawModalVisible: (x: boolean) => void;
  setGetTokenModalVisible: (x: boolean) => void;
  handleApprove: () => void;
  requestedApproval: boolean;
  onEmergencyUnstake: (x: string) => void;
  walletBalance: BigNumber;
  stakedBalance: BigNumber;
}

function Staking(props: Props) {
  const {
    pool,
    loading,
    setDepositModalVisible,
    setWithdrawModalVisible,
    setGetTokenModalVisible,
    handleApprove,
    requestedApproval,
    onEmergencyUnstake,
    walletBalance,
    stakedBalance,
  } = props;
  const account = useAddress();
  const isApproved = !loading && parseFloat(pool.allowance) > 0;

  if (!account) {
    return (
      <div className="w-full">
        <div className="text-gray-500 font-medium uppercase text-xs">Start staking</div>
        <div>
          <ConnectMenu width="w-full" />
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="w-full">
        <div className="text-gray-500 font-medium uppercase text-xs">Start staking</div>
        <div>
          <Skeleton className="w-full" height="h-10" />
        </div>
      </div>
    );
  }

  if (!isApproved) {
    return (
      <div className="w-full">
        <div className="text-white font-medium uppercase text-xs">Enable Pool</div>
        <div>
          <Button onClick={handleApprove} disabled={requestedApproval} className="w-full">
            Enable
          </Button>
        </div>
      </div>
    );
  }

  if (pool.enableEmergencyWithdraw && pool.disableRewards) {
    return (
      <div className="w-full flex justify-between items-center">
        <div>
          <div className="text-white font-medium uppercase text-xs">
            <span className="text-primary">{pool.stakingToken.symbol}</span> Staked
          </div>
          <div className="text-white font-semibold">
            <Balance fontSize="text-xl" value={stakedBalance.toNumber()} />
          </div>
          <div className="text-white">
            <Balance
              fontSize="text-xs"
              value={stakedBalance.multipliedBy(pool.stakingTokenPrice).toNumber()}
              prefix="$"
              decimals={2}
            />
          </div>
        </div>
        <div className="w-28 sm:w-32 ml-auto">
          <Button onClick={() => onEmergencyUnstake(stakedBalance.toString())} className="w-full">
            Withdraw All
          </Button>
        </div>
      </div>
    );
  }

  if (stakedBalance.gt(0)) {
    return (
      <div className="w-full flex justify-between items-center">
        <div>
          <div className="text-white font-medium uppercase text-xs">
            <span className="text-primary">{pool.stakingToken.symbol}</span> Staked
          </div>
          <div className="text-white font-semibold">
            <Balance fontSize="text-xl" value={stakedBalance.toNumber()} />
          </div>
          <div className="text-white">
            <Balance
              fontSize="text-xs"
              value={stakedBalance.multipliedBy(pool.stakingTokenPrice).toNumber()}
              prefix="$"
              decimals={2}
            />
          </div>
        </div>
        <div className="w-28 sm:w-32 ml-auto">
          <Button
            disabled={pool.isFinished}
            onClick={walletBalance.gt(0) ? () => setDepositModalVisible(true) : () => setGetTokenModalVisible(true)}
            scale="sm"
            className="w-full mb-1"
          >
            Stake
          </Button>
          <Button onClick={() => setWithdrawModalVisible(true)} scale="sm" variant="outline" className="w-full">
            Unstake
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-full">
        <div className="text-white font-medium uppercase text-xs">
          Stake <span className="text-primary">ATLAS</span>
        </div>
        <div>
          <Button
            disabled={pool.isFinished}
            onClick={walletBalance.gt(0) ? () => setDepositModalVisible(true) : () => setGetTokenModalVisible(true)}
            className="w-full"
          >
            Stake
          </Button>
        </div>
      </div>
    </>
  );
}

export default Staking;
