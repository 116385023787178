import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { Box, SvgIcon, Typography, Popper, Paper, Divider, Link, Slide, Fade } from "@material-ui/core";
// import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrow-up.svg";
// import { Link } from "react-router-dom";
import { useWeb3Context } from "src/hooks/web3Context";
import { UserCircleIcon } from "@heroicons/react/outline";
import Modal from "../Modal";
import Button from "../Button";
import classNames from "src/utils/classnames";

function ConnectMenu(props) {
  const { width } = props;
  const { connect, disconnect, connected, web3, address } = useWeb3Context();
  const [walletVisible, setWalletVisible] = useState(false);
  const [isConnected, setConnected] = useState(connected);
  // const [isHovering, setIsHovering] = useState(false);

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const handleClick = event => {
    setWalletVisible(true);
  };

  let buttonText = "Connect";
  let clickFunc = connect;

  if (isConnected) {
    buttonText = `${address.slice(0, 2)}...${address.slice(-4)}`;
    // clickFunc = disconnect;
    clickFunc = handleClick;
  }

  if (pendingTransactions && pendingTransactions.length > 0) {
    buttonText = "In progress";
    clickFunc = handleClick;
  }

  // const getBSCscanUrl = txnHash => {
  //   return `https://bscscan.com/tx/${txnHash}`;
  // };

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  return (
    <div className="my-auto">
      <Button
        variant={connected ? "gradient-metal" : "gradient-burning"}
        className={width ?? "sm:w-36"}
        onClick={clickFunc}
      >
        <span className={classNames(props.isMenu ? "hidden sm:inline" : "inline")}>{buttonText}</span>
        <UserCircleIcon className={classNames(props.isMenu ? "sm:hidden w-5 h-5" : "hidden")} />
      </Button>

      <Modal title="Manage Wallet" visible={walletVisible} onClose={() => setWalletVisible(false)}>
        <div className="px-6 mb-5">
          {/* <div className="mb-3">
            {[
              { text: "xxx", txnHash: "0xc4f8306c630d3211a75178def7988a5a2e8cfb9ee47bfd19b5e527b5c8cf7500" },
              { text: "oppa", txnHash: "0xc4f8306c630d3211a75178def7988a5a2e8cfb9ee47bfd19b5e527b5c8cf7500" },
            ].map((x, i) => (
              <div className="group mb-2">
                <a key={x.txnHash} href={getBSCscanUrl(x.txnHash)} target="_blank" rel="noreferrer">
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-white group-hover:opacity-70">{x.text}</div>
                      <div>
                        <ExternalLinkIcon className="w-4 h-4 text-white group-hover:opacity-70" />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div> */}
          <div className="flex justify-center">
            <Button variant="gradient-metal" onClick={disconnect}>
              Disconnect
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ConnectMenu;
