// next imports
import { Token } from "../../constants/types";
import getTokenLogoURL from "../../utils/getTokenLogoURL";

// own app

interface Props {
  token: Token;
  scale?: string;
}

const SCALE: { [key: string]: string } = {
  xs: "w-10 h-10",
  sm: "w-12 h-12",
  md: "w-14 h-14",
  lg: "w-20 h-20",
};

const Single: React.FC<Props> = ({ token, scale }) => {
  const tokenLogoUrl = getTokenLogoURL(token);
  const _scale = scale ?? "md";
  return (
    <div className={`${SCALE[_scale]} object-cover relative`}>
      <img src={tokenLogoUrl} alt={`${token.symbol} Token Images`} />
    </div>
  );
};

export default Single;
