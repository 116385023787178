import { useCallback, useState } from "react";
import BigNumberJS from "bignumber.js";
import { useSelector, useDispatch } from "react-redux";
import BigNumber from "bignumber.js";
import { getSyrupPool } from "src/slices/SyrupSlice";
import { useWeb3Context } from "src/hooks";
import { useSousChef } from "src/hooks/useContract";
import { ethers } from "ethers";

export const BIG_TEN = new BigNumberJS(10);

const sousStake = async (sousChefContract: any, amount: string) => {
  const estimate = await sousChefContract.estimateGas.deposit(new BigNumber(amount).times(BIG_TEN.pow(9)).toString());
  const tx = await sousChefContract.deposit(new BigNumber(amount).times(BIG_TEN.pow(9)).toString(), {
    gasLimit: estimate.mul(2000).div(1000),
  });
  const receipt = await tx.wait();
  return receipt.status;
};

const sousUnstake = async (sousChefContract: any, amount: string) => {
  const estimate = await sousChefContract.estimateGas.withdraw(new BigNumber(amount).times(BIG_TEN.pow(9)).toString());
  const tx = await sousChefContract.withdraw(new BigNumber(amount).times(BIG_TEN.pow(9)).toString(), {
    gasLimit: estimate.mul(2000).div(1000),
  });
  const receipt = await tx.wait();
  return receipt.status;
};

const harvestPool = async (sousChefContract: any) => {
  const estimate = await sousChefContract.estimateGas.deposit("0");
  const tx = await sousChefContract.deposit("0", { gasLimit: estimate.mul(2000).div(1000) });
  const receipt = await tx.wait();
  return receipt.status;
};

const sousEmergencyUnstake = async (sousChefContract: any) => {
  const tx = await sousChefContract.emergencyWithdraw();
  const receipt = await tx.wait();
  return receipt.status;
};

export const useStakePool = (poolAddress: string) => {
  const dispatch = useDispatch();
  const { provider, address, connect, chainID } = useWeb3Context();
  const sousChefContract = useSousChef(poolAddress);

  const handleStake = useCallback(
    async (amount: string, currentBlock: number) => {
      console.log({
        amount,
        currentBlock,
      });
      await sousStake(sousChefContract, amount);

      dispatch(getSyrupPool({ account: address, currentBlock }));
    },
    [address, provider, dispatch, sousChefContract, poolAddress],
  );

  return { onStake: handleStake };
};

export const useUnstakePool = (poolAddress: string, enableEmergencyWithdraw = false) => {
  const dispatch = useDispatch();
  const { provider, address, connect, chainID } = useWeb3Context();
  const sousChefContract = useSousChef(poolAddress);

  const handleUnstake = useCallback(
    async (amount: string, currentBlock: number) => {
      if (enableEmergencyWithdraw) {
        await sousEmergencyUnstake(sousChefContract);
      } else {
        await sousUnstake(sousChefContract, amount);
      }

      dispatch(getSyrupPool({ account: address, currentBlock }));
    },
    [address, dispatch, enableEmergencyWithdraw, sousChefContract, poolAddress],
  );

  return { onUnstake: handleUnstake };
};

export const useHarvestPool = (poolAddress: string) => {
  const dispatch = useDispatch();
  const { provider, address, connect, chainID } = useWeb3Context();
  const sousChefContract = useSousChef(poolAddress);

  const handleHarvest = useCallback(
    async (currentBlock: number) => {
      await harvestPool(sousChefContract);
      dispatch(getSyrupPool({ account: address, currentBlock }));
    },
    [address, dispatch, sousChefContract, poolAddress],
  );

  return { onReward: handleHarvest };
};

export const useApprovePool = (lpContract: any, poolAddress: string, earningTokenSymbol: string) => {
  const [requestedApproval, setRequestedApproval] = useState(false);
  // const { toastSuccess, toastError } = useToast();
  const dispatch = useDispatch();
  const { provider, address, connect, chainID } = useWeb3Context();
  const sousChefContract = useSousChef(poolAddress);

  const handleApprove = useCallback(
    async (currentBlock: number) => {
      // console.log({
      //   address,
      //   lpContract,
      //   poolAddress,
      //   earningTokenSymbol,
      //   currentBlock,
      //   sousChefAddress: sousChefContract.address,
      // });
      try {
        await window.ethereum.enable();
        setRequestedApproval(true);
        const tx = await lpContract.approve(sousChefContract.address, ethers.constants.MaxUint256);
        const receipt = await tx.wait();

        dispatch(getSyrupPool({ account: address, currentBlock }));
        if (receipt.status) {
          // toastSuccess(
          //   t("Contract Enabled"),
          //   t("You can now stake in the %symbol% pool!", { symbol: earningTokenSymbol }),
          // );
          setRequestedApproval(false);
        } else {
          // user rejected tx or didn't go thru
          // toastError(t("Error"), t("Please try again. Confirm the transaction and make sure you are paying enough gas!"));
          setRequestedApproval(false);
        }
      } catch (e) {
        console.error(e);
        // toastError(t("Error"), t("Please try again. Confirm the transaction and make sure you are paying enough gas!"));
      }
    },
    [address, dispatch, lpContract, sousChefContract, poolAddress, earningTokenSymbol],
  );

  return { handleApprove, requestedApproval };
};
