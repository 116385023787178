import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import Pair from "src/components/TokenImages/Pair";
// import Button from "src/components/Button";
import classNames from "src/utils/classnames";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ExternalLinkIcon } from "@heroicons/react/outline";
// import useRefresh from "src/hooks/useRefresh";
import Balance from "src/components/Balance";
import Skeleton from "src/components/Skeleton";
import ActionPanel from "./ActionPanel";
import { Pool } from "src/constants/types";
import BigNumber from "bignumber.js";

export const getPoolBlockInfo = (pool: Pool, currentBlock: number) => {
  const { startBlock, endBlock, isFinished } = pool;
  const shouldShowBlockCountdown = Boolean(!isFinished && startBlock && endBlock);
  const blocksUntilStart = Math.max(startBlock - currentBlock, 0);
  const blocksRemaining = Math.max(endBlock - currentBlock, 0);
  const hasPoolStarted = blocksUntilStart === 0 && blocksRemaining > 0;
  const blocksToDisplay = hasPoolStarted ? blocksRemaining : blocksUntilStart;
  return { shouldShowBlockCountdown, blocksUntilStart, blocksRemaining, hasPoolStarted, blocksToDisplay };
};

interface Props {
  pool: Pool;
  loading: boolean;
  currentBlock: number;
}

function PoolRow(props: Props) {
  const { pool, loading, currentBlock } = props;
  const [toggleOpen, setToggleOpen] = useState(true);

  useEffect(() => {
    if (parseFloat(pool.stakedBalance) > 0) {
      setToggleOpen(true);
    }
  }, []);

  const { shouldShowBlockCountdown, blocksUntilStart, blocksRemaining, hasPoolStarted, blocksToDisplay } =
    getPoolBlockInfo(pool, currentBlock);
  // A bit hacky way to determine if public data is loading relying on totalStaked
  // Opted to go for this since we don't really need a separate publicDataLoaded flag
  // anywhere else
  const isLoadingPublicData = !currentBlock || (!blocksRemaining && !blocksUntilStart);
  const showLoading = isLoadingPublicData && !!pool.hasEnd && !pool.isFinished;
  const renderBlocks = shouldShowBlockCountdown ? (
    <div>
      <div className="flex item-center">
        <Balance fontSize="16px" value={blocksToDisplay} decimals={0} />
        <div className="ml-1">blocks</div>
      </div>
    </div>
  ) : pool.isFinished ? (
    <div>Ended</div>
  ) : (
    <div>-</div>
  );

  const earned = new BigNumber(pool.pendingRewards).div(1e18);
  // console.log({
  //   hmm: new BigNumber(pool.pendingRewards).toNumber(),
  //   pendingrewards: pool.pendingRewards,
  //   decimals: pool.earningToken.decimals,
  // });
  // console.log(earned.toNumber());

  return (
    <Disclosure
      key={pool.sousId}
      as="div"
      className="rounded-lg border border-gray-600 bg-gray-900 max-w-3xl lg:max-w-4xl mx-auto mb-3 overflow-clip overflow-hidden"
    >
      {() => (
        <>
          <Disclosure.Button className="text-left w-full">
            <div
              className="flex items-center text-white pt-5 px-5 sm:pb-5 z-0"
              onClick={toggleOpen ? () => setToggleOpen(false) : () => setToggleOpen(true)}
            >
              <div className="w-20 flex-grow-0">
                <Pair ocean topToken={pool.stakingToken} bottomToken={pool.earningToken} />
              </div>
              <div className="w-36 md:w-40">
                <div className="text-white">
                  <div>
                    <div className="text-white text-sm font-semibold mr-1">Earn {pool.earningToken.symbol}</div>
                    <div className="text-white text-xs flex items-center">
                      Stake {pool.stakingToken.symbol}{" "}
                      {/* <a className="ml-1 z-50" href={"/"} target="_blank" rel="noreferrer">
                        <ExternalLinkIcon className="w-4 h-4 text-gray-500 hover:text-white" />
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-start">
                <div className="hidden sm:block w-32 md:w-36">
                  <div className="text-gray-500 font-medium uppercase text-xxs">{pool.earningToken.symbol} Earned</div>
                  <div className="text-xs mr-1">
                    {loading ? <Skeleton /> : <Balance color="text-primary" value={earned.toNumber()} />}
                  </div>
                </div>
                <div className="hidden sm:block w-32 md:w-32">
                  <div className="text-gray-500 font-medium uppercase text-xxs">APR</div>
                  <div className="text-white text-xs">
                    {loading ? <Skeleton /> : <Balance value={pool.apr} decimals={2} unit="%" />}
                  </div>
                </div>
                <div className="hidden lg:block w-32 md:w-36">
                  <div className="text-gray-500 font-medium uppercase text-xxs">Total Staked</div>
                  <div className="text-white text-xs">
                    {loading ? (
                      <Skeleton />
                    ) : (
                      <Balance
                        value={new BigNumber(pool.totalStaked).div(1e9).toNumber()}
                        unit={` ${pool.stakingToken.symbol}`}
                      />
                    )}
                  </div>
                </div>
                <div className="hidden sm:block w-32 md:w-36">
                  <div className="text-gray-500 font-medium uppercase text-xxs">
                    {hasPoolStarted || !shouldShowBlockCountdown ? "Ends In" : "Starts In"}
                  </div>
                  <div className="text-white text-xs">{loading || showLoading ? <Skeleton /> : renderBlocks}</div>
                </div>
              </div>
              <div className="ml-auto h-6 flex items-center">
                <ChevronDownIcon
                  className={classNames(toggleOpen ? "-rotate-180" : "rotate-0", "h-5 w-5 transform")}
                  aria-hidden="true"
                />
              </div>
            </div>
            <div className="sm:hidden">
              <div
                className="sm:flex text-white px-5 pb-5 pt-3 z-0"
                onClick={toggleOpen ? () => setToggleOpen(false) : () => setToggleOpen(true)}
              >
                <div className="flex items-start justify-between">
                  <div className="w-32">
                    <div className="text-gray-500 font-medium uppercase text-xxs">
                      {pool.earningToken.symbol} Earned
                    </div>
                    <div className="text-xs mr-1">
                      {loading ? <Skeleton /> : <Balance color="text-primary" value={earned.toNumber()} />}
                    </div>
                  </div>
                  <div className="w-32">
                    <div className="text-gray-500 font-medium uppercase text-xxs">APR</div>
                    <div className="text-white text-xs">
                      {loading ? <Skeleton /> : <Balance value={pool.apr} decimals={2} unit="%" />}
                    </div>
                  </div>
                </div>
                <div className="flex items-start justify-between mt-2 xs:mt-0">
                  <div className="w-32">
                    <div className="text-gray-500 font-medium uppercase text-xxs">Total Staked</div>
                    <div className="text-white text-xs">
                      {loading ? (
                        <Skeleton />
                      ) : (
                        <Balance
                          value={new BigNumber(pool.totalStaked).div(1e9).toNumber()}
                          unit={` ${pool.stakingToken.symbol}`}
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-32">
                    <div className="text-gray-500 font-medium uppercase text-xxs">
                      {hasPoolStarted || !shouldShowBlockCountdown ? "Ends In" : "Starts In"}
                    </div>
                    <div className="text-white text-xs">{loading || showLoading ? <Skeleton /> : renderBlocks}</div>
                  </div>
                </div>
              </div>
            </div>
          </Disclosure.Button>
          {toggleOpen && (
            <Disclosure.Panel static as="div" className="bg-black p-5 sm:flex items-center">
              <ActionPanel pool={pool} loading={loading} currentBlock={currentBlock} earned={earned} />
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  );
}

export default PoolRow;
