import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePopperTooltip } from "react-popper-tooltip";
// import { trim } from "../../helpers";
// import { Skeleton } from "@material-ui/lab";
import { useWeb3Context } from "src/hooks/web3Context";
import { getDividendPool } from "src/slices/StakingSlice";
import { getFixedDepositPool } from "src/slices/FixedDepositSlice";
import {
  CurrencyDollarIcon,
  LockClosedIcon,
  ArrowCircleDownIcon,
  CashIcon,
  GlobeIcon,
  TrendingUpIcon,
  ShoppingCartIcon,
  ArrowRightIcon,
  // ClockIcon,
  SupportIcon,
  ArrowCircleRightIcon,
  TruckIcon,
  InformationCircleIcon,
  ScaleIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import Title from "./Title";
import { useBonds } from "src/hooks";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import useRefresh from "src/hooks/useRefresh";
// import { aprToApy, apyToApr } from "src/helpers/apr";
import { fixedDeposits } from "src/constants/fixedDeposits";
// import tokens from "src/tokens/tokens";

function Dashboard() {
  // Use marketPrice as indicator of loading.
  const dispatch = useDispatch();
  const { provider, address } = useWeb3Context();
  const isAppLoading = useSelector(state => !state.app?.marketPrice ?? true);
  const stakingData = useSelector(state => state.staking.publicData);

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement: "top",
  });

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });

  const { fastRefresh } = useRefresh();
  useEffect(() => {
    dispatch(getDividendPool({ provider, account: address }));
    if (currentBlock) {
      console.log("get fixed deposits data");
      dispatch(getFixedDepositPool({ account: address, currentBlock }));
    }
  }, [dispatch, address, fastRefresh]);

  const fixedDepositPools = useSelector(state => {
    return state.fixedDeposit;
  });

  // const apy = aprToApy(stakingData.singleAPY + apyToApr(fixedDepositPools.data[0].apy));

  const marketPrice = useSelector(state => {
    return state.app.marketPrice;
  });
  const circSupply = useSelector(state => {
    return state.app.circSupply;
  });
  const totalSupply = useSelector(state => {
    return state.app.totalSupply;
  });
  const marketCap = useSelector(state => {
    return state.app.marketCap;
  });
  const stakingTVL = useSelector(state => {
    return state.app.stakingTVL;
  });
  const protocolLiquidity = useSelector(state => {
    return state.app.protocolLiquidity;
  });

  const { bonds } = useBonds(56);

  const totalValue = useMemo(() => {
    return bonds.reduce((prev, curr) => {
      return (curr.purchased ?? 0) + prev;
    }, 0);
  }, [bonds]);

  const atlasStaked = (stakingTVL / (circSupply * marketPrice)) * 100;

  const stats = [
    {
      id: 0,
      name: "ATLAS Price",
      stat: `$${new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 3,
        minimumFractionDigits: 0,
      }).format(marketPrice ?? 0)}`,
      icon: CurrencyDollarIcon,
    },
    {
      id: 1,
      name: "Daily ROI",
      stat: `${new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(3)}%`,
      icon: TrendingUpIcon,
    },
    {
      id: 9,
      name: "Backing Per ATLAS",
      stat: `$${new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(totalValue / circSupply)}`,
      icon: SupportIcon,
    },
    {
      id: 2,
      name: "Total Value Staked",
      stat: `$${new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(stakingTVL)}`,
      icon: LockClosedIcon,
    },
    {
      id: 3,
      name: "ATLAS Staked",
      stat: `${new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(atlasStaked > 91 ? 91 : atlasStaked)}%`,
      icon: ArrowCircleDownIcon,
    },
    {
      id: 4,
      name: "Market Cap",
      stat: `$${new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(marketCap)}`,
      icon: GlobeIcon,
    },
    {
      id: 5,
      name: "Treasury Balance",
      stat: `$${new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(totalValue)}`,
      icon: CashIcon,
    },
    {
      id: 6,
      name: "Circulating Supply",
      stat: `${new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(circSupply)} ATLAS`,
      icon: TruckIcon,
    },
    {
      id: 7,
      name: "Total Supply",
      stat: `${new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(totalSupply)} ATLAS`,
      icon: ShoppingCartIcon,
    },
    {
      id: 8,
      name: "Protocol Owned Liquidity",
      stat: `${new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(protocolLiquidity > 99 ? 99 : protocolLiquidity)} %`,
      icon: ScaleIcon,
    },
    // { id: 8, name: "Runway", stat: "124.5595 Days", icon: TruckIcon },
  ];

  return (
    <div className="flex bg-gradient-to-br from-gray-900 via-gray-700 to-gray-900 text-white">
      <div className="w-full max-w-screen-md mx-auto px-4 sm:px-6 md:px-8 my-12">
        <Title />

        <dl className="mt-5 mb-3 grid grid-cols-1 gap-3 sm:grid-cols-2">
          {stats.map(item => (
            <div key={item.id} className="relative bg-gray-900 py-6 px-4 sm:px-6 shadow rounded-lg overflow-hidden">
              <div className="absolute bg-gradient-to-br from-blue-700 to-purple-900 rounded-md p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <div>
                    <div className="ml-16 text-xs font-medium text-gray-400 truncate">{item.name}</div>
                  </div>
                  <div className="ml-16 flex items-baseline">
                    <div className="text-sm font-semibold text-white">{item.stat}</div>
                  </div>
                </div>
                {item.id === 1 && (
                  <div ref={setTriggerRef} className="text-sm font-semibold text-white hover:opacity-80">
                    <InformationCircleIcon className="w-6 h-6" />
                  </div>
                )}
              </div>
            </div>
          ))}
          <Link to="/bonds">
            <div className="bg-gradient-to-br from-green-300 via-blue-500 to-purple-600 text-white hover:opacity-80 py-4 px-4 sm:px-6 sm:py-6 shadow rounded-lg overflow-hidden">
              <div className="flex items-center justify-between">
                <div>
                  <div className="text-sm font-medium">Buy Bonds</div>
                  <div className="font-bold text-xs text-gray-800">Our Liquidity Bedrock</div>
                </div>
                <ArrowCircleRightIcon className="w-6 h-6" />
              </div>
            </div>
          </Link>
          <a target="_blank" rel="noreferrer" href="https://medium.com/autosharkfin/the-road-to-atlantis-c2ab8703eb7c">
            <div className="bg-gradient-to-br from-pink-500 via-red-500 to-yellow-500 text-white hover:opacity-80 py-4 px-4 sm:px-6 sm:py-6 shadow rounded-lg overflow-hidden">
              <div className="flex items-center justify-between">
                <div>
                  <div className="text-sm font-medium">The Road to Atlantis</div>
                  <div className="font-bold text-xs text-gray-800">Read Up More</div>
                </div>
                <ArrowCircleRightIcon className="w-6 h-6" />
              </div>
            </div>
          </a>
        </dl>

        <div className="bg-gray-900 rounded-md p-5 mb-3">
          <dt>
            <div className="absolute bg-gradient-to-br from-blue-700 to-purple-900 rounded-md p-3">
              <img className="h-6 w-6 sm:h-10 sm:w-10" src="/images/icons/atlantis white.svg" />
            </div>
            <p className="pt-2 sm:pt-0 ml-16 sm:ml-20 text-xl sm:text-2xl font-semibold text-white truncate mb-1">
              What is Atlantis?
            </p>
          </dt>
          <dd className="mt-5 sm:mt-0 ml-0 sm:ml-20 flex items-baseline">
            <p className="text-md sm:text-lg text-gray-300">
              Atlantis is a decentralized reserve currency protocol based on the ATLAS token. Each ATLAS token is backed
              by a basket of tokens in the Atlantis treasury, giving it an intrinsic value that it cannot fall below.
              Atlantis also introduces unique economic and game-theoretic dynamics into the market through staking and
              bonding.
            </p>
          </dd>
          <div className="flex justify-end">
            <a
              href="https://docs-atlantis.autoshark.finance/"
              target="_blank"
              rel="noreferrer"
              className="flex items-center mt-4 text-primary hover:opacity-80 cursor-pointer"
            >
              <div className="mr-1">Read the Docs</div>
              <ArrowRightIcon className="h-4 w-4" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div className="bg-gray-900 rounded-md p-5 mb-3">
          <dt>
            <div className="absolute bg-gradient-to-br from-blue-700 to-purple-900 rounded-md p-3">
              <LightningBoltIcon className="h-6 w-6 sm:h-10 sm:w-10" />
            </div>
            <p className="pt-2 sm:pt-0 ml-16 sm:ml-20 text-xl sm:text-2xl font-semibold text-white truncate mb-1">
              Maximizing Earnings
            </p>
          </dt>
          <dd className="mt-5 sm:mt-0 ml-0 sm:ml-20 flex items-baseline">
            <div>
              <p className="text-md sm:text-lg text-gray-300">
                You can maximize your returns from Atlantis by purchasing bonds using various assets from our{" "}
                <a href="/bonds" target="_blank" rel="noreferrer" className="text-primary">
                  bonds page
                </a>
                . Whilst purchasing a bond, you will be prompted to select a{" "}
                <a href="/fixed-deposits" target="_blank" rel="noreferrer" className="text-primary">
                  Fixed Deposit Plan
                </a>
                . Our 90-day fixed deposit plan offers the highest APY, at{" "}
                {`${new Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0,
                }).format(fixedDeposits[0].apy)}%`}
                .
              </p>
            </div>
          </dd>
          {/* <div className="flex justify-end">
            <a
              href="https://docs-atlantis.autoshark.finance/"
              target="_blank"
              rel="noreferrer"
              className="flex items-center mt-4 text-primary hover:opacity-80 cursor-pointer"
            >
              <div className="mr-1">Read the Docs</div>
              <ArrowRightIcon className="h-4 w-4" aria-hidden="true" />
            </a>
          </div> */}
        </div>
      </div>
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {`This Daily ROI can be achieved by purchasing bonds and staking in the 90-day fixed deposit plan.`}
        </div>
      )}
    </div>
  );
}

export default Dashboard;
